import { CircularProgress, MenuItem, Stack, Typography } from "@mui/material";
import { CustomDropDown, ModalPicker, PDFViewer } from "components";
import { FormikProps } from "formik";
import { memo, useEffect, useState } from "react";
import { formatDate } from "utils";
import {
  useGetCentersQuery,
  useGetPaymentsQuery,
  useLazyGetInsurancesQuery,
  useLazyGetUsersQuery,
} from "../service";
import CustomInPut from "./CustomInPut";

const Supported: React.FC<FormikProps<any>> = ({
  values,
  errors,
  touched,
  handleChange,
  // handleBlur,
  setFieldValue,
}) => {
  const { data: centers = [], isFetching } = useGetCentersQuery(undefined);
  const { data: payments = [], isFetching: paymentLoading } =
    useGetPaymentsQuery(undefined);

  const [
    getInsurances,
    { data: insurancesList = [], isFetching: insuranceLoading },
  ] = useLazyGetInsurancesQuery(undefined);

  const [getUsers, { data: usersList = [], isFetching: usersLoading }] =
    useLazyGetUsersQuery();

  const [openInsurances, setInsurances] = useState(false);
  const [openUsers, setUsers] = useState(false);

  useEffect(() => {
    getUsers(undefined);
    getInsurances(undefined);
  }, []);

  if (isFetching || paymentLoading) {
    return (
      <Stack
        width={"50%"}
        display={"flex"}
        flex={1}
        alignItems={"center"}
        pt={40}
      >
        <CircularProgress />
      </Stack>
    );
  }

  return (
    <Stack width={"50%"} display={"flex"} flex={1} alignSelf={"start"}>
      <Stack direction={"row"} flex={1} width={"100%"} spacing={1} justifyContent="space-between">  
      <CustomInPut
        name="info_quote_id"
        value={values?.info_quote_id}
        placeholder="Numéro de devis"
        label="N° devis"
        disabled
      />

      <CustomInPut
        name="insurance"
        value={values?.insurance?.name}
        placeholder="Mutuelle"
        label="Mutuelle"
        onClick={() => setInsurances(!openInsurances)}
        onRedirect={values?.insurance?.url}
        errors={
          touched.insurance && errors.insurance
            ? errors.insurance.toString()
            : undefined
        }
      />
        </Stack>
      <Stack direction={"row"} flex={1} width={"100%"} spacing={1} justifyContent="space-between">  
  <CustomDropDown
    name="status"
    value={values?.status}
    onChange={handleChange}
    label="Statut de la prise en charge"
    placeholder="Select"
    isShadow
    sx={{ flex: 1 }} // Permet aux dropdowns d'occuper tout l'espace disponible
  >
    <MenuItem value="to_do">À faire</MenuItem>
    <MenuItem value="in_progress">En cours</MenuItem>
    <MenuItem value="sent_patient">Envoi patient</MenuItem>
    <MenuItem value="retry_1">Relance Mutuelle</MenuItem>
    <MenuItem value="retry_2">Devis erroné ou Désistement Patient </MenuItem>
    <MenuItem value="granted">Accordée</MenuItem>
    <MenuItem value="refused">Refusée</MenuItem>
    {/*<MenuItem value="error">Erreur Devis</MenuItem>*/}
  </CustomDropDown>
  
  <CustomDropDown
    name="mode"
    value={values?.mode}
    onChange={handleChange}
    label="Mode de la prise en charge "
    placeholder="Select"
    isShadow
    sx={{ flex: 1 }} // Permet d'équilibrer l'espace
  >
    <MenuItem value="online">En ligne</MenuItem>
    <MenuItem value="email">Email</MenuItem>
    <MenuItem value="mail">Courrier</MenuItem>
  </CustomDropDown>
</Stack>

          <Stack direction={"row"} flex={1} width={"100%"} spacing={1}>  
            <CustomInPut
        type="date"
        name="updated_at"
        value={formatDate(values?.updated_at)}
        placeholder="date"
        onChange={handleChange}
        label="Date de PEC "
      />
<CustomInPut
  type="date"
  placeholder="date"
  value={values?.expiration_date ? new Date(values.expiration_date).toISOString().split("T")[0] : ""}
  onChange={(e) => setFieldValue("expiration_date", e.target.value)}
  label="Date d'expiration PEC"
  isShadow
/>

</Stack>
      <Stack direction={"row"} flex={1} width={"100%"} spacing={1}>
        <CustomInPut
          name="total"
          value={values?.total}
          placeholder="Montant total"
          label="Montant total"
          onChange={handleChange}
        />
<CustomInPut
  name="insurance_part"
  value={values?.insurance_part}
  placeholder="Montant mutuelle"
  label="Part mutuelle"
  type="text" // 🔥 Force l'acceptation des points et virgules
  onChange={(e) => {
    let value = e.target.value.replace(",", "."); // Remplace la virgule par un point

    // Vérifie si la valeur est un nombre valide
    const numericValue = parseFloat(value);

    if (!isNaN(numericValue) || value === "") {
      setFieldValue("insurance_part", value === "" ? "" : numericValue);
    }
  }}
/>



      </Stack>
      <Stack direction={"row"} flex={1} width={"100%"} spacing={1}>
        <CustomInPut
          name="ameli_part"
          value={values?.ameli_part}
          placeholder="Montant AMO"
          label="Part AMO"
          onChange={handleChange}
        />
        <CustomInPut
          disabled
          value={values?.total - values?.insurance_part - values?.ameli_part}
          placeholder="Reste à charge"
          label="Reste à charge"
        />
      </Stack>

      <CustomDropDown
        name="payment"
        value={values?.payment?.id}
        label="Mode de paiement"
        placeholder="Select"
        isShadow
      >
        {payments.map((payment: any, index: number) => (
          <MenuItem
            key={index}
            value={payment.id}
            onClick={() => {
              let tempData = { ...payment };
              if (tempData.centers) delete tempData.centers;
              setFieldValue("payment", tempData);
            }}
          >
            {payment.name}
          </MenuItem>
        ))}
      </CustomDropDown>

            <CustomInPut
        name="user"
        placeholder="Commencez à écrire pour rechercher..."
        value={
          (values?.user?.firstname || "") + " " + (values?.user?.lastname || "")
        }
        label="Praticien"
        onClick={() => setUsers(!openUsers)}
        errors={
          touched.user && errors.user
            ? errors.user.toString()
            : undefined
        }
      />

      <CustomDropDown
        name="center"
        value={values?.center?.id}
        label="Centre médical"
        placeholder="Select"
        errors={
          touched.center && errors.center
            ? errors.center.toString()
            : undefined
        }
      >
        {centers.map((center: any, index: number) => (
          <MenuItem
            key={index}
            value={center.id}
            onClick={() => {
              let tempData = { ...center };
              if (tempData.payments) delete tempData.payments;
              setFieldValue("center", tempData);
            }}
          >
            {center.name}
          </MenuItem>
        ))}
      </CustomDropDown>

      <Typography mt={1} ml={0.4} fontSize={13} color={"text.primary"}>
        Réponse mutuelle
      </Typography>
      <PDFViewer
        id={values.response_id}
        onCallBack={(id) => setFieldValue("response_id", Number(id))}
      />

      <ModalPicker
        list={insurancesList}
        keyName={"name"}
        isLoading={insuranceLoading}
        open={openInsurances}
        heading="Mutuelle"
        onCallBack={(res) => {
          if (res) {
            setFieldValue("insurance", res);
          }
          setInsurances(false);
          setUsers(false);
        }}
        onSearch={(search) => {
          getInsurances(search);
        }}
      />

      <ModalPicker
        list={usersList}
        keyName={"firstname"}
        isLoading={usersLoading}
        subKeyName={"lastname"}
        open={openUsers}
        heading="Praticien"
        onCallBack={(res) => {
          if (res) {
            setFieldValue("user", res);
          }
          setInsurances(false);
          setUsers(false);
        }}
        onSearch={(search) => {
          getUsers(search);
        }}
      />
    </Stack>
  );
};

export default memo(Supported)
