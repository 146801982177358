import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import { ButtonBase, Stack } from "@mui/material";
import { InputFeild } from "components";
import { InputFeildType } from "components/InputFeild";

export interface CustomInPutType extends InputFeildType {
  onRedirect?: string;
  isShadow?: boolean;
}

const CustomInPut = ({
  onRedirect,
  isShadow = false,
  ...props
}: CustomInPutType) => {
  const openInNewTab = () => {
    window.open(onRedirect, "_blank", "noreferrer");
  };

  return (
    <Stack
      display={"flex"}
      flex={1}
      flexDirection={"row"}
      alignItems={"center"}
      color={"text.primary"}
    >
      <InputFeild
        {...props}
        boxShadow={isShadow ? "3px 4px 7px #855191" : "none"}
        bgColor="primary.200"
        height={45}
        width={"100%"}
        // sx={{ color: "text.primary" }}
        icon={
          isShadow ? (
            <ModeEditOutlineOutlinedIcon
              sx={{ fontSize: 16, color: "#3649EC" }}
            />
          ) : undefined
        }
      />
      {/* {onRedirect &&
                <a href="https://plainenglish.io" target="_blank" style={{
                    backgroundColor: "primary.main", height: 45,
                    width: 50, borderRadius: 10, bottom: -7,
                    marginLeft: 10
                }}>
                    <ShortcutIcon htmlColor="white" />
                </a>
            } */}
      {onRedirect && (
        <ButtonBase
          style={{
            // backgroundColor: "primary.main",
            height: 45,
            width: 50,
            borderRadius: 10,
            bottom: -6,
            marginLeft: 10,
          }}
          sx={{ bgcolor: "primary.main" }}
          onClick={openInNewTab}
        >
          <ShortcutIcon htmlColor="white" fontSize="small" />
        </ButtonBase>
      )}
    </Stack>
  );
};

export default CustomInPut;
