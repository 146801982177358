import { ColorSystemOptions } from "@mui/material/styles";
import { experimental_extendTheme as extendTheme } from "@mui/material/styles";

const light: ColorSystemOptions = {
  palette: {
    background: {
      paper: "#FBF9FB", //"#FBF9FB"
      default: "#000000",
    },
    primary: {
      main: "#62367d",
      100: "#855191",
      200: "#E8E1EC",
    },
    secondary: {
      main: "#3A8E87",
      200: "white",
      300: "white",
      400: "#dfe4ea",
      500: "#0f161b",
      600: "#f1f2f6",
    },
    text: {
      primary: "#1c2a4d",
      secondary: "#fff",
    },
  },
};
const dark: ColorSystemOptions = {
  palette: {
    background: {
      // paper: "#202f36",
      // paper: "#1a262e",
      // paper: "#131e22",
      paper: "#0f161b",
      default: "#000000",
    },
    primary: {
      main: "#62367d",
      100: "secondary.400", //#131e22
      200: "#1a262e",
    },
    secondary: {
      main: "#3A8E87",
      200: "#202f36",
      300: "#1a262e",
      400: "#131e22",
      500: "#0f161b",
      600: "#0f161b",
    },
    text: {
      primary: "#fff",
      secondary: "#000",
    },
  },
};

const AppTheme = extendTheme({
  colorSchemes: {
    light,
    dark,
  },
  typography: {
    fontFamily: "sans-serif",
    h1: {
      fontWeight: 200,
      color: "red",
    },
    // Define other typography styles here
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px", // Add the borderRadius property
          height: 45,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px", // Add the borderRadius property
          height: 45,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        // root: {
        //   borderRadius: '8px', // Add the borderRadius property
        //   height: 45,
        // },
      },
    },
  },
});

export default AppTheme;
