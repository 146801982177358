import { Stack, ButtonBase } from "@mui/material";
import { capitalizeCamelCase } from "utils";
import AccountCircleIcon from "@mui/icons-material/AccountCircle"; 
import Logo from "../assets/images/pu.png"; 
import { useNavigate } from "react-router-dom"; 

const Header = ({ name }: any) => {
   const navigate = useNavigate(); 

  return (
<Stack
  width={"99.5%"} 
  py={0.8} 
  bgcolor={"#1c2a4d"}
  style={{
    borderBottomRightRadius: 12, // ✅ Arrondi uniquement en bas à droite
    borderBottomLeftRadius: 12,  // ✅ Arrondi uniquement en bas à gauche
    borderTopRightRadius: 12, // ❌ Pas d’arrondi en haut à droite
    borderTopLeftRadius: 12,  // ❌ Pas d’arrondi en haut à gauche
    marginLeft: "4px", // ✅ Alignement avec le menu
    marginRight: "auto", // ✅ Ajustement propre
    marginTop: "8px", // ✅ Ajout d’un petit espace blanc au-dessus
    paddingBottom: "5px", // ✅ Ajustement du padding pour un meilleur rendu
  }}
  mb={0.5}
  justifyContent={"center"}
>

      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mx={2}
      >
        {/* ✅ Logo bien aligné à gauche */}
        <img
          src={Logo}
          alt="Pec UP"
          style={{ height: 55, marginLeft: 20 }} 
        />

        {/* ✅ Icône + Nom cliquable qui redirige vers "Mon Profil" */}
        <ButtonBase
          onClick={() => navigate("/profile")}
          sx={{ 
            backgroundColor: "#FFFFFF", // ✅ Fond blanc
            padding: "8px 15px",
            borderRadius: "10px",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
            marginRight: "20px",
            display: "flex",
            gap: "5px",
            alignItems: "center",
            cursor: "pointer", // ✅ Correction : plus de virgule ici
            transition: "transform 0.2s ease, background-color 0.2s ease", 
            "&:hover": { 
              transform: "scale(1.05)", 
              backgroundColor: "#f0f0f0" // ✅ Légère variation au survol
            }
          }}
        >
          <AccountCircleIcon sx={{ fontSize: 20, color: "#1c2a4d" }} />
          <span style={{ fontSize: 16, color: "#1c2a4d" }}>
            {capitalizeCamelCase(name)}
          </span>
        </ButtonBase>
      </Stack>
    </Stack>
  );
};

export default Header;
