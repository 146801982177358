import { Select, Stack, Typography } from "@mui/material";
import type { SelectProps } from "@mui/material/Select";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";

export interface InputFeildType extends Omit<SelectProps<any>, "ref"> {
  boxShadow?: string;
  height?: number | string;
  width?: number | string;
  bgColor?: string;
  label?: string;
  errors?: string | undefined;
  isShadow?: boolean;
}

const CustomDropDown = ({
  boxShadow,
  height,
  width,
  bgColor,
  label,
  errors,
  isShadow = false,
  ...props
}: InputFeildType) => {
  return (
    <Stack
      sx={{
        marginBottom: 1,
        marginTop: 0.5,
      }}
      color={"text.primary"}
    >
      {/* {label && <Typography ml={.4} fontSize={13}>
            {label}
        </Typography>} */}
      <Stack direction={"row"} alignItems={"center"} mt={0.5} mb={0.2} spacing={1} >
        {label && (
          <Typography
            ml={0.4}
            fontSize={13}
            fontWeight={isShadow ? "bold" : "normal"}
            color={isShadow ? "#1c2a4d" : undefined}
          >
            {label}
          </Typography>
        )}
        {isShadow && (
          <ModeEditOutlineOutlinedIcon
            sx={{ fontSize: 16, color: "#1c2a4d" }}
          />
        )}
      </Stack>

      <Select
        {...props}
        sx={{
          color: "#FFFFFF",
          boxShadow: "4px 4px 12px rgba(7, 25, 130, 0.3)",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: 0,
            },
          backgroundColor: "#8c2380",
          height: 38,
          borderRadius: 2.5,
          fontSize: 14,
          border: errors ? "2px solid red" : undefined,
        }}
        type="menuitem"
        placeholder="Select"
      />
    </Stack>
  );
};

export default CustomDropDown;
