import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import ArchiveOutlinedIcon from "@mui/icons-material/ArchiveOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import BubbleChartOutlinedIcon from "@mui/icons-material/BubbleChartOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";
import WarehouseOutlinedIcon from "@mui/icons-material/WarehouseOutlined";
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import {
  Collapse,
  List,
  ListItemButton,
  Stack,
  Typography
} from "@mui/material";
import { getUser, setSignedInUser } from "features/auth/slice";
import { ReactNode, memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "tools/store";
import Header from "./Header";

interface types {
  children: ReactNode;
}

const NavBar = ({ children }: types) => {
  const navigate = useNavigate();
  const user = useAppSelector(getUser);
  const dispatch = useAppDispatch();

  const routeList = [
    {
      title: "Tableau de bord",
      icon: <SpaceDashboardOutlinedIcon fontSize="small" />,
    },
    {
      title: "DPECS",
      list: [
        { title: "Toutes", icon: <DescriptionOutlinedIcon fontSize="small" /> },
        { title: "Archives", icon: <ArchiveOutlinedIcon fontSize="small" /> },
      ],
      icon: <FolderOpenOutlinedIcon fontSize="small" />,
    },
    { title: "Mon Profil", icon: <ManageAccountsOutlinedIcon fontSize="small" /> },
    // { title: "Stats", icon: <BubbleChartOutlinedIcon fontSize="small" /> },
  ];
  const adminRouteList = [
    {
      title: "Admin",
      icon: <AdminPanelSettingsOutlinedIcon fontSize="small" />,
      list: [
        {
          title: "Utilisateurs",
          icon: <PeopleAltOutlinedIcon fontSize="small" />,
        },
        { title: "Centers", icon: <WarehouseOutlinedIcon fontSize="small" /> },
        {
          title: "Mutuelles",
          icon: <LibraryBooksOutlinedIcon fontSize="small" />,
        },
        {
          title: "Modes de paiement",
          icon: <CreditCardOutlinedIcon fontSize="small" />,
        },
      ],
    },
  ];

  const routeEnum: { [res: string]: string } = {
    "Tableau de bord": "/dashboard",
    Toutes: "/toutes",
    Archives: "/toutes/Archived_true",
    DPECS: "",
    Stats: "/stats",
    Admin: "",
    Utilisateurs: "admin/utilisateurs",
    Centers: "admin/centers",
    Mutuelles: "admin/mutuelles",
    "Modes de paiement": "admin/payment",
    "Mon Profil": "/profile",
  };

  const onNavigation = (routeName: string) => {
    const navigateTo = routeEnum[routeName];
    if (navigateTo && navigateTo !== "") navigate(navigateTo);
  };

  return (
    <Stack width={"100%"} height={"100vh"} bgcolor={"background.paper"}>
      <Header name={user?.username} />

      <Stack
        direction={"row"}
        width={"100%"}
        height={"calc(100vh - 74px)"}
        style={{ marginTop: -4 }}
      >
        <Stack height={"100%"} width={"13%"} position={"relative"}>
          <Stack
            spacing={2}
            alignItems={"self-start"}
            flex={1}
            display={"flex"}
            position={"absolute"}
            top={-10}
            width={"100%"}
          >
            <List
              sx={{ width: "100%", maxWidth: 360 }}
              component="nav"
              aria-labelledby="nested-list-subheader"
            >
              {[
                ...routeList,
                ...(user.role === "admin" ? adminRouteList : []),
              ].map((item, index) => (
                <RenderItem item={item} key={index} onClick={onNavigation} />
              ))}
            </List>

            {/* <ListItemButton
              sx={{ justifyContent: "space-between", py: 1.3, width: "100%" }}
              onClick={() => setMode(mode == "light" ? "dark" : "light")}
            >
              <Typography color={"text.primary"} textAlign={"start"}>
                {mode == "light" ? "Dark Mode" : "Light Mode"}
              </Typography>
            </ListItemButton> */}

            {/* <Stack direction={"row"} width={"100%"} justifyContent={"space-between"} alignItems={"center"} pl={1}>
              <Typography color={"text.primary"} textAlign={"start"}>
                {mode == "light" ? "Dark Mode" : "Light Mode"}
              </Typography>
              <FormControlLabel
                control={<MaterialUISwitch defaultChecked />}
                label=""
              />
            </Stack> */}
          </Stack>

          <Stack
            width={"100%"}
            flex={1}
            display={"flex"}
            direction={"row"}
            alignItems={"center"}
            position={"absolute"}
            bottom={0}
          >
            {/* <Stack direction={"row"} alignItems={"center"} bottom={30} position={"absolute"}> */}
            <ListItemButton
              onClick={() => {
                dispatch(setSignedInUser(undefined));
                navigate("/login");
              }}
              sx={{ p: 2 }}
            >
              <LogoutIcon sx={{ color: "text.primary" }} />
              <Typography fontSize={15} color={"text.primary"} ml={2}>
                Se déconnecter
              </Typography>
            </ListItemButton>
          </Stack>
        </Stack>

        <Stack
          width={"87%"}
          height={"100%"}
          overflow={"hidden"}
          bgcolor={"secondary.200"}
          px={0.5}
          pt={0.5}
          sx={{ borderTopLeftRadius: 10 }}
        >
          {children}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default memo(NavBar);

const RenderItem = ({ item, onClick }: any) => {
  const [open, setOpen] = useState(false);

  return (
    <Stack
      sx={{
        bgcolor: "secondary.600",
        m: 0.5,
        borderRadius: 2,
        overflow: "hidden",
        boxShadow: "3px 4px 7px #855191",
      }}
    >
      <ListItemButton
        sx={{ justifyContent: "space-between", py: 1.3, color: "text.primary" }}
        onClick={() => {
          if (item.list?.length && item.list?.length > 0) {
            setOpen(!open);
          } else {
            setOpen(!open);
            onClick(item.title);
          }
        }}
      >
        <Stack direction={"row"} spacing={1}>
          {item.icon}
          <Typography fontSize={14}>{item.title}</Typography>
        </Stack>
        {item.list?.length && item.list?.length > 0 && (
          <>
            {open ? (
              <ArrowDropUpIcon htmlColor={"text.primary"} />
            ) : (
              <ArrowDropDownIcon htmlColor={"text.primary"} />
            )}
          </>
        )}
      </ListItemButton>
      {item.list?.length && item.list?.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.list.map((nestedItem: any, index: number) => (
              <ListItemButton
                key={index}
                sx={{ pl: 4, bgcolor: "secondary.400" }}
                onClick={() => {
                  onClick(nestedItem.title);
                  setOpen(!open);
                }}
              >
                <Stack direction={"row"} spacing={1} color={"text.primary"}>
                  {nestedItem.icon}
                  <Typography fontSize={14}>{nestedItem.title}</Typography>
                </Stack>
              </ListItemButton>
            ))}
          </List>
        </Collapse>
      )}
    </Stack>
  );
};
