import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/";
import { getUser, signOut } from "features/auth/slice";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAppSelector, useAppDispatch } from "tools/store";
import AppTheme from "tools/theme";

function App() {
  const User = useAppSelector(getUser);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

useEffect(() => {
  if (User) {
    const redirectURL = location.pathname === "/" ? "/dashboard" : location.pathname;
    navigate(redirectURL, { replace: true });
  } else {
    navigate("/login", { replace: true });
  }
}, [User, location.pathname, navigate]);


useEffect(() => {
  const interval = setInterval(() => {
    const hour = new Date().getHours();
    if (hour >= 20 || hour < 7) {
      dispatch(signOut());
      navigate("/login", { replace: true }); // ✅ Correction ici
    }
  }, 60000); // Vérifie chaque minute

  return () => clearInterval(interval);
}, [dispatch, navigate]);

  return (
    <CssVarsProvider theme={AppTheme}>
      <Outlet />
      <ToastContainer />
    </CssVarsProvider>
  );
}

export default App;
