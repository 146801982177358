import { Experimental_CssVarsProvider as CssVarsProvider } from "@mui/material/";
import { getUser } from "features/auth/slice";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAppSelector } from "tools/store";
import AppTheme from "tools/theme";

function App() {
  const User = useAppSelector(getUser);
  const navigate: any = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (User) {
      const redirectURL =
        location.pathname === "/" ? "/dashboard" : location.pathname;
      navigate(redirectURL, true);
    } else {
      navigate("/login", true);
    }
  }, []);

  return (
    <CssVarsProvider theme={AppTheme}>
      <Outlet />
      <ToastContainer />
    </CssVarsProvider>
  );
}
export default App;
