import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { useState } from "react";

interface CustomStandardInputType extends Omit<TextFieldProps, 'variant'> {
    pass?: boolean
}
const CustomStandardInput: React.FC<CustomStandardInputType> = ({
    pass = false,
    ...props
}) => {

    const [showPassword, setShowPassword] = useState(pass);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <TextField
            {...props}
            id="standard-basic"
            variant="standard"
            size="small"
            InputLabelProps={{
                sx: { color: 'gray' } // Change 'red' to the color you desire
            }}
            sx={{
                height: 60,
                "& .MuiInputLabel-root": {
                    paddingLeft: "10px",
                    fontSize: 14,
                },
                "& .MuiInputBase-root": {
                    paddingLeft: "10px",
                    fontSize: 14,
                },
            }}
            type={!showPassword ? 'text' : 'password'}
            InputProps={pass ? {
                endAdornment:
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>

            } : undefined}
        />
    )
}

export default CustomStandardInput