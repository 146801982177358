import { Route, Routes } from "react-router-dom";
import Toutes from "./pages/Toutes";
import ToutesArchived from "./pages/ToutesArchived";
import { PatientDetail } from "features/deshboard";

const ToutesStack = () => {

    return (
        <Routes>
            <Route path="*" element={<Toutes />} />
            {/* <Route path="Archived_true/*" element={<Toutes isArchived={true} />} /> */}
            <Route path="Archived_true/*" element={<ToutesArchived />} />
            
            <Route path="/pec/:data" element={<PatientDetail />} />
        </Routes>
    )
};

export default ToutesStack;