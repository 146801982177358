import React from "react";
import { Typography, Stack, Box, useMediaQuery } from "@mui/material";
import pecuplogo from "assets/images/Pec Up (1).png"; // 🔹 Import corrigé selon ta structure

const Facturation = () => {
  const isMobile = useMediaQuery("(max-width:600px)"); // 🔹 Détection du mode mobile

  return (
    <Stack direction="column" justifyContent="center" alignItems="center" height="100vh" bgcolor="#f5f5f5">
      {/* Logo Pecup avec les mêmes styles que la page de connexion */}
      <img
        src={pecuplogo}
        style={{
          height: isMobile ? "10rem" : "17rem",
          width: isMobile ? "10rem" : "17rem",
          marginTop: isMobile ? 0 : -30,
        }}
        alt="PEC UP Logo"
      />

      {/* Contenu avec bordures arrondies */}
      <Box 
        sx={{
          bgcolor: "white",
          p: 4,
          borderRadius: "16px", // 🔹 Bordures arrondies
          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // 🔹 Ombre légère
          textAlign: "center",
          maxWidth: "600px",
          mt: 2, // 🔹 Ajoute un espacement sous le logo
        }}
      >
        <Typography variant="h6" sx={{ color: "#1c2a4d", fontWeight: "400" }}>
          Bientôt ici vous trouverez les PEC à facturer auprès des mutuelles.
        </Typography>
        <Typography variant="body1" sx={{ color: "#4a5d7a", mt: 2 }}>
          Cette section sera mise à jour très bientôt. Merci de patienter.
        </Typography>
      </Box>
    </Stack>
  );
};

export default Facturation;
