import { Route, Routes } from "react-router-dom";
import DashBoard from "./pages/DashBoard";
import PatientDetail from "./pages/PatientDetail";
import Quote from "./pages/Quote"

const DashboardStack = () => {

    return (
        <Routes>
            <Route path="*" element={<DashBoard />} />
            <Route path="/pec/:data" element={<PatientDetail />} />
            <Route path="/pec/new" element={<Quote />} />
            <Route path="/pec" element={<PatientDetail />} />
        </Routes>
    )
};

export default DashboardStack;