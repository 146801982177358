import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Chip,
  Collapse,
  Divider,
  Grid,
  ListItemButton,
  Stack,
  useColorScheme,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { RolesJson } from "utils";
import UserForm from "./UserForm";

const RenderItem = ({ item, onClick, index }: any) => {
  const [open, setOpen] = useState(false);
  const { mode }: any = useColorScheme();

  return (
    <Stack key={index}>
      {index !== 0 && <Divider />}
      <ListItemButton
        sx={{ justifyContent: "space-between", py: 2 }}
        onClick={() => {
          if (item.list?.length && item.list?.length > 0) {
            setOpen(!open);
          } else {
            setOpen(!open);
            onClick();
          }
        }}
      >
        <Grid container gridRow={1} gridColumn={5}>
          <Grid item xs={3} sx={{ fontWeight: "bold", color: "text.primary" }}>
            {item?.username}
          </Grid>
          <Grid item xs={1.2} color={"text.primary"}>
            {item?.centers?.length + " center(s)"}
          </Grid>
          <Grid item xs={1.2}>
            <Chip
              label={RolesJson[item?.role]}
              variant="outlined"
              size="small"
              sx={{
                color: "gray",
                borderColor: "gray",
              }}
            />
          </Grid>
          {!item?.validated && (
            <Grid item xs={2}>
              <Chip
                icon={
                  <Stack>
                    <UnsubscribeIcon sx={{ mx: 0.5 }} fontSize={"small"} />
                  </Stack>
                }
                label={"Email non valide"}
                color="primary"
                variant="outlined"
                size="small"
              />
            </Grid>
          )}
          {item?.last_activity_date && (
            <Grid item xs={2}>
              <Chip
                icon={
                  <Stack>
                    <TimelapseIcon sx={{ mx: 0.5 }} fontSize={"small"} />
                  </Stack>
                }
                label={
                  moment(item?.last_activity_date).format("DD-MM-YYYY") +
                  " -- " +
                  moment(item?.last_activity_date).utcOffset(0).format("HH:mm")
                }
                color="primary"
                variant="outlined"
                size="small"
              />
            </Grid>
          )}
          {!item?.external_id && (
            <Grid item xs={2}>
              <Chip
                icon={
                  <Stack color={"red"}>
                    <WarningAmberIcon sx={{ mx: 0.5 }} fontSize={"small"} />
                  </Stack>
                }
                label={"Pas d'ID DESMOS"}
                sx={{ color: "red", borderColor: "red" }}
                variant="outlined"
                size="small"
              />
            </Grid>
          )}
        </Grid>

        {open ? <ArrowDropUpIcon sx={{ color: "text.primary" }} /> : <ArrowDropDownIcon sx={{ color: "text.primary" }} />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <UserForm {...item} onClosed={() => setOpen(false)} />
      </Collapse>
    </Stack>
  );
};

export default RenderItem;
