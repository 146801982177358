import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useGetCentersQuery } from "features/deshboard/service";
import { mangeFilter } from "features/toutes/slice";
import useFilter from "hooks/useFilter";
import React, { useEffect } from "react";
import { useAppDispatch } from "tools/store";

const Filerts = () => {
  const { data: centers = [], isLoading: loadingCenters } =
    useGetCentersQuery(undefined);

  const statusPec = [
    { id: "granted", name: "Accordée" },
    { id: "in_progress", name: "En cours" },
    { id: "refused", name: "Refusée" },
    { id: "to_do", name: "À faire" },
    { id: "sent_patient", name: "Envoi patient" },
    { id: "retry_1", name: "Relance Mutuelle" },
    { id: "retry_2", name: "Devis erroné" },
  ];
  const statusPateint = [
    { id: "granted", name: "Accordé" },
    { id: "voicemail", name: "Messagerie" },
    { id: "to_call", name: "À appeler" },
    { id: "pending_insurance_approval", name: "Attente mutuelle" },
    { id: "cancelled", name: "Annulé" },
    { id: "pending_patient", name: "En attente du patient" },
    { id: "refused", name: "Refusé" },
  ];
  const statusFac = [
    { id: "to_bill", name: "Patient à facturer" },
    { id: "billed", name: "Patient facturé" },
    { id: "to_bill", name: "Mutuelle à facturer" },
    { id: "billed", name: "Mutuelle facturée" },
  ];

  return (
    <>
      <SelectedDropDown
        label={"Filtrer Par Centres"}
        list={loadingCenters ? [] : centers}
        type={"per_centre"}
      />
      <SelectedDropDown
        label={"Statut Facturation"}
        list={statusFac}
        type={"facturation"}
      />
      <SelectedDropDown
        label={"Statut Patient"}
        list={statusPateint}
        type={"patient"}
      />
      <SelectedDropDown label={"Statut PEC"} list={statusPec} type={"pec"} />
    </>
  );
};

export default Filerts;

export const SelectedDropDown = ({
  label,
  list,
  type,
}: {
  label: string;
  list: any[];
  type: string;
}) => {
  let { count } = useFilter({ type });
  const dispatch = useAppDispatch();

  return (
    <FormControl
      variant="outlined"
      size="small"
      sx={{
        marginTop: -0.3,
      }}
    >
      <Select
        autoFocus={false}
        labelId="dropdown-label"
        id={"dropdown"}
        multiple
        value={[label]}
        onChange={() => {}}
        sx={{
          height: 50,
          // width: 220,
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline, &.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, &.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            { border: 0 },
          "&:hover": {
            opacity: 0.4,
          },
        }}
        onOpen={() => {
          let ss = document.querySelector(".MuiModal-backdrop") as any;
          ss?.click();
        }}
        onClose={() => {
          setTimeout(() => {
            let ss = document.activeElement as any;
            ss?.blur();
          }, 0);
        }}
        renderValue={(selected) => (
          <div>
            {count > 0 && (
              <Chip
                sx={{ marginTop: -0.5, marginRight: 0.5 }}
                label={count}
                size="small"
                onDelete={(val) => {
                  dispatch(mangeFilter({ type, item: [] }));
                  setTimeout(() => {
                    let ss = document.activeElement as any;
                    ss?.blur();
                  }, 0);
                }}
                onMouseDown={(event) => {
                  event.stopPropagation();
                }}
              />
            )}

            {selected.map((item, index) => (
              <Typography key={index} fontSize={13} mx={2}>
                {item}
              </Typography>
            ))}
          </div>
        )}
      >
        {list.map((item, index) => {
          return (
            <FilterMenuItem
              {...{ item, list, type, index: index + 1 }}
              key={index}
            />
          );
        })}
      </Select>
    </FormControl>
  );
};

const FilterMenuItem = ({ item, list, index, type }: any) => {
  const [isLocalChecked, setChecked] = React.useState(false);
  const dispatch = useAppDispatch();
  let { isChecked } = useFilter({ type, item });

  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  const onClick = () => {
    setChecked(!isLocalChecked);
    dispatch(mangeFilter({ type, item }));
  };

  return (
    <>
      <MenuItem
        sx={{ zIndex: "unset" }}
        key={index}
        value={"item" + index}
        onClick={onClick}
      >
        <Checkbox checked={isLocalChecked} sx={{color:"text.primary"}}/>
        <ListItemText primary={item.name} />
      </MenuItem>
      {index < list.length - 1 && (
        <Box
          sx={{ width: "95%", height: 1.5, background: "#eee", margin: "auto" }}
        />
      )}
    </>
  );
};
