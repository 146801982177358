import api from "tools/api";
import { FormToUrl } from "utils";

export const GlobalService = api.injectEndpoints({
  endpoints: (build) => ({
    getReqToutes: build.query({
      query: () => "",
      providesTags: ["toats"],
    }),
    deleteMedia: build.mutation({
      query: (mediaId) => ({
        url: `media/${mediaId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["toats"],
    }),
    uploadMedia: build.mutation({
      // query: (file) => ({
      //   url: "media",
      //   method: "POST",
      //   body: file,
      // }),
      query: (file) => ({
        url: 'media',
        method: 'POST',        
        body: file,
      }),
      invalidatesTags: ["toats"],
    }),
    mediaUpdate: build.mutation({
      async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
        try {
          if (_arg.id) {
            const deleteMediaRes: any = await fetchWithBQ({
              url: `media/${_arg.id}`,
              method: "DELETE",
            });

            if (deleteMediaRes.error) throw deleteMediaRes.error;
          }

          let mediaRes: any = { data: undefined };

          console.log("======body::", JSON.stringify(_arg.body));
            const createMediaRes: any = await fetchWithBQ({
              url: "media",
              method: "POST",
              body: _arg.body,
            });

            if (createMediaRes.error) throw createMediaRes.error;
            if (createMediaRes.data) mediaRes.data = createMediaRes.data;

          return {
            data: mediaRes.data,
          };
        } catch (error: any) {
          return error.status
            ? { error }
            : { error: { status: 400, data: error } };
        }
      },
      invalidatesTags: ["Session"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetReqToutesQuery,
  useGetReqToutesQuery,
  useDeleteMediaMutation,
  useUploadMediaMutation,
  useMediaUpdateMutation,
} = GlobalService;
