import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Stack, Typography } from '@mui/material';
import { memo, useEffect, useRef, useState, useCallback } from "react";
import Card from './Card';
import TopHeading from './TopHeading';

const ToDo = ({ title, url, name, sort, archived, min }: any) => {
    const [data, setData] = useState<any[]>([]);
    const [page, setPage] = useState<number>(0);
    const [header, setHeader] = useState("");
    const containerRef = useRef<HTMLDivElement | null>(null);
    const token = localStorage.getItem('appToken');

    // Utiliser useCallback pour éviter de recréer la fonction à chaque rendu
    const fetchData = useCallback(async () => {
        if (!url) return;

        try {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${token}`);

            const tempUrl = new URL(url);
            tempUrl.searchParams.set("page", page.toString());

            const response = await fetch(tempUrl.toString(), {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            });

            if (!response.ok) {
                throw new Error(`Erreur API: ${response.status}`);
            }

            const headersObj: any = {};
            response.headers.forEach((value, name) => {
                headersObj[name] = value;
            });

            setHeader(headersObj["pagination-count"]?.toString() || "0");

            const result = await response.json();

            if (Array.isArray(result)) {
                setData(prevData => {
                    if (page === 0) {
                        return result; // Remplacer les données si nouvelle recherche
                    } else {
                        const updatedData = result.filter(item => 
                            !prevData.some(existingItem => existingItem.id === item.id)
                        );
                        return [...prevData, ...updatedData];
                    }
                });
            }
        } catch (error) {
            console.error("Erreur lors du fetch:", error);
        }
    }, [page, url, token]);

    // Gère le scroll infini
    const handleScroll = useCallback(() => {
        const container = containerRef.current;
        if (container) {
            const { scrollTop, clientHeight, scrollHeight } = container;
            if (scrollTop + clientHeight >= scrollHeight - 10) {
                setPage(prevPage => prevPage + 1); // Mise à jour sécurisée
            }
        }
    }, []);

    // Charger les données à chaque changement de filtre ou de tri
    useEffect(() => {
        setData([]); // Reset data
        setPage(0);
    }, [name, sort, archived, min]);

    // Charger les données lorsqu'on change de page
    useEffect(() => {
        fetchData();
    }, [page, fetchData]);

    // Ajouter l'écouteur d'événements pour le scroll
    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll]);

    return (
        <Stack bgcolor={"#fafdff"} border={1} borderRadius={2}
            height={"calc(100vh - 163px)"}
            borderColor={"gray"} width={"100%"} pb={.2}
            boxShadow={"3px 1px 5px #855191"}
        >
            <TopHeading title={title} count={header} />

            <Stack ref={containerRef} paddingX={2} sx={{ overflowY: data.length > 0 ? 'auto' : 'none', overflowX: "hidden" }}>
                {data.length > 0 ? (
                    data.map((item, index) => (
                        <Card key={item.id || index} title={title} item={item} />
                    ))
                ) : (
                    <Stack mt={20} width={"20rem"} alignItems={"center"}>
                        <ErrorOutlineIcon sx={{ fontSize: 36, color: "gray" }} />
                        <Typography textAlign={"center"} color={"gray"}>
                            Aucune donnée
                        </Typography>
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
}

export default memo(ToDo);
