import api from 'tools/api';
import { setSignedInUser } from './slice';

export const AuthenticationService = api.injectEndpoints({
    endpoints: (build) => ({
        // signIn: build.mutation({
        //     query: (body) => ({
        //         url: '/auth/login',
        //         method: 'POST',
        //         body,
        //     }),
        //     invalidatesTags: ['Session'],
        // }),

        signIn: build.mutation({
            async queryFn(_arg, _queryApi, _extraOptions, fetchWithBQ) {
                // const token = await getFCMToken()
                try {
                    const loginResponse: any = await fetchWithBQ({
                        url: "/auth/login",
                        method: "POST",
                        body: _arg,
                    });

                    if (loginResponse.error) throw loginResponse.error;

                    localStorage.setItem('appToken', loginResponse?.data?.token);
                    let talentResponse: any = { data: undefined };

                    if (loginResponse.data) {
                        const userResponse: any = await fetchWithBQ(`users/${loginResponse?.data?.user_id}`);
                        if (userResponse.error) throw userResponse.error;
                        if (userResponse.data) {
                            _queryApi.dispatch(setSignedInUser(userResponse.data));
                            talentResponse.data = userResponse.data;
                        } else {
                            throw "Not a valid Guardian Profile";
                        }
                    }
                    if (talentResponse.error) throw talentResponse.error;

                    return {
                        data: talentResponse.data,
                    };
                } catch (error: any) {
                    return error.status ? { error } : { error: { status: 400, data: error } };
                }
            },
            invalidatesTags: ['Session']
        }),


        getUsersData: build.query({
            query: (user_id) => `users/${user_id}`,
        }),
    }),
    overrideExisting: false,
});

export const {
    useSignInMutation,
    useLazyGetUsersDataQuery,    
} = AuthenticationService;