import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import React from 'react';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { PrimaryButton } from 'components';
import { useAppSelector } from 'tools/store';
import { getUser } from 'features/auth/slice';
import { useUpdateUserMutation } from 'features/admin/service';
import { ToastView } from 'utils';

interface FormValues {
    firstname: string;
    lastname: string;
    email: string;
    password: string | undefined | null;
}

const validationSchema = Yup.object().shape({
    firstname: Yup.string().required('Prénom requis'),
    lastname: Yup.string().required('Nom requis'),
    email: Yup.string().email('Adresse email invalide').required('Email requis'),
    // password: Yup.string().min(8, 'Le mot de passe doit contenir au moins 8 caractères').required('Mot de passe requis'),
    password: Yup.string().min(8, 'Le mot de passe doit contenir au moins 8 caractères').optional(),
});

const MyyProfile = () => {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const [onUpdate, { isLoading: isUpdating }] = useUpdateUserMutation();

    const user = useAppSelector(getUser)

    const initialValues: FormValues = {
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        password: undefined,
    };

    const onSubmit = async (values: FormValues, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
        console.log(values);
        try {
            onUpdate({ id: user.id, body: values })
                .then((res: any) => {
                    if (!res?.error) {
                        ToastView("Utilisateur mis à jour !", "success");
                    }
                })
                .catch((e) => {
                    console.log("=-=-=-=-=-:::::", e);
                });
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            setSubmitting(false);
        }
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
    } = formik;

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={4}
                sx={{
                    borderRadius: 3,
                    overflow: "hidden",
                    boxShadow: "3px 3px 14px #cfbfd9",
                    maxHeight: "calc(100% - 140px)",
                    padding: 4,
                    margin: 5
                }}>
                <TextField
                    id="username"
                    label="Nom d'utilisateur"
                    placeholder="Nom d'utilisateur..."
                    disabled
                    value={user.username}
                    fullWidth
                    InputLabelProps={{ shrink: true, disabled: true, sx: { color: 'gray' } }}
                />
                <TextField
                    id="firstname"
                    name="firstname"
                    label="Prénom"
                    placeholder="Votre prénom..."
                    required
                    fullWidth
                    value={values.firstname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.firstname && Boolean(errors.firstname)}
                    helperText={touched.firstname && errors.firstname}
                    InputLabelProps={{ shrink: true, sx: { color: 'gray' } }}
                />
                <TextField
                    id="lastname"
                    name="lastname"
                    label="Nom"
                    placeholder="Votre nom..."
                    required
                    fullWidth
                    value={values.lastname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.lastname && Boolean(errors.lastname)}
                    helperText={touched.lastname && errors.lastname}
                    InputLabelProps={{ shrink: true, sx: { color: 'gray' } }}
                />
                <TextField
                    id="email"
                    name="email"
                    label="Email"
                    placeholder="Votre email..."
                    type="email"
                    required
                    fullWidth
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    InputLabelProps={{ shrink: true, sx: { color: 'gray' } }}
                    sx={{ display: 'none' }}  // Ajoute cette ligne pour cacher le champ
                />
                <TextField
                    id="password"
                    name="password"
                    label="Mot de passe (8 caractères minimum)"
                    placeholder="Mot de passe..."
                    fullWidth
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type={showPassword ? 'text' : 'password'}
                    InputLabelProps={{ shrink: true, sx: { color: 'gray' } }}
                    InputProps={{
                        endAdornment:
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleShowPassword}
                                >
                                    {!showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                    }}
                // error={touched.password && Boolean(errors.password)}
                // helperText={touched.password && errors.password}
                />
                <Stack alignItems={"flex-end"}>
                    <PrimaryButton title={'Enregistrer'} boxShadow='' type='submit' loading={isUpdating} />
                </Stack>
            </Stack>
        </form>
    );
}

export default MyyProfile
