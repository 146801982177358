import { Route, Routes } from "react-router-dom";
import Utilisateurs from "./pages/Utilisateurs";
import Centers from "./pages/Centers";
import Mutuelles from "./pages/Mutuelles";
import Payments from "./pages/Payments";

const AdminStack = () => {

    return (
        <Routes>
            <Route path="*" element={<Utilisateurs />} />
            <Route path="/centers" element={<Centers />} />
            <Route path="/mutuelles" element={<Mutuelles />} />
            <Route path="/payment" element={<Payments />} />
        </Routes>
    )
};

export default AdminStack;