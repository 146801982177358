import api from "tools/api";
import { FormToUrl } from "utils";

interface ReqParamsInterface {
  page?: number;
  limit?: number;
  any_names?: string;
  params?: any;
}

export const DashboardService = api.injectEndpoints({
  endpoints: (build) => ({
    getReqToutes: build.query({
      query: (props?: ReqParamsInterface) => {
        let url = "requests?is_archived=false";
        if (props)
          Object.entries(props).map(([key, value]: any) => {
            if (key === "params") {
              url += `&${FormToUrl(value)}`;
            } else url += `&${key}=${value}`;
          });
        return url;
      },
      providesTags: ["toats"],
    }),
    getReqArchived: build.query({
      query: (props?: ReqParamsInterface) => {
        let url = "requests?is_archived=true&priority=true&sort=date&order=desc";
        if (props)
          Object.entries(props).map(([key, value]: any) => {
            if (key === "params") {
              url += `&${FormToUrl(value)}`;
            } else url += `&${key}=${value}`;
          });
        return url;
      },
      providesTags: ["toats"],
    }),
    getReqUnArchived: build.query({
      query: (props?: ReqParamsInterface) => {
        let url = "requests?is_archived=false&priority=true&sort=date&order=desc";
        if (props)
          Object.entries(props).map(([key, value]: any) => {
            if (key === "params") {
              url += `&${FormToUrl(value)}`;
            } else url += `&${key}=${value}`;
          });
        return url;
      },
      providesTags: ["toats"],
    }),
    updateToArchive: build.mutation({
      query: (body) => ({
        url: `requests/archive`,
        method: "PATCH",
        body: body,
      }),
      invalidatesTags: ["toats"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetReqToutesQuery,
  useLazyGetReqArchivedQuery,
  useLazyGetReqUnArchivedQuery,
  useUpdateToArchiveMutation,
} = DashboardService;
