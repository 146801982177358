import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  ButtonBase,
  Input,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  Typography
} from "@mui/material";
import React, { useState } from "react";

const statusObj: any = {
  date: "Date de Creation",
  status_update: "Maj statut PEC",
  patient_status_update: "Maj statut Patient",
  Archivées: "Archivées",
  Actives: "Actives",
  "Mes Pecs": "Mes Pecs",
  Toutes: "Toutes",
};
const StatusBar = ({ name, onChange, onSort, onMin, onArchive, searchType, setSearchType }: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorE2, setAnchorE2] = useState<null | HTMLElement>(null);
  const [anchorE3, setAnchorE3] = useState<null | HTMLElement>(null);

  const [sort, setSort] = useState("Maj statut PEC");
  const [min, setMin] = useState("Toutes");
  const [archived, setArchived] = useState("Actives");

  const handleMenuClick = (setter: any) => (event: React.MouseEvent<HTMLButtonElement>) => {
    setter(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorE2(null);
    setAnchorE3(null);
  };

  const renderMenu = (anchor: any, open: boolean, options: string[], setter: any, onSelect: (value: string) => void) => (
    <Menu anchorEl={anchor} open={open} onClose={handleClose}>
      {options.map((it) => (
        <MenuItem
          key={it}
          style={{ width: 200 }}
          onClick={() => {
            handleClose();
            onSelect(it);
          }}
        >
          {statusObj[it]}
        </MenuItem>
      ))}
    </Menu>
  );

  return (
<Stack
  width={"97%"} // ✅ Ajustement léger pour éviter de trop serrer
  margin={"auto"}
  py={0.8}  // ✅ Légère réduction de l’espace vertical
  bgcolor={"#162a4d"}
  borderRadius={3}
  boxShadow={"0px 4px 4px rgba(7, 25, 130, 0.5)"}
  justifyContent={"center"}
  mt={1.7} // ✅ Ajustement fin de la marge du haut
  position={"relative"}
  zIndex={2}
>


      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mx={2}
      >
        <InputSearchComponent value={name} onChange={onChange} />

        <Stack direction={"row"} spacing={1}>
          <FilterComponent title={sort} onClick={handleMenuClick(setAnchorEl)} />
          <FilterComponent title={min} onClick={handleMenuClick(setAnchorE2)} />
          <FilterComponent title={archived} onClick={handleMenuClick(setAnchorE3)} />

          {renderMenu(anchorEl, Boolean(anchorEl), ["date", "status_update", "patient_status_update"], setSort, (it) => {
            onSort(it);
            setSort(statusObj[it]);
          })}

          {renderMenu(anchorE2, Boolean(anchorE2), ["Toutes", "Mes Pecs"], setMin, (it) => {
            onMin(it === "Toutes");
            setMin(statusObj[it]);
          })}

          {renderMenu(anchorE3, Boolean(anchorE3), ["Actives", "Archivées"], setArchived, (it) => {
            onArchive(it === "Actives");
            setArchived(statusObj[it]);
          })}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default StatusBar;

const FilterComponent = ({ title, onClick }: any) => {
  return (
    <ButtonBase
      onClick={onClick}
      style={{ borderRadius: 8, textAlign: "start", padding: 4 }} // ✅ Padding réduit
    >
      <Stack
        bgcolor={"#FFFFFF"}
        color={"text.primary"}
        borderRadius={2}
        width={180} // ✅ Réduction de la largeur
        height={38}  // ✅ Réduction de la hauteur
        justifyContent={"space-between"}
        direction={"row"}
        alignItems={"center"}
        px={1.5}  // ✅ Moins de padding horizontal
      >
        <Typography fontSize={13}> {/* ✅ Texte légèrement plus petit */}
          {title}
        </Typography>
        <ArrowDropDownIcon sx={{ marginRight: 1 }} />
      </Stack>
    </ButtonBase>
  );
};

export const InputSearchComponent = ({ value, onChange, w = "32%", icon = true }: any) => {
  const [searchType, setSearchType] = useState("all");

  return (
    <Stack direction={"row"} alignItems={"center"} width={w} spacing={1.5}>
      {icon && <FilterAltOutlinedIcon sx={{ color: "#FFFFFF", marginRight: 2 }} />}

    <select
  value={searchType}
  onChange={(e) => setSearchType(e.target.value)} // ✅ Met à jour searchType
  style={{
    height: 38,
    borderRadius: 4,
    padding: "5px",
    backgroundColor: "#FFFFFF",
    border: "none",
    marginRight: "10px",
    cursor: "pointer",
    display: "none", // ✅ Masque la liste déroulante sans la supprimer
  }}
>
  <option value="all">Tous</option>
  <option value="patient">Patient</option>
  <option value="doctor">Docteur</option>
  <option value="center">Centre</option>
  <option value="devis">Numéro de devis</option>
</select>


      <Input
        placeholder="Recherche"
        value={value}
        onChange={(event) => onChange(event, searchType)} // ✅ On passe onChange correctement
        sx={{
          bgcolor: "#FFFFFF",
          width: "75%",
          height: 38,
          borderRadius: 1.5,
          paddingX: 1.5,
          "&:hover, &:focus-within": {
            border: 1,
          }
        }}
        endAdornment={
          <InputAdornment position="end">
            <SearchOutlinedIcon sx={{ color: "text.primary" }} />
          </InputAdornment>
        }
        disableUnderline={true}
      />
    </Stack>
  );
};
