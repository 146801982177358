import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { isArray } from 'lodash';
import type { RootState } from '../../tools/store';
import { CommonSliceInterface, SnackbarInterface } from './types';

const initialState: CommonSliceInterface = {
  showPermissionModal: false,
  showNotificationModal: false,
  selectedWarehouses: []
};

export const GlobalReducerName = 'global';

export const globalSlice = createSlice({
  name: GlobalReducerName,
  initialState,
  reducers: {
    togglePermissionModal(state, action: PayloadAction<any | null>) {
      state.showPermissionModal = action.payload;
    },
    toggleNotificationModal(state, action: PayloadAction<any | null>) {
      state.showNotificationModal = action.payload;
    },
    updateErrorMessage(state, action: PayloadAction<SnackbarInterface | undefined>) {
      state.snackBar = action.payload;
    },
    setSelectedWarehouses(state, action: PayloadAction<{ label: string; value: string }[]>) {
      state.selectedWarehouses = action.payload;
    }
  }
});

export const getSnackBar = (state: RootState) => state.global.snackBar;
export const showNotificationModal = (state: RootState) => state.global.showNotificationModal;
export const showPermissionModal = (state: RootState) => state.global.showPermissionModal;
export const getSelectedWarehouses = (state: RootState) => state.global.selectedWarehouses;
export const getSelectedWarehouseID = (state: RootState) => {
  // const warehouses = state.global.selectedWarehouses;
  // if (isArray(warehouses) && warehouses.length > 0) {
  //   return warehouses[0].value;
  // } else if (!isArray(warehouses) && warehouses) {
  //   return warehouses.value;
  // }
};
export const {
  updateErrorMessage,
  togglePermissionModal,
  toggleNotificationModal,
  setSelectedWarehouses
} = globalSlice.actions;
export default globalSlice.reducer;
