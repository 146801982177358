


const PrivacyPolicy = () => {
    return (
        <div style={{ margin:10 }}>
            <h1>Définitions</h1>
            <br />
            <p>
                <strong>L'éditeur</strong> : La personne, physique ou morale, qui édite les services de
                communication au public en ligne.
                <br />
                <strong>Le Site</strong> : L'ensemble des sites, pages Internet et services en ligne proposés par
                l'Éditeur.
                <br />
                <strong>L'Utilisateur</strong> : La personne utilisant le Site et les services.
            </p>
            <br />
            <h1>1- Nature des données collectées</h1>
            <br />
            <p>
                <strong
                >Dans le cadre de l'utilisation des Sites, l'Éditeur est susceptible de collecter les catégories
                    de données suivantes concernant ses Utilisateurs :</strong>
                Données d'état-civil, d'identité, d'identification, médicales... <br />
            </p>
            <br />
            <h1>2- Communication des données personnelles à des tiers</h1>
            <br />
            <p>
                <strong>Pas de communication à des tiers :</strong>
                Vos données ne font l'objet d'aucune communication à des tiers. Vous êtes toutefois informés qu'elles
                pourront être divulguées en application d'une loi, d'un règlement ou en vertu d'une décision d'une
                autorité réglementaire ou judiciaire compétente.
            </p>
            <br />
            <h1>
                3- Information préalable pour la communication des données personnelles à des tiers en cas de
                fusion / absorption
            </h1>
            <br />
            <p>
                <strong
                >Information préalable et possibilité d’opt-out avant et après la fusion / acquisition :</strong>
                Dans le cas où nous prendrions part à une opération de fusion, d’acquisition ou à toute autre forme
                de cession d’actifs, nous nous engageons à garantir la confidentialité de vos données personnelles
                et à vous informer avant que celles-ci ne soient transférées ou soumises à de nouvelles règles de confidentialité.
            </p>
            <br />
            <h1>4- Agrégation des données</h1>
            <br />
            <p>
                <strong>Agrégation avec des données non personnelles :</strong>
                Nous pouvons publier, divulguer et utiliser les informations agrégées (informations relatives à tous
                nos Utilisateurs ou à des groupes ou catégories spécifiques d'Utilisateurs que nous combinons de manière
                à ce qu'un Utilisateur individuel ne puisse plus être identifié ou mentionné) et les informations non
                personnelles à des fins d'analyse du secteur et du marché, de profilage démographique, à des fins promotionnelles
                et publicitaires et à d'autres fins commerciales.
                <br />
                <strong
                >Agrégation avec des données personnelles disponibles sur les comptes sociaux de l'Utilisateur :</strong>
                Si vous connectez votre compte à un compte d’un autre service afin de faire des envois croisés, ledit
                service pourra nous communiquer vos informations de profil, de connexion, ainsi que toute autre information
                dont vous avez autorisé la divulgation. Nous pouvons agréger les informations relatives à tous nos
                autres Utilisateurs, groupes, comptes, aux données personnelles disponibles sur l’Utilisateur.
            </p>
            <br />
            <h1>5- Collecte des données d'identité</h1>
            <br />
            <p>
                <strong>Utilisation d’un pseudonyme :</strong>
                L’utilisation du Site nécessite une inscription sans identification préalable. Elle peut s’effectuer
                sans que vous ne communiquiez de données nominatives vous concernant (nom, prénom, adresse, etc). Vous
                pouvez utiliser le pseudonyme de votre choix.
            </p>
            <br />
            <h1>6- Collecte des données d'identification</h1>
            <br />
            <p>
                <strong
                >Utilisation de l'identifiant de l’utilisateur uniquement pour l’accès aux services :</strong>
                Nous utilisons vos identifiants électroniques seulement pour et pendant l'exécution du contrat.
            </p>
            <br />
            <h1>7- Collecte des données du terminal</h1>
            <br />
            <p>
                <strong>Aucune collecte des données techniques :</strong>
                Nous ne collectons et ne conservons aucune donnée technique de votre appareil (adresse IP, fournisseur
                d'accès à Internet...).
            </p>
            <br />
            <h1>8- Cookies</h1>
            <br />
            <p>
                <strong>Durée de conservation des cookies : </strong>
                Conformément aux recommandations de la CNIL, la durée maximale de conservation des cookies est de 13
                mois au maximum après leur premier dépôt dans le terminal de l'Utilisateur, tout comme la durée de
                la validité du consentement de l’Utilisateur à l’utilisation de ces cookies. La durée de vie des cookies
                n’est pas prolongée à chaque visite. Le consentement de l’Utilisateur devra donc être renouvelé à l'issue
                de ce délai.
                <br />
                <strong>Finalité cookies :</strong>
                Les cookies peuvent être utilisés pour des fins statistiques notamment pour optimiser les services
                rendus à l'Utilisateur, à partir du traitement des informations concernant la fréquence d'accès, la
                personnalisation des pages ainsi que les opérations réalisées et les informations consultées. Vous
                êtes informé que l'Éditeur est susceptible de déposer des cookies sur votre terminal. Le cookie enregistre
                des informations relatives à la navigation sur le service (les pages que vous avez consultées, la date
                et l'heure de la consultation...) que nous pourrons lire lors de vos visites ultérieures.
                <br />
                <strong>Opt-in pour le dépôt de cookies :</strong>
                Nous n'utilisons pas de cookies. Si nous devions en utiliser à l’avenir, vous en seriez informé préalablement
                et auriez la possibilité de désactiver ces cookies.
            </p>
            <br />
            <h1>9 - Conservation des données techniques</h1>
            <br />
            <p>
                <strong>Durée de conservation des données techniques :</strong>
                Les données techniques sont conservées pour la durée strictement nécessaire à la réalisation des finalités
                visées ci-avant.
            </p>
            <br />
            <h1>10- Délai de conservation des données personnelles et d'anonymisation</h1>
            <br />
            <p>
                <strong>Conservation des données pendant la durée de la relation contractuelle :</strong>
                Conformément à l'article 6-5° de la loi n°78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers
                et aux libertés, les données à caractère personnel faisant l'objet d'un traitement ne sont pas conservées
                au-delà du temps nécessaire à l'exécution des obligations définies lors de la conclusion du contrat
                ou de la durée prédéfinie de la relation contractuelle.
                <br />
                <strong
                >Conservation des données anonymisées au delà de la relation contractuelle / après la
                    suppression du compte :</strong>
                Nous conservons les données personnelles pour la durée strictement nécessaire à la réalisation des
                finalités décrites dans les présentes Politiques de confidentialité. Au-delà de cette durée, elles
                seront anonymisées et conservées à des fins exclusivement statistiques et ne donneront lieu à aucune
                exploitation, de quelque nature que ce soit.
                <br />
                <strong>Suppression des données après suppression du compte :</strong>
                Des moyens de purge de données sont mis en place afin d'en prévoir la suppression effective dès lors
                que la durée de conservation ou d'archivage nécessaire à l'accomplissement des finalités déterminées
                ou imposées est atteinte. Conformément à la loi n°78-17 du 6 janvier 1978 relative à l'informatique,
                aux fichiers et aux libertés, vous disposez par ailleurs d'un droit de suppression sur vos données
                que vous pouvez exercer à tout moment en prenant contact avec l'Éditeur.
                <br />
                <strong>Suppression des données après 3 ans d'inactivité :</strong>
                Pour des raisons de sécurité, si vous ne vous êtes pas authentifié sur le Site pendant une période
                de trois ans, vous recevrez un e-mail vous invitant à vous connecter dans les plus brefs délais, sans
                quoi vos données seront supprimées de nos bases de données.
            </p>
            <br />
            <h1>11- Suppression du compte</h1>
            <br />
            <p>
                <strong>Suppression du compte à la demande :</strong>
                L'Utilisateur a la possibilité de supprimer son Compte à tout moment, par simple demande à l'Éditeur.
                <br />
                <strong>Suppression du compte en cas de violation de la Politique de Confidentialité :</strong>
                En cas de violation d'une ou de plusieurs dispositions de la Politique de Confidentialité ou de tout
                autre document incorporé aux présentes par référence, l'Éditeur se réserve le droit de mettre fin ou
                restreindre sans aucun avertissement préalable et à sa seule discrétion, votre usage et accès aux services,
                à votre compte et à tous les Sites.
            </p>
            <br />
            <h1>12- Indications en cas de faille de sécurité décelée par l'Éditeur</h1>
            <br />
            <p>
                <strong>Information de l'Utilisateur en cas de faille de sécurité :</strong>
                Nous nous engageons à mettre en oeuvre toutes les mesures techniques et organisationnelles appropriées
                afin de garantir un niveau de sécurité adapté au regard des risques d'accès accidentels, non autorisés
                ou illégaux, de divulgation, d'altération, de perte ou encore de destruction des données personnelles
                vous concernant. Dans l'éventualité où nous prendrions connaissance d'un accès illégal aux données
                personnelles vous concernant stockées sur nos serveurs ou ceux de nos prestataires, ou d'un accès non
                autorisé ayant pour conséquence la réalisation des risques identifiés ci-dessus, nous nous engageons
                à :
                <br />
                - Vous notifier l'incident dans les plus brefs délais ;
                <br />
                - Examiner les causes de l'incident et vous en informer ;
                <br />
                - Prendre les mesures nécessaires dans la limite du raisonnable afin d'amoindrir les effets négatifs
                et préjudices pouvant résulter dudit incident.
                <br />
                <strong>Limitation de la responsabilité :</strong>
                En aucun cas les engagements définis au point ci-dessus relatifs à la notification en cas de faille
                de sécurité ne peuvent être assimilés à une quelconque reconnaissance de faute ou de responsabilité
                quant à la survenance de l'incident en question.
            </p>
            <br />
            <h1>13- Transfert des données personnelles à l'étranger</h1>
            <br />
            <p>
                <strong>Pas de transfert en dehors de l'Union européenne :</strong>
                L'Éditeur s'engage à ne pas transférer les données personnelles de ses Utilisateurs en dehors de l'Union
                européenne.
                <br />
                <a href="https://www.cnil.fr/fr/la-protection-des-donnees-dans-le-monde"
                >https://www.cnil.fr/fr/la-protection-des-donnees-dans-le-monde</a>
            </p>
            <br />
            <h1>14- Modification de la politique de confidentialité</h1>
            <br />
            <p>
                <strong
                >En cas de modification de la présente Politique de Confidentialité, engagement de ne pas
                    baisser le niveau de confidentialité de manière substantielle sans l'information préalable des
                    personnes concernées.</strong>
                <br />
                Nous nous engageons à vous informer en cas de modification substantielle de la présente Politique de
                Confidentialité, et à ne pas baisser le niveau de confidentialité de vos données de manière substantielle
                sans vous en informer et obtenir votre consentement.
            </p>
            <br />
            <h1>15- Droit applicable et modalités de recours</h1>
            <br />
            <p>
                <strong>Application du droit français (législation CNIL) et compétence des tribunaux :</strong>
                La présente Politique de Confidentialité et votre utilisation du Site sont régies et interprétées conformément
                aux lois de France, et notamment à la Loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux
                fichiers et aux libertés. Le choix de la loi applicable ne porte pas atteinte à vos droits en tant
                que consommateur conformément à la loi applicable de votre lieu de résidence. Si vous êtes un consommateur,
                vous et nous acceptons de se soumettre à la compétence nonexclusive des juridictions françaises, ce
                qui signifie que vous pouvez engager une action relative à la présente Politique de Confidentialité
                en France ou dans le pays de l'UE dans lequel vous vivez. Si vous êtes un professionnel, toutes les
                actions à notre encontre doivent être engagées devant une juridiction en France.
                <br />
                En cas de litige, les parties chercheront une solution amiable avant toute action judiciaire. En cas
                d'échec de ces tentatives, toutes contestations à la validité, l'interprétation et / ou l'exécution
                de la Politique de Confidentialité devront être portées même en cas de pluralité des défendeurs ou
                d'appel en garantie, devant les tribunaux français.
            </p>
            <br />
            <h1>16- Portabilité des données</h1>
            <br />
            <p>
                <strong>Portabilité des données :</strong>L'Éditeur s'engage à vous offrir la possibilité de vous
                faire restituer l'ensemble des données vous concernant sur simple demande. L'Utilisateur se voit
                ainsi garantir une meilleure maîtrise de ses données, et garde la possibilité de les réutiliser.
                Ces données devront être fournies dans un format ouvert et aisément réutilisable.
            </p>
        </div>
    )
}

export default PrivacyPolicy