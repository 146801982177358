import {
  Box,
  ButtonBase,
  IconButton,
  Input,
  Stack,
  Typography,
} from "@mui/material";
import type { InputProps } from "@mui/material/Input";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useMediaQuery, useTheme } from "@mui/material";

export interface InputFeildType extends InputProps {
  boxShadow?: string;
  height?: number | string;
  width?: number | string;
  bgColor?: string;
  label?: string;
  errors?: string | undefined;
  text?: boolean;
  onClipBoard?: (res: string) => void;
  onClick?: () => void;
  icon?: React.ReactNode;
}

const InputFeild = ({
  boxShadow,
  height,
  width,
  bgColor,
  label,
  errors,
  text = false,
  onClick,
  onClipBoard,
  style = {},
  icon,
  ...props
}: InputFeildType) => {
  
  // ✅ Ajout de isMobile pour la gestion du responsive
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack
  width={"100%"}
  alignItems={"center"} // Centre le contenu
  justifyContent={"center"}
  sx={{
    padding: isMobile ? "10px" : "20px", // Padding ajusté pour mobile
    width: "90%", // Évite de toucher les bords sur mobile
    maxWidth: "400px", // Largeur max pour garder une bonne proportion
  }}
>
    <Box
      style={{
        width: "100%", // Permet d'être responsive
        maxWidth: "350px", // Empêche de devenir trop large sur desktop
      }}
    >
      <Stack direction={"row"} alignItems={"center"} mt={0.5} mb={0.2} spacing={1} color={"text.primary"}>
        {label && (
          <Typography ml={0.4} fontSize={13} fontWeight={icon ? "bold" : "normal"} color={icon ? "#3649EC" : undefined}>
            {label}
          </Typography>
        )}
        {icon}
      </Stack>

      {text || onClick ? (
        <ButtonBase
          sx={{
            width: "100%",
            marginBottom: 1.5,
            borderRadius: 2.5,
          }}
          disabled={!onClick}
          onClick={onClick}
        >
          <Stack
            direction={"row"}
            bgcolor={bgColor ?? "white"}
            height={height ?? 60}
            width={"100%"}
            px={2}
            borderRadius={2.5}
            alignItems={"center"}
            boxShadow={boxShadow ?? "4px 4px 12px rgba(28, 42, 77, 0.2)"}
            mb={onClick ? 0 : 1.5}
            mt={label ? 0 : 1.3}
            border={errors ? 2 : undefined}
            borderColor={errors ? "red" : undefined}
          >
            <Typography fontSize={14} display={"flex"} flex={1} alignItems={"center"}>
              {props?.value?.toString()}
            </Typography>
            <ArrowDropDownIcon sx={{ fontSize: 23, marginRight: -1 }} />
          </Stack>
        </ButtonBase>
      ) : (
        <Stack direction={"row"} alignItems={"center"}>
<Input
  {...props}
  sx={{ color: "text.primary", bgcolor: bgColor ?? "#ffffff" }}
  style={{
    border: errors ? "2px solid red" : undefined,
    fontSize: 15,
    borderRadius: 12,
    paddingInline: 16,
    paddingBlock: 12,
    height: props.multiline ? undefined : height ?? 50, // ✅ Ajuste la hauteur à 50px au lieu de 65px
    width: "100%", // ✅ Ajuste pour prendre toute la largeur de son conteneur
    maxWidth: "420px", // ✅ Fixe une largeur max alignée avec le bouton
    boxShadow: "4px 4px 12px rgba(7, 25, 130, 0.3)",
    marginTop: label ? 0 : 12,
    alignItems: props.multiline ? "start" : "center",
    ...style,
  }}
  disableUnderline={true}
/>

          {onClipBoard && (
            <IconButton onClick={() => onClipBoard(props.value as string)}>
              <ContentCopyIcon fontSize={"small"} />
            </IconButton>
          )}
        </Stack>
      )}
    </Box>
   </Stack>
  );
};

export default InputFeild;
