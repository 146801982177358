import { useEffect, useState } from 'react';
import { useAppSelector } from 'tools/store';

export default function useFilter({ type, item }: { type?: string, item?: any }) {

    const { per_centre, facturation, patient, pec } = useAppSelector((state: any) => state.filters)
    const [isChecked, setChecked] = useState(false);
    const [count, setCount] = useState(0);

    useEffect(() => {
        setCount(getData(`${type}_len`))
        if (item) {
            let selectedList = getData(`${type}`)
            selectedList?.map((it: any) => {
                if (it.name == item.name) {
                    setChecked(true)
                }
            })
        }
    }, [per_centre.length, facturation.length, patient.length, pec.length])

    const getData = (type?: string) => {
        let obj: any = {
            per_centre: per_centre,
            per_centre_len: per_centre.length,
            facturation: facturation,
            facturation_len: facturation.length,
            patient: patient,
            patient_len: patient.length,
            pec: pec,
            pec_len: pec?.length
        }
        const selectedProperties = {
            patient_billing_statuses: obj.facturation,
            patient_statuses: obj.patient,
            statuses: obj.pec,
            center_ids: obj.per_centre
        };
        return type ? obj[type] : selectedProperties
    }

    return { count, isChecked, data: getData(type) };
}
