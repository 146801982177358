import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { PrimaryButton } from "components";
import { useGetCentersQuery } from "features/deshboard/service";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  useCreateUserMutation,
  useDeleteUserMutation,
  useLazyResendUserQuery,
  useLazyResetUserQuery,
  useUpdateUserMutation,
} from "../../service";
import CustomStandardInput from "../CustomStandardInput";
import { ToastView } from "utils";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const validationSchema = Yup.object().shape({
  username: Yup.string().required("Username is required"),
  // password: Yup.string().required("Password is required"),
  email: Yup.string().email('Invalid email').required('Email is required'),
  // external_id: Yup.string().required("External_id is required"),
  firstname: Yup.string().required("Firstname is required"),
  centers: Yup.array().required("Centers is required"),
  lastname: Yup.string().required("Lastname is required"),
  role: Yup.string().required("Role is required"),
});

const UserForm: React.FC<{
  username?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  external_id?: number;
  centers?: any[];
  role?: string;
  id?: number;
  validated?: boolean;
  onClosed: () => void;
}> = ({
  username,
  email,
  external_id,
  firstname,
  centers,
  lastname,
  role,
  id,
  validated,
  onClosed,
}) => {
    const { data: centerList = [], isLoading: loadingCenters } =
      useGetCentersQuery(undefined);

    const [onDelete, { isLoading: isDeleting }] = useDeleteUserMutation();
    const [onReset, { isLoading: isResetLoad }] = useLazyResetUserQuery();
    const [onResend, { isLoading: isResending }] = useLazyResendUserQuery();
    const [onUpdate, { isLoading: isUpdating }] = useUpdateUserMutation();
    const [onCreate, { isLoading: isCreatting }] = useCreateUserMutation();

    const onSubmit = (values: any) => {
      if (id) {
        onUpdate({ id, body: values })
          .then((res: any) => {
            if (!res?.error) {
              ToastView("Utilisateur mis à jour !", "success");
              onClosed()
            }
          })
          .catch((e) => {
            console.log("=-=-=-=-=-:::::", e);
          });
      } else {
        onCreate(values)
          .then((res: any) => {
            if (!res?.error) {
              ToastView("Nouvel utilisateur ajouté !", "success");
              onClosed()
            }
          })
          .catch((e) => {
            console.log("=-=-=-=-=-:::::", e);
          });
      }
    };

    const {
      handleBlur,
      handleChange,
      values,
      handleSubmit,
      errors,
      touched,
      setFieldValue,
    } = useFormik({
      initialValues: {
        username: username || undefined,
        password: undefined,
        email: email || undefined,
        external_id: external_id || undefined,
        firstname: firstname || undefined,
        centers: centers || undefined,
        lastname: lastname || undefined,
        role: role || "admin",
      },
      validationSchema,
      onSubmit,
    });

    return (
      <Stack
        flex={1}
        height={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Stack my={4} width={"100%"}>
          <CustomStandardInput
            label="Nom d'utilisateur"
            value={values.username}
            name="username"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.username && errors.username ? true : false}
          />
          <CustomStandardInput
            label="Email"
            value={values.email}
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && errors.email ? true : false}
          />
          <CustomStandardInput
            label="Mot de passe (8 caractères minimum)"
            pass
            value={values.password}
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.password && errors.password ? true : false}
          />
          <CustomStandardInput
            label="Prénom"
            value={values.firstname}
            name="firstname"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.firstname && errors.firstname ? true : false}
          />
          <CustomStandardInput
            label="Nom de famille"
            value={values.lastname}
            name="lastname"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.lastname && errors.lastname ? true : false}
          />
          <CustomStandardInput
            label="ID DESMOS"
            value={values.external_id}
            name="external_id"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.external_id && errors.external_id ? true : false}
          />

          <FormControl
            variant="standard"
            sx={{
              height: 60,
              "& .MuiInputLabel-root": {
                paddingLeft: "10px",
                fontSize: 14,
              },
              "& .MuiInputBase-root": {
                paddingLeft: "10px",
                fontSize: 14,
              },
            }}
          >
            <InputLabel sx={{ color: "gray" }} id="demo-simple-select-standard-label">Role</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={values.role}
              onChange={handleChange}
              name="role"
              label="Role"
            >
              {[
                { id: "admin", role: "admin", text: "Administrateur" },
                { id: "manager", role: "manager", text: "Directeur" },
                { id: "secretary", role: "secretary", text: "Secrétaire" },
                { id: "doctor", role: "doctor", text: "Praticien" },
                {
                  id: "insurance_secretary",
                  role: "insurance_secretary",
                  text: "Tiers Payant",
                },
              ].map((it) => (
                <MenuItem value={it.id}>{it.text}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            variant="standard"
            sx={{
              height: 60,
              "& .MuiInputLabel-root": {
                paddingLeft: "10px",
                fontSize: 14,
              },
              "& .MuiInputBase-root": {
                paddingLeft: "10px",
                fontSize: 14,
              },
            }}
          >
            <InputLabel id="demo-simple-select-standard-label" sx={{ color: "gray" }}>
              Centers
            </InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              label="Centers"
              multiple
              value={(values?.centers || []).map((it: any) => it.name)}
              renderValue={(selected) => selected?.join(", ")}
              MenuProps={MenuProps}
            >
              {(centerList || [])?.map((it: any) => {
                const tempList = values?.centers
                  ? [...values?.centers]
                  : [];
                const findedIndex = tempList.findIndex(
                  (item: any) => item?.id === it?.id
                );
                const check = findedIndex > -1 ? true : false
                return (
                  <MenuItem
                    key={it.id}
                    value={it.id}
                    onClick={() => {
                      const temp = { ...it };
                      if (temp.payments) {
                        delete temp.payments;
                      }
                      if (check) {
                        tempList.splice(findedIndex, 1);
                      } else {
                        tempList.push(temp);
                      }
                      setFieldValue("centers", tempList);
                    }}
                  >
                    <Checkbox
                      checked={check ? true : false}
                    />
                    <ListItemText primary={it.name} />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Stack>

        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          width={"97%"}
          py={2}
        >
          {id && (
            <PrimaryButton
              title="Supprimer"
              onClick={() => onDelete(id).then((res: any) => {
                if (!res?.error) {
                  ToastView("Supprimé avec succès", "success");
                  onClosed()
                }
              })}
              style={{ width: 150, paddingBlock: 15, borderRadius: 6 }}
              boxShadow=""
              loading={isDeleting}
            />
          )}
          {firstname && username && (
            <PrimaryButton
              title="Reset MDP"
              onClick={() => onReset(username)}
              style={{ width: 150, paddingBlock: 15, borderRadius: 6 }}
              boxShadow=""
              loading={isResetLoad}
            />
          )}
          {id === undefined && <Stack />}
          <Stack direction={"row"} spacing={1}>
            {!validated && id && (
              <PrimaryButton
                title="Confirmation Email"
                onClick={() => onResend(username)}
                style={{ width: 150, paddingBlock: 15, borderRadius: 6 }}
                boxShadow=""
                loading={isResending}
              />
            )}
            <PrimaryButton
              title="Enregister"
              onClick={() => handleSubmit()}
              style={{ width: 150, paddingBlock: 15, borderRadius: 6 }}
              boxShadow=""
              loading={isUpdating || isCreatting}
            />
          </Stack>
        </Stack>
      </Stack>
    );
  };

export default UserForm;
