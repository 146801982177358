import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Collapse,
  Divider,
  List,
  Stack,
  TablePagination,
} from "@mui/material";
import { PrimaryButton } from "components";
import { CustomInput } from "features/toutes/components/EnhancedTableHead";
import React, { useState } from "react";
import MutuellesRenderItem from "../components/mutuelles/MutuellesRenderItem";
import MutuellsForm from "../components/mutuelles/MutuellsForm";
import {
  useGetInsurancesListQuery,
  useSyncInsurancesMutation,
} from "../service";
import { ToastView } from "utils";

const Mutuelles = () => {
  const [openNew, setNew] = useState(false);
  const [onSync, { isLoading: isSyncing }] = useSyncInsurancesMutation();

  const [filter, setFilter] = useState({
    limit: 10,
    page: 0,
    name: "",
  });
  const { data } = useGetInsurancesListQuery(filter);

  const handleChangePage = (event: unknown, newPage: number) => {
    setFilter({ ...filter, page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFilter({ ...filter, page: 0, limit: parseInt(event.target.value, 10) });
  };

  return (
    <Stack px={3} height={"100%"}>
      <Stack direction={"row"} justifyContent={"space-between"} my={2}>
        <Stack direction={"row"} width={"100%"}>
          <Box
            sx={{
              alignItems: "center",
              height: 48,
              display: "flex",
              flexGrow: 1,
              mr: 5,
              position: "relative",
            }}
          >
            <Box sx={{ position: "absolute", left: 7, width: 40, top: 14 }}>
              <SearchIcon sx={{ width: 30, height: 20 }} />
            </Box>

            <CustomInput
              type="text"
              value={filter.name}
              onChange={(val) =>
                setFilter({ ...filter, name: val.target.value })
              }
              placeholder="Rechercher.."
            />
          </Box>
        </Stack>

        <Stack direction={"row"} spacing={5}>
          <PrimaryButton
            title={"Synchroniser"}
            style={{ width: 150, paddingBlock: 15, borderRadius: 6 }}
            boxShadow=""
            onClick={() =>
              onSync().then((res: any) => {
                if (!res?.error) {
                  ToastView("Synchronisation réussie", "success");
                }
              })
            }
            loading={isSyncing}
          />
          <PrimaryButton
            title={openNew ? "Annuler" : "Ajouter"}
            style={{ width: 80, paddingBlock: 15, borderRadius: 6 }}
            boxShadow=""
            onClick={() => setNew(!openNew)}
          />
        </Stack>
      </Stack>

      <Stack
        width={"100%"}
        sx={{
          borderRadius: 2,
          overflow: "hidden",
          boxShadow: "5px 3px 12px #cfbfd9",
          maxHeight: "calc(100% - 140px)",
        }}
      >
        <List
          sx={{ width: "100%", overflowY: "auto", overflowX: "none" }}
          aria-labelledby="nested-list-subheader"
        >
          {openNew && (
            <>
              <Collapse in={openNew} timeout="auto" unmountOnExit>
                <MutuellsForm onClosed={() => setNew(false)} />
              </Collapse>
              <Divider />
            </>
          )}
          {data?.map((item: any, index: number) => (
            <MutuellesRenderItem item={item} index={index} />
          ))}
        </List>
      </Stack>
      <TablePagination
        rowsPerPageOptions={[10, 15, 20, 50]}
        component="div"
        count={-1}
        rowsPerPage={filter.limit}
        page={filter.page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Stack>
  );
};

export default Mutuelles;
