import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Avatar, Stack, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Jsons, colorJson, replacer } from 'utils';




function Card({ title, item }: any) {

  const colorPicker = { bgColor: colorJson[title.toLowerCase()].color, fntColor: "black" }

  const now = moment(new Date());
  const duration = moment.duration(now.diff(moment(item?.status_update)));

  // Format the duration in the desired way (e.g., "10m" for 10 minutes)
  const formattedDuration = duration.humanize();
  const navigator = useNavigate();


  const TooltipfullName = `Dr ${item?.user?.firstname} ${item?.user?.lastname}`
  const fullName = item?.patient_lastname + " " + item?.patient_name;
  // const maxLength = 22; // Adjust the maximum length as needed

  let truncatedFullName = fullName;
  // if (fullName.length > maxLength) {
  //   truncatedFullName = fullName.substring(0, maxLength) + '...';
  // }  

  const onNavigateDetail = () => navigator(`/dashboard/pec/${item.id}`);

  const myJson: any = { ...Jsons }
  const status = myJson.pec.pec_status[item.status]
  const patient_status = myJson.pec.patient_status[item.patient_status]

  let t = Number(item?.total) - Number(item?.ameli_part) - Number(item?.insurance_part);
// let t = 0;
  return (
    <Stack width={"20rem"} boxShadow={5} borderRadius={2}
      justifyContent={"space-between"} my={2} bgcolor={"secondary.200"} sx={{
        cursor: "pointer", '&:hover': {
          // background: "#ecf0f1",
          opacity:.6
        }
      }} onClick={onNavigateDetail}>

      <Stack p={1} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
        <TextWithIcon
          bgColor={colorPicker.bgColor}
          title={`${title} (${replacer(formattedDuration)})`}
          fontColor={colorPicker.fntColor}
          icon={colorJson[status]?.icon}
        />

        <Stack direction={"row"} spacing={1}>
          <Typography color={"text.primary"} alignSelf={"center"}>
            {item?.info_quote_id}
          </Typography>
          <Tooltip title={TooltipfullName}>
            <Avatar sx={{ fontSize: 14 }}>
              {item.user.lastname.substring(0, 3)}
            </Avatar>
          </Tooltip>
        </Stack>
      </Stack>

      <Stack p={1} direction="row" alignItems="center" spacing={1}>

        <TextWithIcon
          bgColor={colorJson[patient_status]?.color}
          title={replacer(formattedDuration)}
          fontColor={colorPicker.fntColor}
          icon={colorJson[patient_status]?.icon}
        />
        <Typography color={"text.primary"} variant='body2'>{truncatedFullName}</Typography>
      </Stack>

      <Stack direction="row" justifyContent="space-between" alignItems="center" >
        <Typography color={"text.primary"} m={1} align='left'>
          {t.toFixed(2) + " €"}
        </Typography>
        {item?.appointment_date &&
          <Stack direction="row" alignItems="center">
            <CalendarMonthIcon sx={{ fontSize: 15 }} />
            <Typography color={"text.primary"} align='right' m={1}>
              le {moment(item.appointment_date).format("DD/MM/YYYY")}
            </Typography>
          </Stack>
        }
      </Stack>
    </Stack>
  );
}

export default Card;


const TextWithIcon = ({
  bgColor,
  title,
  fontColor,
  icon
}: {
  bgColor: string;
  title: string;
  fontColor: string
  icon: React.ReactNode
}) => (
  <Stack direction="row" sx={{ background: bgColor }}
    py={.4} px={.6} borderRadius={2} alignItems={"center"}>
    <Stack color={"black"} mr={1}>
      {icon}
    </Stack>
    <Typography fontSize={13} color={fontColor}>
      {title}
    </Typography>
  </Stack>
)