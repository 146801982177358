import {
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  TableSortLabel,
  Box,
} from "@mui/material";
import { Data, EnhancedTableProps, HeadCell } from "../types";
import { visuallyHidden } from "@mui/utils";
import styled from "styled-components";

export default function EnhancedTableHead({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  onRequestSort,
}: EnhancedTableProps) {
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead sx={{ background: "#aab4c7", height: 50 }}>
      <TableRow sx={{}}>
        <TableCell padding="checkbox" align="left">
          <Checkbox
            sx={{ color: "text.primary" }}
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell, index) => (
          <TableCell
            key={index}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              sx={{ color: "black", fontWeight: "bold" }}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={[visuallyHidden]}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Prénom",
  },
  {
    id: "calories",
    numeric: true,
    disablePadding: false,
    label: "Nom",
  },
  {
    id: "fat",
    numeric: true,
    disablePadding: false,
    label: "Praticien",
  },
  {
    id: "carbs",
    numeric: true,
    disablePadding: false,
    label: "Mode",
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "Mutuelle",
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "Statut patient",
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "Statut PEC",
  },
];

export const CustomInput = styled.input`
  display: "flex";
  flex: 1;
  // width: 99%;
  height: 48px;
  border: 0;
  padding-left: 40px;

  &:focus {
    outline: none; /* Remove the default focus outline */
    background-color: #f5f5f5;
  }
`;
