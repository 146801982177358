import { ButtonBase, ButtonBaseProps, CircularProgress } from "@mui/material";

interface type extends ButtonBaseProps {
  title: string;
  boxShadow?: string;
  loading?: boolean;
}

const PrimaryButton = ({
  title,
  boxShadow = "5px 10px 60px #62367d",
  loading,
  style = {},
  ...props
}: type) => {
  return (
    <ButtonBase
      {...props}
      style={{
        paddingBlock: 20,
        color: "white",
        fontSize: 12,
        width: 350,
        borderRadius: 10,
        boxShadow: boxShadow,
        ...style,
      }}
      sx={{ bgcolor: "primary.main" }}
    >
      {loading ? (
        <CircularProgress size={15} style={{ color: "white" }} />
      ) : (
        title
      )}
    </ButtonBase>
  );
};

export default PrimaryButton;
