import { CircularProgress, Stack, Typography } from "@mui/material";
import { getUser } from "features/auth/slice";
import { Formik, FormikProps } from "formik";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "tools/store";
import { ToastView } from "utils";
import {
  PrimaryButton,
  SecondaryButton,
  SwitchWithText,
} from "../../../components";
import { HistoryComp, Patient, Supported } from "../components";
import {
  useGetCentersQuery,
  useGetInsurancesQuery,
  useGetUsersQuery,
  useLazyGetPaymentsQuery,
  useLazyGetSingleRequestsQuery,
  useLazyGetSyncQuery,
  useUpdateRequestsMutation,
} from "../service";

const PatientDetail = () => {
  const User = useAppSelector(getUser);
  const { data: id } = useParams();

  const [getData, { data, error, isFetching }] =
    useLazyGetSingleRequestsQuery();
  const [getSynce, { isFetching: loadingSync }] = useLazyGetSyncQuery();
  const { data: usersList = [], isLoading: loadingUsers } =
    useGetUsersQuery(undefined);
  const [update, { isLoading }] = useUpdateRequestsMutation();
  const { data: centers = [], isLoading: loadingCenters } =
    useGetCentersQuery(undefined);
  const { data: insurances = [], isLoading: loadingInsurance } =
    useGetInsurancesQuery(undefined);

  const [getPayments, { isFetching: paymentLoading }] =
    useLazyGetPaymentsQuery();

  const navigate = useNavigate();

  useEffect(() => {
    if (id) getData(id?.toString());
  }, []);

  if (
    isFetching ||
    isLoading ||
    loadingUsers ||
    loadingCenters ||
    loadingCenters ||
    loadingInsurance
  ) {
    // temp
    // if (isFetching || isLoading) { // org
    return (
      <Stack
        display={"flex"}
        flex={1}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <CircularProgress size={60} />
      </Stack>
    );
  }

  const getSynchronize = ({ setFieldValue }: FormikProps<any>) => {
    getSynce(data?.quote_id)
      .then((response) => {
        if (response.data && !response.error) {
          const res = response.data;
          setFieldValue("patient_name", res.patient_name);
          setFieldValue("patient_lastname", res.patient_lastname);
          setFieldValue("phone_number", res.phone_number);
          setFieldValue("total", res.total);
          setFieldValue("insurance_part", res.insurance_part);
          setFieldValue("ameli_part", res.ameli_part);
          setFieldValue("quote_id", res.proposition_id.toString());
          setFieldValue("info_quote_id", res.estimate_number.toString());
          setFieldValue("patient_id", res.patient_id);
          setFieldValue("social_security_number", res.social_security_number);
          setFieldValue("patient_birthdate", res.patient_birthdate);

          const newUser = usersList.find(
            (item: any) => item.external_id === res.user_id
          );
          setFieldValue("user_id", newUser.id);

          const newCenter = centers.find(
            (item: any) => item.external_id === res.center_id
          );
          setFieldValue("center_id", newCenter.id);

          const newInsurance = insurances.find(
            (item: any) => item.external_id === res.AMC2_id
          );
          setFieldValue("insurance_id", newInsurance.id);

          ToastView("Synchronisation réussie !", "success");
        } else {
          ToastView("Synchronisation échoué", "error");
        }
      })
      .catch((e) => {
        ToastView(e, "error");
        // console.log("=========== error:", e)
      });
  };

  return (
    <Stack width={"100%"} justifyContent={"center"} overflow={"hidden"}>
      <Stack
        // bgcolor={"secondary.600"}
        borderRadius={3}
        style={{ overflowY: "scroll" }}
      >
        <Formik
          initialValues={{
            is_archived: data?.is_archived,

            /*Support*/
            info_quote_id: data?.info_quote_id,
            quote_id: data?.quote_id,
            status: data?.status,
            user: data?.user,
            mode: data?.mode,
            total: data?.total,
            insurance_part: data?.insurance_part,
            ameli_part: data?.ameli_part,
            updated_at: data?.updated_at,
            insurance: data?.insurance,
            payment: data?.payment,
            expiration_date: data?.expiration_date,
            center: data?.center,

            /*Patient*/
            patient_lastname: data?.patient_lastname,
            patient_name: data?.patient_name,
            patient_birthdate: data?.patient_birthdate,
            social_security_number: data?.social_security_number,
            phone_number: data?.phone_number,
            comments: data?.comments,
            patient_status: data?.patient_status,
            appointment_date: data?.appointment_date,
            call_date: data?.call_date,
            estimate_id: data?.estimate_id,
            insurance_media_id: data?.insurance_media_id,
            response_id: data?.response_id,

            patient_billing_status: data?.patient_billing_status,
            insurance_status: data?.insurance_status,
          }}
          onSubmit={async (values) => {
            const newValue = {
              ...data,
              ...values,
              // payment_id: values?.payment?.id,
            };

            if (values?.payment?.id) {
              newValue["payment_id"] = values?.payment?.id;
            } else {
              getPayments().then((res) => {
                if (res?.data && res?.data?.length > 0) {
                  newValue["payment_id"] = res?.data[0]?.id;
                }
              });
            }

            if (newValue.insurance_media) delete newValue.insurance_media;
            if (newValue.response) delete newValue.response;
            if (newValue.estimate) delete newValue.estimate;
            if (newValue.center) delete newValue.center;
            if (newValue.insurance) delete newValue.insurance;
            if (newValue.payment) delete newValue.payment;
            if (newValue.user) delete newValue.user;

            await update(newValue)
              .then(() => {
                // getData(id?.toString())
                ToastView(`PEC ${id} modifiée avec succès`, "success");
                navigate("/dashboard");
              })
              .catch((e) => {
                ToastView(e, "error");
                // console.log("=========== error:", e)
              });
          }}
        >
          {(props) => (
            <Stack p={2}>
              <Stack>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  flex={1}
                >
                  <Stack direction={"row"} spacing={1}>
                    <SecondaryButton
                      title="Synchroniser"
                      disabled={loadingSync}
                      loading={loadingSync}
                      onClick={() => getSynchronize(props)}
                    />
                    <PrimaryButton
                      title="Valider"
                      boxShadow="5px 5px 15px #62367d"
                      style={{
                        width: 100,
                        paddingBlock: 10,
                        fontWeight: "bold",
                        fontSize: 15,
                      }}
                      onClick={() => props.handleSubmit()}
                    />
                  </Stack>

                  <Stack direction={"row"} spacing={3}>
                    <SwitchWithText
                      title="Patient à facturer"
                      checked={props.values.patient_billing_status === "billed"}
                      onChange={() =>
                        props.setFieldValue(
                          "patient_billing_status",
                          props.values.patient_billing_status === "billed"
                            ? "to_bill"
                            : "billed"
                        )
                      }
                    />

                    <SwitchWithText
                      title="Mutuelle à facturer"
                      checked={props.values.insurance_status === "billed"}
                      onChange={() =>
                        props.setFieldValue(
                          "insurance_status",
                          props.values.insurance_status === "billed"
                            ? "to_bill"
                            : "billed"
                        )
                      }
                    />

                    {User.role === "admin" && (
                      <SwitchWithText
                        title="À payer"
                        checked={props.values.is_archived}
                        onChange={() =>
                          props.setFieldValue(
                            "is_archived",
                            props.values.is_archived ? false : true
                          )
                        }
                      />
                    )}
                  </Stack>
                </Stack>
              </Stack>

              <Stack mx={10}>
                <Stack
                  flex={1}
                  direction={"row"}
                  justifyContent={"space-around"}
                  mt={8}
                  color={"text.primary"}
                >
                  <Typography variant="h5">Prise en charge</Typography>
                  <Typography variant="h5">Patient</Typography>
                </Stack>

                <Stack direction={"row"} display={"flex"} flex={1} spacing={2}>
                  <Supported {...props} />

                  <Patient {...props} />
                </Stack>
              </Stack>
            </Stack>
          )}
        </Formik>

        <HistoryComp id={id} />

        <Stack py={5} />
      </Stack>
    </Stack>
  );
};

export default PatientDetail;
