import { ButtonProps, Typography, Button, CircularProgress } from "@mui/material";
import React, { memo } from "react";

interface Type extends ButtonProps {
    title: string;
    loading?: boolean;
}

const SecondaryButton: React.FC<Type> = ({ title, style = {}, loading = false, ...props }) => {
    return <Button style={{ textTransform: 'none', ...style }} {...props}>
        {loading
            ? < CircularProgress size={14} />
            : <Typography fontSize={16} fontWeight={"bold"}>
                {title}
            </Typography>}
    </Button>
}


export default memo(SecondaryButton);