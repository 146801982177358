import { Card, Stack, Typography } from "@mui/material";
import moment from "moment";
import { useEffect } from "react";
import { Jsons, colorJson } from "utils";
import { useLazyGetHistoryQuery } from "../service";


const HistoryComp = ({ id }: any) => {
    // const { data = [], isLoading } = useGetHistoryQuery(id?.toString());
    const [getHistory, { data = [], isLoading }] = useLazyGetHistoryQuery();

    useEffect(() => {
        getHistory(id?.toString())
    }, [])

    if (isLoading) {
        return <></>
    }

    return (
        <Stack width={"95%"} alignSelf={"center"}>
            <Stack borderTop={1} borderColor={"#bdc3c7"} my={5} />

            {data.map((item: any, index: number) => {

                const myJson: any = { ...Jsons }

                const status = myJson.pec.pec_status[item.status] ?? myJson.pec.patient_status[item.patient_status]

                if (status === (null || undefined)) {
                    return <></>
                }

                const time = moment(item.updated_at).format('DD/MM/YYYY HH:mm:ss');

                const currentDate = moment();
                const inputDate = moment(item.updated_at);

                const monthsAgo = currentDate.diff(inputDate, 'months');
                const daysAgo = currentDate.diff(inputDate, 'days');

                let result;

                if (monthsAgo >= 1) {
                    result = `${monthsAgo} mois${monthsAgo > 1 ? '' : ''}`;
                } else {
                    result = `${daysAgo} jours${daysAgo > 1 ? '' : ''} `;
                }

                return <Card sx={{ marginBottom: 2, borderRadius: 3 }}>
                    <Stack
                        direction={'row'}
                        justifyContent={"space-between"}
                        p={2.5}
                        alignItems={"center"}
                        // borderLeft={10}
                        // borderRight={10}
                        // borderRadius={4}
                        // borderColor={colorJson[status]?.color}
                        bgcolor={colorJson[status]?.color}
                    >
                        {colorJson[status]?.icon}

                        <Stack direction={"row"} spacing={4} display={"flex"} flex={1} ml={10}>
                            <Typography>{time}</Typography>
                            <Typography>Statut de la prise en charge modifié à "{status}" il y a {result} par {item?.user?.firstname} {item?.user?.lastname}</Typography>
                        </Stack>
                        {/* <Stack bgcolor={colorJson[status]?.color} borderRadius={200} p={2} /> */}
                    </Stack>
                </Card>
            })}

        </Stack>
    )
}

export default HistoryComp;