import { ForgotPass, PrivacyPolicy, SignIn } from "features";
import AppStack from "features/AppStack";
import { getUser } from "features/auth/slice";
import React, { memo } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import "./App.css";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { persistor, store, useAppSelector } from "./tools/store";

// 🔥 Désactive le Dark Mode dès le lancement
localStorage.removeItem("theme"); // ❌ Supprime l'ancien mode enregistré
document.documentElement.setAttribute("data-theme", "light"); // ✅ Applique le mode clair sur tout le site

export const PrivateRoute = memo(() => {
  const User = useAppSelector(getUser);
  return User ? <Outlet /> : <Navigate to="/login" replace />;
});

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/forgotpass" element={<ForgotPass />} />
      <Route path="/login" element={<SignIn />} />

      <Route path="" element={<PrivateRoute />}>
        <Route path="/*" element={<AppStack />} />
      </Route>
    </Route>
  )
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
