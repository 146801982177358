import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import pecuplogo from "assets/images/Pec Up (1).png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { InputFeild, PrimaryButton } from "components";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "tools/store";
import { ToastView } from "utils";
import * as Yup from "yup";
import { useSignInMutation } from "../service";
import { getRemember, getUser, setRemember } from "../slice";
import Marquee from "react-fast-marquee";

const SignIn = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate: any = useNavigate();
  const dispatch = useAppDispatch();
  const remember = useAppSelector(getRemember);
  const [signIn, { isLoading }] = useSignInMutation();
  const User = useAppSelector(getUser);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (User) {
      navigate("/dashboard", true);
    }
  }, [User]);

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });

  const { handleBlur, handleChange, values, handleSubmit, errors, touched } =
    useFormik({
      initialValues: {
        username: remember?.check ? remember?.email : "",
        password: remember?.check ? remember?.password : "",
      },
      validationSchema,
onSubmit: async (values: any) => {
  const hour = new Date().getHours();
  
  if(hour >= 20 || hour < 7) {
    ToastView("Connexion impossible entre 20h et 7h.", "warning");
    return;  // bloque la connexion ici
  }

  // ta logique actuelle
  signIn(values)
    .then((data: any) => {
      if (!data.error) {
        ToastView("connexion réussie", "success");
        if (remember?.check) {
          dispatch(
            setRemember({
              ...remember,
              email: values.username,
              password: values.password,
            })
          );
        }
        navigate("/dashboard", { replace: true });
      } else ToastView("Utilisateur ou mot de passe incorrect", "error");
    })
    .catch((e) => {
      ToastView(e, "error");
    });

      },
    });

  return (
    <Stack
      width={"100%"}
      height={"100%"}
      bgcolor={"#1c2a4d"}
      justifyContent={"center"}
      padding={isMobile ? 2 : 0}
    >
      <Stack
        width={isMobile ? "90%" : "75%"}
        mx={"auto"}
        flex={1}
        bgcolor={"#ffffff"}
        borderRadius={8}
        color={"white"}
        my={2.5}
        pr={isMobile ? undefined : 7}
        boxShadow={"6px 6px 12px rgba(28, 42, 77, 0.2)"}
      >
        <Stack direction={isMobile ? "column" : "row"} flex={1} m={1}>
          <Stack
            flex={1}
            height={isMobile ? "auto" : "100%"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <img
              src={pecuplogo}
              style={{
                height: isMobile ? "10rem" : "17rem",
                width: isMobile ? "10rem" : "17rem",
                marginTop: isMobile ? 0 : -30,
              }}
              alt="PEC UP Logo"
            />
          </Stack>

      <Stack
        flex={1}
        justifyContent={"center"}
        alignItems={"center"}
        padding={isMobile ? 2 : 0}
      >
<Typography 
  variant="h5" 
  fontWeight="500" 
  color="#1c2a4d"
  textAlign="center"
  letterSpacing={1}
  mt={3}  // ✅ Descend un peu le titre
  mb={3}  // ✅ Augmente l'espace en dessous pour un bon équilibre
  sx={{ opacity: 0.9 }}
>
  Identifiez-vous
</Typography>

        {/* Ton texte défilant ici 👇 */}
<Marquee speed={50} gradient={false} style={{ maxWidth: "400px", marginBottom: "20px" }}>
  <Typography variant="body2" color="#d32f2f">
    ⚠️ La connexion n’est pas disponible entre 20h et 7h. Pendant cette plage horaire, vous serez automatiquement déconnecté.
  </Typography>
</Marquee>

        <Stack my={2}>
         <InputFeild
  type="text"
  name="username"
  placeholder="Identifiant"
  style={{
    width: "100%", // ✅ Largeur totale
    maxWidth: "400px", // ✅ Ajusté pour s'aligner avec le bouton
  }}
  onChange={handleChange} // ✅ Ajouté ici
  onBlur={handleBlur}
  value={values.username}
  errors={
    touched.username && errors.username
      ? errors.username.toString()
      : undefined
  }
/>


<InputFeild
  type={showPassword ? "text" : "password"}
  name="password"
  placeholder="Mot de passe"
  style={{
    width: "100%", // ✅ Largeur totale
    maxWidth: "400px", // ✅ Ajusté pour s'aligner avec le bouton
  }}
  onChange={handleChange} // ✅ Ajouté ici
  onBlur={handleBlur}
  value={values.password}
  errors={
    touched.password && errors.password
      ? errors.password.toString()
      : undefined
  }

            onKeyDown={(e: any) => {
              if (e.key === "Enter") {
                handleSubmit();
              }
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                  sx={{ color: "text.primary" }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />

          <Stack
            direction={isMobile ? "column" : "row"}
            mt={1}
            justifyContent={isMobile ? "center" : "space-between"}
            alignItems={isMobile ? "center" : "initial"}
            mx={2}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={remember?.check}
                  onChange={() =>
                    dispatch(setRemember({ ...remember, check: !remember?.check }))
                  }
                  sx={{
                    transform: "scale(1.1)",
                    color: "#1c2a4d",
                    "&.Mui-checked": { color: "#1c2a4d" },
                    "& .MuiSvgIcon-root": { fontSize: 22 },
                    "& .MuiCheckbox-root": { border: "1.5px solid #1c2a4d" },
                  }}
                />
              }
              label={
                <Typography fontSize={14} sx={{ color: "#1c2a4d", fontWeight: "normal" }}>
                  Se rappeler de moi
                </Typography>
              }
            />
            <Button
              sx={{
                color: "transparent",
                background: "transparent",
                textTransform: "none",
              }}
              onClick={() => navigate("/forgotpass")}
            >
              <Typography fontSize={12}>Mot de passe oublié</Typography>
            </Button>
          </Stack>
        </Stack>

        <PrimaryButton
          title="Se connecter"
          loading={isLoading}
          disabled={isLoading}
          onClick={() => { handleSubmit(); }}
          type="submit"
          style={{
            width: "100%",
            maxWidth: "350px",
            backgroundColor: "#1c2a4d",
            margin: "auto",
          }}
        />

        <Button
          sx={{
            marginTop: 10,
            alignSelf: "center",
            color: "transparent",
            background: "transparent",
            height: 5,
            textTransform: "none",
          }}
          onClick={() => navigate("/privacy-policy")}
        >
          <Typography fontSize={15}>Données privées</Typography>
        </Button>
      </Stack>
    </Stack>
  </Stack>
</Stack>
  );
};

export default SignIn;
