import { Button, Stack, Typography } from "@mui/material";
import { useFormik } from "formik";
import pecuplogo from 'assets/images/pecuplogo.png';
import { InputFeild, PrimaryButton } from "components";
import { useNavigate } from 'react-router-dom';


const ForgotPass = () => {

    const navigate = useNavigate();

    const { handleBlur, handleChange, values, handleSubmit } = useFormik({
        initialValues: {
            username: "",
        },
        onSubmit: async (values) => {

            try {
                const url = "https://api.pecup.fr/auth/reset/" + values?.username

                var requestOptions: RequestInit = {
                    method: 'GET',
                    redirect: 'follow',
                };


                const finalUrl = url

                await fetch(finalUrl, requestOptions)
                    .then(response => {
                        if (response.status == 200) {
                            navigate("/");
                        }
                        return response.json();
                    })
                    .then((res: any) => { })
                    .catch(error => console.log('========== error', error));
            } catch (e) {

            }

        },
    });


    return (
        <Stack width={"100%"} height={"100vh"} bgcolor={"primary.200"} justifyContent={"center"}>

            <Stack flex={1} bgcolor={"secondary.600"} border={3.5} borderRadius={8} color={"white"} my={2.5} mx={29} pr={7}>

                <Stack direction={"row"} flex={1} m={1}>
                    <Stack flex={1} height={"100%"} justifyContent={"center"} alignItems={"center"} >
                        <img src={pecuplogo} style={{ height: "25rem", width: "25rem", marginTop: 50 }} alt="PEC UP Logo" />
                    </Stack>
                    <Stack flex={1} height={"100%"} justifyContent={"center"} alignItems={"center"} >
                        <Typography color={"black"} variant="h4" fontWeight={"bold"}>
                            Hello!
                        </Typography>
                        <Typography color={"black"} variant="body1" mt={1} fontFamily={"cursive"}>
                            Innovation, performance et optimisation!
                        </Typography>
                        <Stack my={4}>

                            <InputFeild
                                type="text"
                                name="username"
                                placeholder="Identifiant"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.username}
                            />

                            <Button sx={{
                                marginTop: 2, marginRight: 2, alignSelf: "end",
                                color: "transparent",
                                background: "transparent",
                                height: 5
                            }} onClick={() => navigate("/")}>
                                <Typography fontSize={12} color={"black"}>
                                     Page de connexion
                                </Typography>
                            </Button>

                        </Stack>
                        <PrimaryButton title="Envoyer un mail" onClick={() => handleSubmit()} />

                    </Stack>

                </Stack>

            </Stack>

        </Stack>
    )

}

export default ForgotPass