import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Avatar, Stack, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Jsons, colorJson, replacer } from 'utils';
import TextWithIcon from './TextWithIcon'; // Assure-toi du bon chemin relatif

function Card({ title, item }: any) {
  console.log("Statut brut reçu dans Card:", item.status);

  const myJson: any = { ...Jsons };
  const status = myJson.pec.pec_status[item.status] ?? "Statut non défini";
  const patient_status = myJson.pec.patient_status[item.patient_status];

  console.log("Statut interprété:", status);

  // ✅ Vérification si la couleur existe, sinon mettre une couleur par défaut
  const colorPicker = colorJson[status]
    ? { bgColor: colorJson[status].color, fntColor: "black" }
    : { bgColor: "#cccccc", fntColor: "black" };

  console.log("Couleur trouvée pour status:", colorPicker);

  const now = moment(new Date());
  const duration = moment.duration(now.diff(moment(item?.status_update)));
  const formattedDuration = duration.humanize();
  const navigator = useNavigate();

  const TooltipfullName = `Dr ${item?.user?.firstname} ${item?.user?.lastname}`;
  const fullName = item?.patient_lastname + " " + item?.patient_name;

  const onNavigateDetail = () => navigator(`/dashboard/pec/${item.id}`);

  let t = Number(item?.total) - Number(item?.ameli_part) - Number(item?.insurance_part);

  // --- Logique pour la date d'expiration ---
  const [expirationStatus, setExpirationStatus] = useState<string | null>(null);
  const [daysRemaining, setDaysRemaining] = useState<number | null>(null);
  const [expirationDateText, setExpirationDateText] = useState<string>("");

  useEffect(() => {
    if (item?.expiration_date) {
      const now = moment();
      const expirationDate = moment(item?.expiration_date);
      const diffInDays = expirationDate.diff(now, 'days'); // Différence en jours

      if (diffInDays < 0) {
        // Expirée
        setExpirationStatus('expired');
        setDaysRemaining(Math.abs(diffInDays)); // Jours depuis expiration
      } else if (diffInDays <= 14) {
        // 2 semaines avant expiration
        setExpirationStatus('expireSoon');
        setDaysRemaining(diffInDays); // Jours restants avant expiration
      } else {
        setExpirationStatus(null); // Pas d'alerte
        setDaysRemaining(null);
      }
    } else {
      // Si la date d'expiration est vide
      setExpirationDateText(" "); // Affiche "X" si la date n'est pas renseignée
    }
  }, [item?.expiration_date]);

  // Choix de la couleur en fonction du statut d'expiration
  const cardStyle = expirationStatus === 'expired' 
  ? { 
      boxShadow: '0 4px 12px rgba(229, 57, 53, 1)', // Ombre rouge
      border: '3px dashed #e53935' // Bordure rouge pour expiration
    }
  : expirationStatus === 'expireSoon' 
  ? { 
      boxShadow: '0 4px 12px rgba(28, 42, 77, 1)', // Ombre bleue
      border: '3px dashed #1c2a4d' // Bordure bleue pour expiration proche
    } 
  : { 
    boxShadow: '0 4px 12px rgba(0, 30, 100, 0.4)'
    }; // Bordure normale

  return (
    <Stack 
      width={"20rem"} 
      boxShadow={5} 
      borderRadius={2}
      justifyContent={"space-between"} 
      my={2} 
      bgcolor={"secondary.200"} 
      sx={{
        cursor: "pointer",
        '&:hover': {
          opacity: .6
        },
        ...cardStyle, // Appliquer le shadow et la bordure dynamique
      }} 
      onClick={onNavigateDetail}
    >
      <Stack p={1} direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
        <TextWithIcon
          bgColor={colorPicker.bgColor}
          title={`${title} (${replacer(formattedDuration)})`}
          fontColor={colorPicker.fntColor}
          icon={colorJson[status]?.icon}
        />

        <Stack direction={"row"} spacing={1}>
          {/* Remplacement du numéro de devis par la date d'expiration */}
          <Typography color={"text.primary"} alignSelf={"center"} style={{ fontSize: '0.75rem' }}>
            {expirationDateText || moment(item?.expiration_date).format("DD/MM/YYYY")}
          </Typography>
<Tooltip title={TooltipfullName}>
  <Avatar 
    sx={{ 
      fontSize: 14, 
      backgroundColor: '#1c2a4d',  // Fond de l'avatar en bleu #1c2a4d
      color: 'white'  // Texte de l'avatar en blanc
    }}>
    {item.user.lastname.substring(0, 3)}  {/* Initiales du dentiste */}
  </Avatar>
</Tooltip>

        </Stack>
      </Stack>

      {/* Afficher le message en fonction du statut */}
      {expirationStatus === 'expireSoon' && daysRemaining !== null && (
        <Typography variant="body2" color="orange" textAlign="center">
          Expire dans {daysRemaining} {daysRemaining === 1 ? 'jour' : 'jours'}
        </Typography>
      )}
      {expirationStatus === 'expired' && daysRemaining !== null && (
        <Typography variant="body2" color="red" textAlign="center">
          Expirée depuis {daysRemaining} {daysRemaining === 1 ? 'jour' : 'jours'}
        </Typography>
      )}

      {/* Affichage du statut du patient */}
      <Stack p={1} direction="row" alignItems="center" spacing={1}>
        <TextWithIcon
          bgColor={colorJson[patient_status]?.color || "#cccccc"} // ✅ Sécurisation
          title={replacer(formattedDuration)}
          fontColor={colorPicker.fntColor}
          icon={colorJson[patient_status]?.icon || <></>}
        />
        <Typography color={"text.primary"} variant='body2'>{fullName}</Typography>
      </Stack>

      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography color={"text.primary"} m={1} align='left'>
          {t.toFixed(2) + " €"}
        </Typography>
        {item?.appointment_date &&
          <Stack direction="row" alignItems="center">
            <CalendarMonthIcon sx={{ fontSize: 15 }} />
            <Typography color={"text.primary"} align='right' m={1}>
              le {moment(item.appointment_date).format("DD/MM/YYYY")}
            </Typography>
          </Stack>
        }
      </Stack>
    </Stack>
  );
}

export default Card;
