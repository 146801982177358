import AddSharpIcon from "@mui/icons-material/AddSharp";
import { IconButton, Stack } from "@mui/material";
import { useState } from "react";
import { StatusBar, ToDo } from "../../../components";

const DashBoard = () => {

  const [name, setName] = useState("");
  const [sort, setSort] = useState("status_update");
  const [min, setMin] = useState(true);
  const [archived, setArchived] = useState(true);
  const token = localStorage.getItem("appToken");

  return (
    <Stack width={"100%"} height={"100%"} bgcolor={"secondary.200"}>
      <StatusBar
        name={name}
        onChange={(val: any) => setName(val.target.value)}
        onSort={setSort}
        onArchive={setArchived}
        onMin={setMin}
      />

      <Stack sx={{ overflowX: "auto" }} height={"calc(100% - 52px)"}>
        <Stack direction="row" spacing={1} my={1}>
          {/* &types=&patient_billing_statuses=&payment_ids=&patient_statuses=&user_ids=&insurance_ids=&center_ids= */}

          <ToDo
            token={token}
            title={"À faire"}
            name={name}
            sort={sort}
            archived={archived}
            min={min}
            url={`https://api.pecup.fr/requests?limit=10&is_archived=${!archived}&statuses=to_do&mine=${!min}&order=desc&sort=${sort}&any_names=${name}`}
          />

          <ToDo
            token={token}
            title={"En cours"}
            name={name}
            sort={sort}
            archived={archived}
            min={min}
            url={`https://api.pecup.fr/requests?limit=10&is_archived=${!archived}&statuses=in_progress&mine=${!min}&order=desc&sort=${sort}&any_names=${name}`}
          />

          <ToDo
            token={token}
            title={"Envoi patient"}
            name={name}
            sort={sort}
            archived={archived}
            min={min}
            url={`https://api.pecup.fr/requests?limit=10&is_archived=${!archived}&statuses=sent_patient&mine=${!min}&order=desc&sort=${sort}&any_names=${name}`}
          />

          <ToDo
            token={token}
            title={"Relance 1"}
            name={name}
            sort={sort}
            archived={archived}
            min={min}
            url={`https://api.pecup.fr/requests?limit=10&is_archived=${!archived}&statuses=retry_1&mine=${!min}&order=desc&sort=${sort}&any_names=${name}`}
          />

          <ToDo
            token={token}
            title={"Accordée"}
            name={name}
            sort={sort}
            archived={archived}
            min={min}
            url={`https://api.pecup.fr/requests?limit=10&is_archived=${!archived}&statuses=granted&mine=${!min}&order=desc&sort=${sort}&any_names=${name}`}
          />

          <ToDo
            token={token}
            title={"Refusée"}
            name={name}
            sort={sort}
            archived={archived}
            min={min}
            url={`https://api.pecup.fr/requests?limit=10&is_archived=${!archived}&statuses=refused&mine=${!min}&order=desc&sort=${sort}&any_names=${name}`}
          />

          <ToDo
            token={token}
            title={"Relance 2"}
            name={name}
            sort={sort}
            archived={archived}
            min={min}
            url={`https://api.pecup.fr/requests?limit=10&is_archived=${!archived}&statuses=retry_2&mine=${!min}&order=desc&sort=${sort}&any_names=${name}`}
          />

          <Stack p={0.5} />

          <IconButton
            sx={{
              position: "absolute",
              bottom: 50,
              right: 50,
              backgroundColor: "primary.main",
              "&:hover": {
                backgroundColor: "rgba(98, 54, 125, 0.35)",
              },
            }}
            onClick={() => {              
              // navigate(`/dashboard/pec`)
            }}
          >
            <AddSharpIcon htmlColor="white" />
          </IconButton>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default DashBoard;
