import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Collapse,
  Divider,
  List,
  Stack
} from "@mui/material";
import { PrimaryButton } from "components";
import { CustomInput } from "features/toutes/components/EnhancedTableHead";
import { useState } from "react";
import PaymentsForm from "../components/payments/PaymentsForm";
import PaymentsRenderItem from "../components/payments/PaymentsRenderItem";
import { useGetAllPaymentsQuery } from "../service";

const Payments = () => {
  const [openNew, setNew] = useState(false);

  const [filter, setFilter] = useState({
    names: "",
  });
  const { data } = useGetAllPaymentsQuery(filter);

  return (
    <Stack px={3} height={"100%"}>
      <Stack direction={"row"} justifyContent={"space-between"} my={2}>
        <Stack direction={"row"} width={"100%"}>          
          <Box
            sx={{
              alignItems: "center",
              height: 48,
              display: "flex",
              flexGrow: 1,
              mr: 5,
              position:"relative"
            }}
          >
            <Box sx={{ position: "absolute", left: 7, width: 40, top: 14 }}>
              <SearchIcon sx={{ width: 30, height: 20 }} />
            </Box>

            <CustomInput
              type="text"
              value={filter.names}
              onChange={(val) =>
                setFilter({ ...filter, names: val.target.value })
              }
              placeholder="Rechercher.."
            />
          </Box>
        </Stack>

        <Stack direction={"row"} spacing={5}>          
          <PrimaryButton
            title={openNew ? "Annuler" : "Ajouter"}
            style={{ width: 80, paddingBlock: 15, borderRadius: 6 }}
            boxShadow=""
            onClick={() => setNew(!openNew)}
          />
        </Stack>
      </Stack>

      <Stack
        width={"100%"}
        sx={{
          borderRadius: 2,
          overflow: "hidden",
          boxShadow: "5px 3px 12px #cfbfd9",
          maxHeight: "calc(100% - 140px)",
        }}
      >
        <List
          sx={{ width: "100%", overflowY: "auto", overflowX: "none" }}
          aria-labelledby="nested-list-subheader"
        >
          {openNew && (
            <>
              <Collapse in={openNew} timeout="auto" unmountOnExit>
                <PaymentsForm onClosed={()=> setNew(false)} />
              </Collapse>
              <Divider />
            </>
          )}
          {data?.map((item: any, index: number) => (
            <PaymentsRenderItem item={item} index={index} />
          ))}
        </List>
      </Stack>
    </Stack>
  );
};

export default Payments;
