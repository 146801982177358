import { Dialog, DialogTitle, DialogContent, Typography, Box, IconButton } from "@mui/material";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import { useState } from "react";
import PieChartComponent from "../../stats/components/PieChartComponent";

const DashboardStatsModal = () => {
  const [open, setOpen] = useState(false);
  const [stats, setStats] = useState<{ [key: string]: number }>({});
  const [loading, setLoading] = useState(true);

  // 🔄 Labels en français
  const labelsFr = {
    toDo: "À faire",
    inProgress: "En cours",
    sentPatient: "Envoi patient",
    retry1: "Relance Mutuelle",
    granted: "Accordée",
    refused: "Refusée",
    retry2: "Devis erroné ou Désistement Patient",
    
  };

  // 🎨 Couleurs des statuts
  const COLORS: Record<string, string> = {
    granted: "#4CAF50",
    refused: "#E91E63",
    inProgress: "#36A2EB",
    sentPatient: "#FFCE56",
    toDo: "#1c2a4d",
    retry1: "#FF9800",
    retry2: "#FF6384"
    //error: "#ff8000"//
  };

  // 📌 **Récupération des valeurs AVANT ouverture de la modale**
  const fetchStats = () => {
    setLoading(true);

    setTimeout(() => {
      const extractNumber = (selector: string) => {
        const element = document.querySelector(selector) as HTMLElement | null;
        if (!element) return 0;

        const text = element.innerText.trim();
        const numbers = text.match(/\d+/g);
        return numbers ? parseInt(numbers[numbers.length - 1]) : 0;
      };

      const newStats = {
        toDo: extractNumber("[data-title='À faire']"),
        inProgress: extractNumber("[data-title='En cours']"),
        sentPatient: extractNumber("[data-title='Envoi patient']"),
        retry1: extractNumber("[data-title='Relance Mutuelle']"),
        granted: extractNumber("[data-title='Accordée']"),
        refused: extractNumber("[data-title='Refusée']"),
        retry2: extractNumber("[data-title='Devis erroné ou Désistement Patient']"),
      };


      setStats(newStats);
      setLoading(false);
    }, 500); // ✅ Simulation d’un chargement ultra rapide
  };

  // 🧮 **Total des DPECS**
  const totalDPECS = Object.values(stats).reduce((acc, val) => acc + val, 0);

  // 📊 **Préparation des données pour `PieChartComponent`**
  const pieData = {
    title: " ",
    data: Object.entries(stats)
      .filter(([key, value]) =>
        ["granted", "refused", "inProgress", "toDo", "sentPatient", "retry1", "retry2"].includes(key) && value > 0
      )
      .map(([key, value]) => ({
        name: labelsFr[key as keyof typeof labelsFr],
        y: value,
        color: COLORS[key],
      })),
    chart: {
      type: "pie",
      options3d: { enabled: true, alpha: 45, beta: 0 },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        depth: 35,
        animation: { duration: 500 },
        dataLabels: { enabled: true, format: "<b>{point.name}</b>: {point.percentage:.1f}%" },
      },
    },
    tooltip: { pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>" },
    credits: {
      enabled: false, // ✅ Supprime complètement "Highcharts.com"
    },
    exporting: {
      enabled: false, // ✅ Désactive le menu de la démo
    },
  };

  return (
    <>
      {/* Bouton flottant */}
      <IconButton
        sx={{
          position: "fixed",
          bottom: 50,
          right: 50,
          backgroundColor: "#1c2a4d",
          width: 65,
          height: 65,
          borderRadius: "50%",
          boxShadow: 3,
          "&:hover": { backgroundColor: "rgba(28, 42, 77, 0.8)" },
        }}
        onClick={() => {
          fetchStats(); // ✅ Charge les stats juste avant d’ouvrir
          setOpen(true);
        }}
      >
        <InsertChartIcon htmlColor="white" sx={{ fontSize: 40 }} />
      </IconButton>

      {/* Modale des statistiques */}
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="md" fullWidth>

        <DialogTitle sx={{ textAlign: "center", fontWeight: "bold" }}>
  📊 Mes Statistiques
</DialogTitle>

<DialogContent>
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    gap={2}
    width="100%"
  >
    {/* 🟩 Carte "Total DPECS" seule en haut */}
    <Box 
      p={2} 
      bgcolor="white" 
      borderRadius={2} 
      textAlign="center" 
      boxShadow={3} 
      width="30%" 
      minWidth="200px"
    >
      <Typography variant="subtitle1" fontWeight="600" color="#1c2a4d">
        Total DPECS
      </Typography>
      <Typography variant="h5" fontWeight="bold" color="black">
        {loading ? "..." : totalDPECS}
      </Typography>
    </Box>

    {/* 📊 Stats et camembert en disposition flex */}
    <Box
      display="flex"
      flexDirection="row"
      width="100%"
      justifyContent="space-between"
      gap={3}
    >
      {/* 🟩 Cartes stats à gauche (40%) */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)" // ✅ 2 colonnes pour équilibrer
        gap={2}
        p={2}
        flex={1}
        maxWidth="40%"
        textAlign="center"
      >
        {Object.entries(stats).map(([key, value]) => (
          <Box
            key={key}
            p={2}
            bgcolor="white"
            borderRadius={2}
            textAlign="center"
            boxShadow={3}
          >
            <Typography variant="subtitle2" fontWeight="600" color={COLORS[key]}>
              {labelsFr[key as keyof typeof labelsFr]}
            </Typography>
            <Typography variant="h6" fontWeight="bold" color="black">
              {loading ? "..." : value}
            </Typography>
          </Box>
        ))}
      </Box>

      {/* 🟦 Camembert à droite (60%) */}
      <Box flex={1} display="flex" justifyContent="center" alignItems="center" maxWidth="60%">
        {loading ? (
          <Typography>Chargement des données...</Typography>
        ) : pieData.data.length > 0 ? (
          <PieChartComponent title={pieData.title} data={pieData.data} />
        ) : (
          <Typography>Aucune donnée disponible</Typography>
        )}
      </Box>
    </Box>
  </Box>
</DialogContent>

      </Dialog>
    </>
  );
};

export default DashboardStatsModal;
