import {
  FormControlLabel,
  Stack,
  Switch,
  SwitchProps,
  Typography,
} from "@mui/material";
import { memo } from "react";

interface type extends SwitchProps {
  title: string;
}

const SwitchWithText = ({ title, ...props }: type) => {
  return (
    <FormControlLabel
      control={<Switch {...props} color="primary" />}
      label={
        <Typography fontSize={13} color={"text.primary"}>
          {title}
        </Typography>
      }
    />
  );
};

export default memo(SwitchWithText);
