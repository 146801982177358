import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Collapse,
  Divider,
  List,
  Stack
} from "@mui/material";
import { PrimaryButton } from "components";
import { CustomInput } from "features/toutes/components/EnhancedTableHead";
import { useState } from "react";
import CenterForm from "../components/centers/CenterForm";
import CenterRenderItem from "../components/centers/CenterRenderItem";
import { useGetAllCentersQuery, useSyncCentersMutation } from "../service";
import { ToastView } from "utils";

const Centers = () => {
  const [openNew, setNew] = useState(false);

  const [filter, setFilter] = useState({
    name: "",
  });

  const [onSync, { isLoading: isSyncing }] = useSyncCentersMutation();

  const { data: centers = [], isLoading: loadingCenters } =
    useGetAllCentersQuery({});

  return (
    <Stack px={3} height={"100%"}>
      <Stack direction={"row"} justifyContent={"space-between"} my={2}>
        <Stack direction={"row"} width={"100%"}>
          <Box
            sx={{
              alignItems: "center",
              height: 48,
              display: "flex",
              flexGrow: 1,
              mr: 5,
              position: "relative"
            }}
          >
            <Box sx={{ position: "absolute", left: 7, width: 40, top: 14 }}>
              <SearchIcon sx={{ width: 30, height: 20 }} />
            </Box>

            <CustomInput
              type="text"
              value={filter.name}
              onChange={(val) =>
                setFilter({ ...filter, name: val.target.value })
              }
              placeholder="Rechercher.."
            />
          </Box>
        </Stack>

        <Stack direction={"row"} spacing={5}>
          <PrimaryButton
            title={"Synchroniser"}
            style={{ width: 150, paddingBlock: 15, borderRadius: 6 }}
            boxShadow=""
            onClick={() => onSync().then((res: any) => {
              if (!res?.error) {
                ToastView("Synchronisation réussie", "success");
              }
            })}
            loading={isSyncing}
          />
          <PrimaryButton
            title={openNew ? "Annuler" : "Ajouter"}
            style={{ width: 80, paddingBlock: 15, borderRadius: 6 }}
            boxShadow=""
            onClick={() => setNew(!openNew)}
          />
        </Stack>
      </Stack>

      <Stack
        width={"100%"}
        sx={{
          borderRadius: 2,
          overflow: "hidden",
          boxShadow: "5px 3px 12px #cfbfd9",
          maxHeight: "calc(100% - 140px)",
        }}
      >
        <List
          sx={{ width: "100%", overflowY: "auto", overflowX: "none" }}
          aria-labelledby="nested-list-subheader"
        >
          {openNew && (
            <>
              <Collapse in={openNew} timeout="auto" unmountOnExit>
                <CenterForm onClosed={() => setNew(false)} />
              </Collapse>
              <Divider />
            </>
          )}
          {centers.filter((it:any)=>it?.name?.toLocaleLowerCase()?.includes(filter?.name?.toLocaleLowerCase()))?.map((item: any, index: number) => (
            <CenterRenderItem item={item} index={index} key={index}/>
          ))}
        </List>
      </Stack>
    </Stack>
  );
};

export default Centers;
