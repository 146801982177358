import { Stack, Typography } from "@mui/material";
import React from "react";

const TextWithIcon = ({
  bgColor,
  title,
  fontColor,
  icon
}: {
  bgColor: string;
  title: string;
  fontColor: string;
  icon: React.ReactNode;
}) => (
  <Stack direction="row" sx={{ background: bgColor }}
    py={.4} px={.6} borderRadius={2} alignItems={"center"}>
    <Stack color={"black"} mr={1}>
      {icon}
    </Stack>
    <Typography fontSize={13} color={fontColor}>
      {title}
    </Typography>
  </Stack>
);

export default TextWithIcon;
