import { ButtonBase, CircularProgress, Dialog, DialogContent, DialogProps, DialogTitle, IconButton, Stack, TextField, Typography } from "@mui/material";
import { memo, useState } from "react";
import CancelIcon from '@mui/icons-material/Cancel';

interface ModalPickerTypes extends DialogProps {
    list: any;
    keyName: string;
    subKeyName?: string;
    heading?: string;
    isLoading?: boolean;
    onSearch: (res?: any) => void;
    onCallBack: (res?: any) => void;
}

const ModalPicker: React.FC<Omit<ModalPickerTypes, "children">> = ({
    list = [],
    keyName,
    subKeyName,
    isLoading = false,
    heading,
    onSearch,
    onCallBack,
    ...props
}) => {

    const handleClosed = (res?: any) => {
        onCallBack()
    }
    const [search, setSearch] = useState<any>("");
    // const [_list, setList] = useState<any>(list);

    // useEffect(() => {
    //     const filtered = list.filter((item: any) => item[keyName].toLowerCase().includes(search.toLowerCase()));
    //     setList(filtered);
    // }, [search, list]);

    // useEffect(() => {
    //     onSearch(search)
    // }, [search])



    return <Dialog
        {...props}
        fullWidth
        scroll={"paper"}
        onClose={handleClosed}>

        <DialogTitle id="scroll-dialog-title" height={10}>
            <Stack sx={{ position: "absolute", top: 0, width: "95%", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                <Typography>{heading || "Sélectionner"}</Typography>
                <IconButton
                    onClick={handleClosed}>
                    <CancelIcon />
                </IconButton>
            </Stack>
        </DialogTitle>
        <DialogContent dividers={true}>
            <Stack>
                <TextField
                    id="outlined-read-only-input"
                    placeholder="Recherche..."
                    fullWidth
                    value={search}
                    onChange={(res) => {
                        setSearch(res.target.value)
                        onSearch(res.target.value)
                    }}
                />
                <Stack py={1} />
                <Stack width={"100%"}>
                    {isLoading
                        ? <Stack alignItems={"center"} py={20}>
                            <CircularProgress />
                        </Stack>
                        : list.map((it: any, index: number) => (
                            <ButtonBase
                                key={index}
                                sx={{ width: "100%", textAlign: "start" }}
                                onClick={() => onCallBack(it)}>
                                <Stack borderBottom={1} py={2} display={"flex"} flex={1}>
                                    {it[keyName].toString()}{subKeyName && " - " + it[subKeyName].toString()}
                                </Stack>
                            </ButtonBase>
                        ))
                    }
                </Stack>
            </Stack>
        </DialogContent>
    </Dialog>
}

export default memo(ModalPicker);