import AccessAlarmsOutlinedIcon from '@mui/icons-material/AccessAlarmsOutlined';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DirectionsBikeOutlinedIcon from '@mui/icons-material/DirectionsBikeOutlined';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import HourglassTopOutlinedIcon from '@mui/icons-material/HourglassTopOutlined';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import PendingIcon from '@mui/icons-material/Pending';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import PhoneForwardedOutlinedIcon from '@mui/icons-material/PhoneForwardedOutlined';
import RunningWithErrorsOutlinedIcon from '@mui/icons-material/RunningWithErrorsOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import UpdateOutlinedIcon from '@mui/icons-material/UpdateOutlined';
import VoicemailOutlinedIcon from '@mui/icons-material/VoicemailOutlined';
import moment from "moment";
import { toast } from 'react-toastify';
import WifiIcon from '@mui/icons-material/Wifi';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import MailIcon from '@mui/icons-material/Mail';
import SentimentVerySatisfiedOutlinedIcon from '@mui/icons-material/SentimentVerySatisfiedOutlined';

export const formatDate = (rawDate: Date | string | null) => {
    if (rawDate?.toString().includes(":")) {
        const date = moment(rawDate);
        if (date.isValid()) {
            return date.format('YYYY-MM-DD');
        }
    }
    return rawDate;
};


export const Jsons = {
    "pec": {
        "pec_status": {
            "granted": "accordée",
            "in_progress": "en cours",
            "refused": "refusée",
            "to_do": "à faire",
            "sent_patient": "envoi patient",
            "retry_1": "relance 1",
            "retry_2": "relance 2"
        },
        "patient_status": {
            "granted": "accord",
            "voicemail": "messagerie",
            "to_call": "à appeler",
            "pending_insurance_approval": "attente mutuelle",
            "cancelled": "annulation",
            "refused": "refus",
            "pending_patient": "en attente du patient"
        },
        "mode": {
            "online": "en ligne",
            "mail": "courrier",
            "email": "email"
        }
    }
}


export const colorJson: any = {
    "pending": { color: '#e0e0e0', icon: <PendingIcon />, iconColor: 'darkgray' },

    // Statut de la prise en charge
    "à faire": { color: '#dda0dd', icon: <CreateOutlinedIcon />, iconColor: 'darkgray' },
    "en cours": { color: '#ffcccb', icon: <HourglassTopOutlinedIcon />, iconColor: 'blue' },
    // "accordée": { color: '#98fb98', icon: <CheckCircleRoundedIcon />, iconColor: 'green' },
    "accordée": { color: '#98fb98', icon: <SentimentVerySatisfiedOutlinedIcon />, iconColor: 'green' },
    "refusée": { color: '#ff6961', icon: <HighlightOffRoundedIcon />, iconColor: 'red' },
    "envoi patient": { color: '#fffacd', icon: <DirectionsBikeOutlinedIcon />, iconColor: 'darkgray' },
    "relance 1": { color: '#add8e6', icon: <UpdateOutlinedIcon />, iconColor: 'darkgray' },
    "relance 2": { color: '#bdd0d6', icon: <RunningWithErrorsOutlinedIcon />, iconColor: 'darkgray' },

    //Statut pateint
    "accord": { color: '#98fb98', icon: <HowToRegRoundedIcon />, iconColor: 'green' },
    "refus": { color: '#ff6961', icon: <PersonOffOutlinedIcon />, iconColor: 'red' },
    "messagerie": { color: '#dda0dd', icon: <VoicemailOutlinedIcon />, iconColor: 'purple' },
    "attente mutuelle": { color: '#ffcccb', icon: <TimerOutlinedIcon />, iconColor: 'darkgray' },
    "à appeler": { color: '#ffb6c1', icon: <PhoneForwardedOutlinedIcon />, iconColor: 'cyan' },
    "en attente du patient": { color: '#add8e6', icon: <AccessAlarmsOutlinedIcon />, iconColor: 'darkgray' },
    "annulation": { color: '#bdd0d6', icon: <CloseOutlinedIcon />, iconColor: 'cyan' },
}


export const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
    } else {
        return document.execCommand('copy', true, text);
    }
}


export const ToastView = (msg: string, type: "success" | "info" | "warning" | "error") => {
    switch (type) {
        case "error": {
            toast.error(msg);
        } break;
        case "success": {
            toast.success(msg);
        } break;
        case "info": {
            toast.info(msg);
        } break;
        case "warning": {
            toast.warn(msg);
        } break;
        default: toast(msg)
    }
}

export const modeIconJson: { [prop: string]: any } = {
    online: { icon: <WifiIcon htmlColor='green' />, color: 'green' },
    email: { icon: <AlternateEmailIcon htmlColor='blue' />, color: 'blue' },
    mail: { icon: <MailIcon htmlColor='purple' />, color: 'purple' },
}

export const capitalization = (text: string) => {
    const newText = text.charAt(0).toUpperCase() + text.slice(1);
    return newText
}

export const capitalizeCamelCase = (str: string) => {
    return str.replace(/\b\w/g, function (char) {
        return char.toUpperCase();
    });
}

export const replacer = (value: string) => {
    const newValue = value.replace("days", "j")
        .replace("a day", "1j")
        .replace("days", "j")
        .replace("a month", "1mois")
        .replace("months", "mois")
        .replace("hours", "h")
        .replace("an hour", "1h")
        .replace("a year", "1an")
        .replace("years", "an")
        .replace("a minute", "1min")
        .replace("minutes", "min")
        .replace("a few seconds", "sec")
        .replace(" ", "")
    // .toLocaleUpperCase()

    return newValue
}

export const diff = (text: string) => {
    const now = moment(new Date());
    const duration = moment.duration(now.diff(moment(text)));

    // Format the duration in the desired way (e.g., "10m" for 10 minutes)
    const formattedDuration = duration.humanize();
    return formattedDuration
}

export const FormToUrl = (obj: Record<any, any>): string => {
    if (obj) return Object.entries(obj)
        .map(([key, value]) => {
            let keys = encodeURIComponent(key)
            if (key === "patient_billing_statuses") {
                const patient: { id: string }[] = []
                const insurance: { id: string }[] = []
                value.forEach((it: any) => {
                    if (it.name === "Patient à facturer" || it.name === "Patient facturé") {
                        patient.push({ id: it.id })
                    } else {
                        insurance.push({ id: it.id })
                    }
                });
                return `patient_billing_statuses=${ListSeperated(patient)}&insurance_statuses=${ListSeperated(insurance)}`
            }
            if (Array.isArray(value)) {
                return `${keys}=${ListSeperated(value)}`;
            } else {
                return `${keys}=${encodeURIComponent(value)}`;
            }
        })
        .join('&');
    else return ""
};

export const ListSeperated = (arr: any) => {
    const translatedArray = arr.map((item: any) => item.id);
    return translatedArray.join(',');
}



export const RolesJson: { [key: string]: string } = {
    'admin': 'Administrateur',
    'manager': 'Directeur',
    'secretary': 'Secrétaire',
    'doctor': 'Praticien',
    'insurance_secretary'
        : 'Tiers Payant'

}