import { Dialog, DialogTitle, DialogContent, Typography, Button } from "@mui/material";
import { useEffect, useState } from "react";

const ReminderPopup = () => {
  const [open, setOpen] = useState(false);
  const [todoCount, setTodoCount] = useState(0);

  // ✅ Fonction pour extraire le nombre de PEC "À faire" depuis le DOM
  const extractNumber = (selector: string) => {
    const element = document.querySelector(selector) as HTMLElement | null;
    if (!element) return todoCount; // 🔄 Garde l'ancienne valeur si le DOM n'est pas prêt

    const text = element.innerText.trim();
    const numbers = text.match(/\d+/g);
    return numbers ? parseInt(numbers[numbers.length - 1]) : 0;
  };

  // ✅ Fonction qui met à jour le nombre de PEC et affiche la pop-up après le délai
  const updatePECCount = () => {
    const pecAFaire = extractNumber("[data-title='À faire']");
    setTodoCount(pecAFaire);

    setTimeout(() => {
      setOpen(true); // ✅ Déclenche la pop-up avec un léger retard après la mise à jour des PEC
    }, 500); // ⏳ Petit délai pour éviter les bugs de transition
  };

  useEffect(() => {
    // ✅ Charge la valeur des PEC à faire mais n'affiche pas la pop-up immédiatement
    setTimeout(() => {
      updatePECCount();
    }, 30000); // ⏳ Attente de 30s avant le premier affichage (remettre 1h après)

    // ✅ Déclenche la pop-up toutes les 30 secondes après le premier délai
    const interval = setInterval(() => {
      updatePECCount();
    }, 3600000); // ⏳ 30 secondes pour tester

    return () => clearInterval(interval);
  }, []);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs" fullWidth>
      <DialogTitle sx={{ fontSize: 22, fontWeight: "bold", textAlign: "center" }}>
        📢 Rappel
      </DialogTitle>
      <DialogContent sx={{ minWidth: 350, textAlign: "center", p: 3 }}>
<Typography variant="h6" sx={{ mb: 2, textAlign: "center" }}>
  Il vous reste{" "}
  <span style={{ color: "red", fontSize: "24px", fontWeight: "bold" }}>
    {todoCount}
  </span>{" "}
  <span style={{ fontSize: "18px" }}>PEC à faire.</span>
  <br /> {/* ✅ Retour à la ligne */}
  <span style={{ fontSize: "16px", fontWeight: "bold", color: "#1c2a4d" }}>
    Pensez à mettre à jour le statut des PEC.
  </span>
</Typography>

        <Button
          onClick={() => setOpen(false)}
          variant="contained"
          sx={{
            bgcolor: "red",
            color: "white",
            fontSize: 18,
            px: 4,
            py: 1.5,
            "&:hover": { bgcolor: "darkred" },
          }}
        >
          OK
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default ReminderPopup;
