import {
    createApi,
    fetchBaseQuery,
    BaseQueryFn,
    FetchArgs,
    FetchBaseQueryError,
  } from "@reduxjs/toolkit/query/react";
  
  export const MAIN_API_REDUCER_KEY = "mainAPI";
  
  const baseQuery = fetchBaseQuery({
    baseUrl: "https://api.pecup.fr/",
    // timeout: 30000,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
  
      const token = localStorage.getItem("appToken");
      if (token && token !== null && token !== "") {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  });
  
  const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
  > = async (args, theApi, extraOptions) => {
    // You can implement token refreshing logic here if needed.
    let result = await baseQuery(args, theApi, extraOptions);
    if (result.error && result.error.status === 401) {
      window.location.href = "/";
      localStorage.removeItem("persist:root");
    }
    return result;
  };
  
  const api = createApi({
    reducerPath: MAIN_API_REDUCER_KEY,
    tagTypes: ["Session", "toats", "users", "insurances", "payments", "centers"],
    baseQuery: baseQueryWithReauth,
    keepUnusedDataFor: 30,
    endpoints: () => ({}), // You can add endpoints as needed.
  });
  
  export default api;
