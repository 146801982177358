import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import {
  ButtonBase,
  Input,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  Typography
} from "@mui/material";
import React from "react";

const stausObj: any = {
  date: "Date de Creation",
  status_update: "Maj statut PEC",
  patient_status_update: "Maj statut Patient",
  Archivées: "Archivées",
  Actives: "Actives",
  "Mes Pecs": "Mes Pecs",
  Toutes: "Toutes",
};

const StatusBar = ({ name, onChange, onSort, onMin, onArchive }: any) => {

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorE2, setAnchorE2] = React.useState<null | HTMLElement>(null);
  const [anchorE3, setAnchorE3] = React.useState<null | HTMLElement>(null);

  const [sort, setSort] = React.useState("Maj statut PEC");
  const [min, setMin] = React.useState("Toutes");
  const [archived, setArchived] = React.useState("Actives");

  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorE2);
  const open3 = Boolean(anchorE3);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorE2(event.currentTarget);
  };
  const handleClick3 = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorE3(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorE2(null);
    setAnchorE3(null);
  };

  return (
    <Stack
      width={"100%"}
      py={0.5}
      bgcolor={"background.paper"}
      borderRadius={2}
      justifyContent={"center"}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mx={2}
      >
        <InputSearchComponent value={name} onChange={onChange} />

        <Stack direction={"row"} spacing={1}>
          <FilterComponent title={sort} onClick={handleClick} />

          <FilterComponent title={min} onClick={handleClick2} />

          <FilterComponent title={archived} onClick={handleClick3} />

          <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {["date", "status_update", "patient_status_update"].map((it) => (
              <MenuItem
                style={{ width: 200 }}
                onClick={() => {
                  handleClose();
                  onSort(it);
                  setSort(stausObj[it]);
                }}
              >
                {stausObj[it]}
              </MenuItem>
            ))}
          </Menu>

          <Menu anchorEl={anchorE2} open={open2} onClose={handleClose}>
            {["Toutes", "Mes Pecs"].map((it) => (
              <MenuItem
                style={{ width: 200 }}
                onClick={() => {
                  handleClose();
                  "Mes Pecs" === it ? onMin(false) : onMin(true);
                  setMin(stausObj[it]);
                }}
              >
                {stausObj[it]}
              </MenuItem>
            ))}
          </Menu>

          <Menu anchorEl={anchorE3} open={open3} onClose={handleClose}>
            {["Actives", "Archivées"].map((it) => (
              <MenuItem
                style={{ width: 200 }}
                onClick={() => {
                  handleClose();
                  "Actives" === it ? onArchive(true) : onArchive(false);
                  setArchived(stausObj[it]);
                }}
              >
                {stausObj[it]}
              </MenuItem>
            ))}
          </Menu>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default StatusBar;

const FilterComponent = ({ title, onClick }: any) => {
  return (
    <ButtonBase
      onClick={onClick}
      style={{ borderRadius: 10, textAlign: "start" }} // Customize styles as needed
    >
      <Stack
        bgcolor={"primary.200"}
        color={"text.primary"}
        borderRadius={2}
        width={200}
        height={40}
        justifyContent={"space-between"}
        direction={"row"}
        alignItems={"center"}
      >
        <Typography ml={1} fontSize={14}>
          {title}
        </Typography>
        <ArrowDropDownIcon sx={{ marginRight: 1 }} />
      </Stack>
    </ButtonBase>
  );
};

export const InputSearchComponent = ({
  value,
  onChange,
  w = "30%",
  icon = true,
}: any) => {
  return (
    <Stack direction={"row"} alignItems={"center"} width={w}>
      {icon && (
        <FilterAltOutlinedIcon sx={{ color: "primary.main", marginRight: 3 }} />
      )}
      
      <Input
        placeholder="Recherche"
        onChange={onChange}
        sx={{
          bgcolor: "primary.200",
          width: "100%",
          height: 40,
          borderRadius: 1.8,
          paddingInline: 1,
          "&:hover, &:focus-within": {
            border: 1,
          }
        }}
        endAdornment={
          <InputAdornment position="end">
            <SearchOutlinedIcon sx={{ color: "text.primary" }} />
          </InputAdornment>
        }
        disableUnderline={true}
      />

    </Stack>
  );
};
