import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { IconButton, Tooltip } from "@mui/material";
import {
  useLazyGetReqArchivedQuery,
  useLazyGetReqToutesQuery,
  useLazyGetReqUnArchivedQuery,
} from "features/toutes/service";
import useFilter from "hooks/useFilter";
import moment from "moment";
import React, { useEffect, useState } from "react";
import CsvDownloader from "react-csv-downloader";

const columns = [
  {
    id: "id",
    displayName: "ID",
  },
  {
    id: "id_doc",
    displayName: "ID Docteur",
  },
  {
    id: "part_amo",
    displayName: "Part AMO",
  },
  {
    id: "date_derdv",
    displayName: "Date deRDV",
  },
  {
    id: "center_id",
    displayName: "Center ID",
  },
  {
    id: "center_name",
    displayName: "Center Nome",
  },
];

export interface DownloadTemplateType {
  page: number;
  limit: number;
  archive?: boolean;
  search?: string;
}

const DownloadTemplate: React.FC<DownloadTemplateType> = ({
  page,
  limit,
  archive = false,
  search,
}) => {
  const [getReqToutes] = useLazyGetReqUnArchivedQuery();
  const [getReqToutesArchived] = useLazyGetReqArchivedQuery();

  const [templateList, setTemplateList] = useState([]);
  const [fetching, setFetching] = useState(true);
  const { data: myFilterData } = useFilter({});

  const handleSetData = (res: any) => {
    if (res?.data) {
      setTemplateList(
        res?.data.map((it: any) => ({
          id: it.id || "-",
          id_doc: it.user_id || "-",
          part_amo: it.ameli_part || "-",
          date_derdv: it.call_date
            ? moment(it.call_date).format("DD/MM/YYYY")
            : "-",
          center_id: it.center.id || "-",
          center_name: it.center.name || "-",
        }))
      );
    }
    setFetching(false);
  };

  const handleCatch = (error: any) => {
    setFetching(false);
    console.log("---error", error);
  };

  useEffect(() => {
    const value = {      
      any_names: search,
      params: myFilterData,
    };
    setFetching(true);
    if (archive) {
      getReqToutesArchived(value)
        .then(handleSetData)
        .catch(handleCatch);
    } else {
      getReqToutes(value)
        .then(handleSetData)
        .catch(handleCatch);
    }
  }, [
    // page,
    // limit,
    search,
    myFilterData.patient_billing_statuses.length,
    myFilterData.patient_statuses.length,
    myFilterData.statuses.length,
    myFilterData.center_ids.length,
  ]);

  if (fetching) {
    return (
      <Tooltip title={"veuillez patienter pendant le chargement des données"}>
        <IconButton>
          <FileDownloadOutlinedIcon htmlColor="lightgray" />
        </IconButton>
      </Tooltip>
    );
  }

  return (
    <CsvDownloader
      datas={templateList}
      filename={archive ? "archives_resports" : "totues_reports"}
      // extension=".xlsx"
      extension=".csv"
      columns={columns}
      separator=";"
      wrapColumnChar="'"
    >
      <IconButton>
        <FileDownloadOutlinedIcon />
      </IconButton>
    </CsvDownloader>
  );
};

export default DownloadTemplate;
