import { NavBar } from "components";
import { Route, Routes } from "react-router-dom";
import DashboardStack from "./deshboard/DashboardStack";
import ToutesStack from "./toutes/ToutesStack";
import AdminStack from "./admin/AdminStack";
import { useAppSelector } from "tools/store";
import { getUser } from "./auth/slice";
import MyyProfile from "./profile/pages/MyyProfile";
// import StatsStack from "./stats/StatsStack";

const AppStack = () => {
  const User = useAppSelector(getUser);
  return (
    <NavBar>
      <Routes>
        <Route path="/dashboard/*" element={<DashboardStack />} />
        <Route path="/toutes/*" element={<ToutesStack />} />
        {User?.role === "admin" ? (
          <Route path="/admin/*" element={<AdminStack />} />
        ) : <></>}
        {/* <Route path="/stats/*" element={<StatsStack />} /> */}
        <Route path="/profile" element={<MyyProfile />} />
      </Routes>
    </NavBar>
  );
};

export default AppStack;
