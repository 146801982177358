import {
  Box,
  ButtonBase,
  IconButton,
  Input,
  InputProps,
  Stack,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

export interface InputFeildType extends InputProps {
  boxShadow?: string;
  height?: number | string;
  width?: number | string;
  bgColor?: string;
  label?: string;
  errors?: string | undefined;
  text?: boolean;
  onClipBoard?: (res: string) => void;
  onClick?: () => void;
  icon?: React.ReactNode;
}

const InputFeild = ({
  boxShadow,
  height,
  width,
  bgColor,
  label,
  errors,
  text = false,
  onClick,
  onClipBoard,
  style = {},
  icon,
  ...props
}: InputFeildType) => {
  return (
    <Box
      style={{
        width: width ?? 350,
      }}
    >
      {/* {label && <Typography mt={.5} ml={.4} fontSize={13}>
            {label}
        </Typography>} */}

      <Stack
        direction={"row"}
        alignItems={"center"}
        mt={0.5}
        mb={0.2}
        spacing={1}
        color={"text.primary"}
      >
        {label && (
          <Typography
            ml={0.4}
            fontSize={13}
            fontWeight={icon ? "bold" : "normal"}
            color={icon ? "#3649EC" : undefined}
          >
            {label}
          </Typography>
        )}
        {icon}
      </Stack>

      {text || onClick ? (
        <ButtonBase
          sx={{
            width: "100%",
            marginBottom: 1.5,
            borderRadius: 2.5,
          }}
          disabled={!onClick}
          onClick={onClick}
        >
          <Stack
            direction={"row"}
            bgcolor={bgColor ?? "white"}
            height={height ?? 60}
            width={"100%"}
            px={2}
            borderRadius={2.5}
            alignItems={"center"}
            boxShadow={boxShadow ?? "10px 10px 30px #d8e7ed"}
            mb={onClick ? 0 : 1.5}
            mt={label ? 0 : 1.3}
            border={errors ? 2 : undefined}
            borderColor={errors ? "red" : undefined}
          >
            <Typography
              fontSize={14}
              display={"flex"}
              flex={1}
              alignItems={"center"}
            >
              {props?.value?.toString()}
            </Typography>
            <ArrowDropDownIcon sx={{ fontSize: 23, marginRight: -1 }} />
          </Stack>
        </ButtonBase>
      ) : (
        <Stack direction={"row"} alignItems={"center"}>
          <Input
            {...props}
            sx={{ color: "text.primary", bgcolor: bgColor ?? "secondary.200" }}
            style={{
              border: errors ? "2px solid red" : undefined,
              fontSize: 14,
              // backgroundColor: bgColor ?? "white",
              borderRadius: 10,
              paddingInline: 15,
              paddingBlock: 15,
              height: props.multiline ? undefined : height ?? 60,
              width: "100%",
              marginBlock: 10,
              boxShadow: boxShadow ?? "10px 10px 30px #d8e7ed",
              marginTop: label ? 0 : 10,
              alignItems: props.multiline ? "start" : "center",
              ...style,
            }}
            disableUnderline={true}
          />

          {onClipBoard && (
            <IconButton onClick={() => onClipBoard(props.value as string)}>
              <ContentCopyIcon fontSize={"small"} />
            </IconButton>
          )}
        </Stack>
      )}

      {/* {errors && (
        <Typography
          mt={0.5}
          ml={0.4}
          color={"red"}
          fontSize={10}
          sx={{ marginTop: -0.5 }}
        >
          {errors}
        </Typography>
      )} */}
    </Box>
  );
};

export default InputFeild;
