import { Select, SelectProps, Stack, Typography } from "@mui/material";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";

export interface InputFeildType extends SelectProps {
  boxShadow?: string;
  height?: number | string;
  width?: number | string;
  bgColor?: string;
  label?: string;
  errors?: string | undefined;
  isShadow?: boolean;
}

const CustomDropDown = ({
  boxShadow,
  height,
  width,
  bgColor,
  label,
  errors,
  isShadow = false,
  ...props
}: InputFeildType) => {
  return (
    <Stack
      sx={{
        marginBottom: 1,
        marginTop: 0.5,
      }}
      color={"text.primary"}
    >
      {/* {label && <Typography ml={.4} fontSize={13}>
            {label}
        </Typography>} */}
      <Stack direction={"row"} alignItems={"center"} mb={0.4} spacing={1}>
        {label && (
          <Typography
            ml={0.4}
            fontSize={13}
            fontWeight={isShadow ? "bold" : "normal"}
            color={isShadow ? "#3649EC" : undefined}
          >
            {label}
          </Typography>
        )}
        {isShadow && (
          <ModeEditOutlineOutlinedIcon
            sx={{ fontSize: 16, color: "#3649EC" }}
          />
        )}
      </Stack>

      <Select
        {...props}
        sx={{
          color: "text.primary",
          boxShadow: isShadow ? "3px 4px 7px #855191" : "none",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
            {
              border: 0,
            },
          backgroundColor: "primary.200",
          height: 45,
          borderRadius: 2.5,
          fontSize: 14,
          border: errors ? "2px solid red" : undefined,
        }}
        type="menuitem"
        placeholder="Select"
      />
    </Stack>
  );
};

export default CustomDropDown;
