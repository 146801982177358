import {
    CircularProgress,
    Stack, Typography
} from '@mui/material';
import { getUser } from 'features/auth/slice';
import { Formik } from 'formik';
import { memo, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector } from 'tools/store';
import { ToastView } from 'utils';
import { PrimaryButton, SecondaryButton, SwitchWithText } from '../../../components';
import { HistoryComp, Patient, Supported } from '../components';
import { useCreateRequestsMutation, useGetSingleInsurancesQuery, useLazyGetSingleCentersQuery, useLazyGetSingleInsurancesQuery, useLazyGetSingleUserQuery, useLazyGetSyncQuery, useLazyGetUsersQuery } from '../service';
import * as Yup from "yup";


const Quote = () => {
    const [searchParams] = useSearchParams();

    const id = searchParams.get("quote_id");
    const [quote_id, setQuoteId] = useState("");

    const User = useAppSelector(getUser)
    const [getSynce, { data, isFetching }] = useLazyGetSyncQuery();
    const [onCreate, { isLoading }] = useCreateRequestsMutation();
    const [getCenter, { data: centerData, isFetching: centerLoading }] = useLazyGetSingleCentersQuery()
    const [getInsurance, { data: insuranceData, isFetching: insuranceLoading }] = useLazyGetSingleInsurancesQuery()
    const [getSingleUser, { data: userData, isFetching: userLoading }] = useLazyGetSingleUserQuery()

    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            setQuoteId(id)
            getSynce(id?.toString())
        }
    }, [])

    useEffect(() => {
        if (quote_id) navigate(`?quote_id=${quote_id}`)
    }, [quote_id])

    useEffect(() => {
        if (data?.internal?.center_id) getCenter(data?.internal?.center_id)
    }, [data?.internal?.center_id])

    useEffect(() => {
        if (data?.internal?.insurance_id) getInsurance(data?.internal?.insurance_id)
    }, [data?.internal?.insurance_id])
    
    useEffect(() => {
        if (data?.internal?.user_id) getSingleUser(data?.internal?.user_id)
    }, [data?.internal?.user_id])

    if (isFetching || isLoading || centerLoading || insuranceLoading || userLoading) {
        return <Stack display={"flex"} flex={1}
            alignItems={"center"}
            justifyContent={"center"}
        >
            <CircularProgress size={60} />
        </Stack>
    }

    const validationSchema = Yup.object().shape({
        center: Yup.object().required("Username is required"),
        user: Yup.object().required("Username is required"),
        insurance: Yup.object().required("Username is required"),
      });    

    return (
        <Stack width={"100%"} justifyContent={"center"} overflow={"hidden"}>

            <Stack bgcolor={"secondary.600"} borderRadius={3} style={{ overflowY: "scroll" }}>

                <Formik
                    initialValues={{
                        /*Support*/
                        // info_quote_id: data?.info_quote_id,
                        info_quote_id: data?.estimate_number.toString(),
                        quote_id: id,
                        status: data?.status || "to_do",
                        user: userData,
                        mode: data?.mode || "online",
                        total: data?.total,
                        insurance_part: data?.insurance_part,
                        ameli_part: data?.ameli_part,
                        updated_at: data?.estimate_date,
                        insurance: insuranceData,
                        payment: data?.payment || {
                            id: 5,
                            updated_at: "2022-03-02T11:56:16.795Z",
                            name: "À confirmer"
                        },
                        expiration_date: data?.expiration_date,
                        center: centerData,

                        /*Patient*/
                        patient_lastname: data?.patient_lastname,
                        patient_name: data?.patient_name,
                        patient_birthdate: data?.patient_birthdate,
                        social_security_number: data?.social_security_number,
                        phone_number: data?.phone_number,
                        comments: data?.comments,
                        patient_status: data?.patient_status || "pending_patient",
                        appointment_date: data?.appointment_date,
                        call_date: data?.call_date,
                        estimate_id: data?.estimate_id,
                        insurance_media_id: data?.insurance_media_id,
                        response_id: data?.response_id,

                        patient_billing_status: data?.patient_billing_status || "to_bill",
                        insurance_status: data?.insurance_status || "to_bill",
                        is_archived: data?.is_archived || false,
                    }}
                    validationSchema={validationSchema}
                    onSubmit={async (values) => {

                        const newValue = {
                            ...data,
                            ...values,
                            center_id: values?.center?.id || data?.internal?.center_id,
                            center_name: values?.center?.name || data?.center_name,
                            user_id: values?.user?.id || data?.internal.user_id,
                            insurance_id: values?.insurance?.id || data?.internal?.insurance_id,
                        }
                        // newValue["user_id"] = newValue.internal.user_id
                        // newValue["insurance_id"] = newValue.internal.insurance_id

                        delete newValue.insurance_media
                        delete newValue.response
                        delete newValue.estimate
                        delete newValue.center
                        delete newValue.insurance
                        delete newValue.payment
                        delete newValue.user
                        delete newValue.internal

                        delete newValue.AMC2_id
                        delete newValue.AMC2_name
                        delete newValue.AMO_id
                        delete newValue.AMO_name
                        delete newValue.home_phonenumber
                        delete newValue.doctor
                        delete newValue.estimate_number
                        delete newValue.proposition_id

                        delete newValue.updated_ats
                        delete newValue.billing_number
                        delete newValue.billing_number_1

                        await onCreate(newValue).then((res: any) => {
                            if (res?.error) {
                                ToastView(res?.error?.data?.error, "error")
                            } else {
                                ToastView(`PEC ${id} modifiée avec succès`, "success")
                                navigate("/dashboard")
                            }
                        }).catch((e) => {
                            ToastView(e, "error")
                            // console.log("=========== error:", e)
                        })
                    }}
                >
                    {props => (

                        <Stack p={2}>

                            <Stack>

                                <Stack direction={"row"} justifyContent={"space-between"} flex={1}>
                                    <Stack direction={"row"} spacing={1}>
                                        {/* <SecondaryButton title="Synchronize" disabled={loadingSync} loading={loadingSync} onClick={() => getSynchronize(props)} /> */}
                                        {/* <SecondaryButton title="Valider" onClick={() => props.handleSubmit()} /> */}
                                        <PrimaryButton
                                            title="Valider"
                                            boxShadow="5px 5px 15px #62367d"
                                            style={{
                                                width: 100,
                                                paddingBlock: 10,
                                                fontWeight: "bold",
                                                fontSize: 15,
                                            }}
                                            onClick={() => props.handleSubmit()}
                                        />
                                    </Stack>


                                    <Stack direction={"row"} spacing={3}>
                                        <SwitchWithText
                                            title='Patient à facturer'
                                            checked={props.values.patient_billing_status === "billed"}
                                            onChange={() => props.setFieldValue("patient_billing_status", props.values.patient_billing_status === "billed" ? "to_bill" : "billed")}
                                        />

                                        <SwitchWithText
                                            title='Mutuelle à facturer'
                                            checked={props.values.insurance_status === "billed"}
                                            onChange={() => props.setFieldValue("insurance_status", props.values.insurance_status === "billed" ? "to_bill" : "billed")}
                                        />

                                        {User.role === "admin" && <SwitchWithText
                                            title='À payer'
                                            checked={props.values.is_archived}
                                            onChange={() => props.setFieldValue("is_archived", props.values.is_archived ? false : true)}
                                        />}
                                    </Stack>
                                </Stack>

                            </Stack>

                            <Stack mx={10}>

                                <Stack flex={1} direction={"row"} justifyContent={"space-around"} mt={8}>
                                    <Typography variant='h5'>
                                        Prise en charge
                                    </Typography>
                                    <Typography variant='h5'>
                                        Patient
                                    </Typography>
                                </Stack>

                                <Stack direction={"row"} display={"flex"} flex={1} spacing={2}>

                                    <Supported {...props} />

                                    <Patient {...props} />

                                </Stack>

                            </Stack>

                        </Stack>

                    )}

                </Formik>

                <HistoryComp id={id} />

                <Stack py={5} />

            </Stack >

        </Stack >
    )
}


export default memo(Quote);