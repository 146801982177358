import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  Collapse,
  Divider,
  Grid,
  ListItemButton,
  Stack,
  useColorScheme
} from "@mui/material";
import { useState } from "react";
import CenterForm from "./CenterForm";

const CenterRenderItem = ({ item, index }: any) => {
  const [open, setOpen] = useState(false);

  return (
    <Stack key={index}>
      {index !== 0 && <Divider />}
      <ListItemButton
        sx={{ justifyContent: "space-between", py: 2, color:"text.primary" }}
        onClick={() => {
          if (item.list?.length && item.list?.length > 0) {
            setOpen(!open);
          } else {
            setOpen(!open);
          }
        }}
      >
        <Grid container gridRow={1} gridColumn={2} justifyContent={"space-between"}>
          <Grid item sx={{ fontWeight: "bold" }}>
            {item?.name}
          </Grid>
          <Grid item sx={{ mr: 20 }}>
            {item?.payments?.length + " modes de paiement"}
          </Grid>
        </Grid>

        {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <CenterForm {...item} onClosed={() => setOpen(false)} />
      </Collapse>
    </Stack>
  );
};

export default CenterRenderItem;
