import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { FormControlLabel, Stack, Typography } from "@mui/material";
import useColorScheme from "hooks/useColorScheme";
import { capitalizeCamelCase } from "utils";
import ThemeSwitch from "./ThemeSwitch";

const Header = ({ name, title, count }: any) => {
  const { mode, setMode }: any = useColorScheme();

  return (
    <Stack
      width={"100%"}
      py={1.5}
      bgcolor={"background.paper"}
      style={{
        borderTopRightRadius: 5,
        borderEndEndRadius: 5,
        borderTopLeftRadius: 5,
      }}
      mb={0.5}
      justifyContent={"center"}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        mx={1}
      >
        <Typography
          color={"primary.main"}
          fontSize={30}
          fontWeight="bold"
          ml={3}
          mr={1}
        >
          {title ?? "PEC UP"}
        </Typography>

        <RocketLaunchIcon sx={{ color: "primary.main" }} fontSize={"large"} />

        <Stack direction={"row"} marginLeft="auto" spacing={10} mr={3}>
          <FormControlLabel
            control={
              <ThemeSwitch
                checked={mode == "light" ? false : true}
                onChange={() => setMode(mode == "light" ? "dark" : "light")}
              />
            }
            label={`${mode == "light" ? "Dark" : "Light"} Mode`}
            sx={{ color: "text.primary" }}
          />          

          <Typography color={"primary.main"} fontSize={22}>
            {capitalizeCamelCase(name)}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Header;
