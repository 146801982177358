import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../tools/store';
import { AuthenticationSliceInterface } from './types';

const initialState: AuthenticationSliceInterface = {
  remeber: { email: "", password: "", check: false }
};

export const AuthenticationReducerName = 'authentication';

export const AuthenticationSlice = createSlice({
  name: AuthenticationReducerName,
  initialState,
  reducers: {
    setSignedInUser: (state, action) => {
      state.user = action.payload;
      state.session = action.payload;
    },
    signOut: (state) => {
      delete state.user;
      delete state.session;
    },
    setRemember: (state, action) => {
      state.remeber = action.payload;
    }
  }
});

export const { setSignedInUser, signOut, setRemember } = AuthenticationSlice.actions;
export const getUser = (state: RootState) => state.authentication.user;
// export const getSelectedWarehouse = (state: any) => state.global.selectedWarehouses;
export const getSession = (state: RootState) => state.authentication.session;
export const getRemember = (state: RootState) => state.authentication.remeber;

export default AuthenticationSlice.reducer;
