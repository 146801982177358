import api from "tools/api";
import { FormToUrl } from "utils";

export const AdminService = api.injectEndpoints({
  endpoints: (build) => ({
    getUserList: build.query({
      query: (obj) => `users?${FormToUrl(obj)}`,
      providesTags: ["users"],
    }),
    deleteUser: build.mutation({
      query: (id) => ({
        url: `users/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["users"],
    }),
    resetUser: build.query({
      query: (name) => `auth/reset/${encodeURIComponent(name)}`,
    }),
    updateUser: build.mutation({
      query: ({ id, body }) => ({
        url: `users/${id}`,
        method: "PATCH",
        body
      }),
      invalidatesTags: ["users"],
    }),
    createUser: build.mutation({
      query: (body) => ({
        url: "users",
        method: "POST",
        body
      }),
      invalidatesTags: ["users"],
    }),
    resendUser: build.query({
      query: (name) => `auth/resend/${encodeURIComponent(name)}`,
    }),
    syncUser: build.mutation<any, void>({
      query: () => ({
        url: "sync/users",
        method: "POST",
      }),
      invalidatesTags: ["users"],
    }),
    getInsurancesList: build.query({
      query: (obj) => `insurances?${FormToUrl(obj)}`,
      providesTags: ["insurances"],
    }),
    getAllPayments: build.query({
      query: (obj) => `payments?${FormToUrl(obj)}`,
      providesTags: ["payments"],
    }),
    syncCenters: build.mutation<any, void>({
      query: () => ({
        url: "sync/centers",
        method: "POST",
      }),
      invalidatesTags: ["centers"],
    }),
    getAllCenters: build.query({
      query: (obj) => `centers?${FormToUrl(obj)}`,
      providesTags: ["centers"]
    }),
    updateCenter: build.mutation({
      query: ({ id, body }) => ({
        url: `centers/${id}`,
        method: "PATCH",
        body
      }),
      invalidatesTags: ["centers"],
    }),
    createCenter: build.mutation({
      query: (body) => ({
        url: "centers",
        method: "POST",
        body
      }),
      invalidatesTags: ["centers"],
    }),
    deleteCenter: build.mutation({
      query: (id) => ({
        url: `centers/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["centers"],
    }),
    syncInsurances: build.mutation<any, void>({
      query: () => ({
        url: "sync/insurances",
        method: "POST",
      }),
      invalidatesTags: ["insurances"],
    }),

    deleteInsurances: build.mutation({
      query: (id) => ({
        url: `insurances/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["insurances"],
    }),
    updateInsurances: build.mutation({
      query: ({ id, body }) => ({
        url: `insurances/${id}`,
        method: "PATCH",
        body
      }),
      invalidatesTags: ["insurances"],
    }),
    createInsurances: build.mutation({
      query: (body) => ({
        url: "insurances",
        method: "POST",
        body
      }),
      invalidatesTags: ["insurances"],
    }),

    deletePayments: build.mutation({
      query: (id) => ({
        url: `payments/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["payments"],
    }),
    updatePayments: build.mutation({
      query: ({ id, body }) => ({
        url: `payments/${id}`,
        method: "PATCH",
        body
      }),
      invalidatesTags: ["payments"],
    }),
    createPayments: build.mutation({
      query: (body) => ({
        url: "payments",
        method: "POST",
        body
      }),
      invalidatesTags: ["payments"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserListQuery,
  useDeleteUserMutation,
  useLazyResetUserQuery,
  useUpdateUserMutation,
  useCreateUserMutation,
  useLazyResendUserQuery,
  useSyncUserMutation,
  useGetInsurancesListQuery,
  useGetAllPaymentsQuery,
  useSyncCentersMutation,
  useGetAllCentersQuery,
  useCreateCenterMutation,
  useUpdateCenterMutation,
  useDeleteCenterMutation,
  useSyncInsurancesMutation,
  useDeleteInsurancesMutation,
  useCreateInsurancesMutation,
  useUpdateInsurancesMutation,
  useDeletePaymentsMutation,
  useCreatePaymentsMutation,
  useUpdatePaymentsMutation
} = AdminService;
