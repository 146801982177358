import Highcharts from "highcharts";
import React, { useEffect, useRef } from "react";

import { Typography } from '@mui/material';
import HighchartsAccessibility from 'highcharts/modules/accessibility';
import HighchartsExportData from 'highcharts/modules/export-data';
import HighchartsExporting from 'highcharts/modules/exporting';

HighchartsExporting(Highcharts);
HighchartsExportData(Highcharts);
HighchartsAccessibility(Highcharts);

interface type {
  data: {
    name: string;
    y: number;
  }[];
  title: string;
}

const PieChartComponent: React.FC<type> = ({ data, title }) => {
  const chartContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (chartContainer.current) {
      (function (H: any): void {
        H.seriesTypes.pie.prototype.animate = function (
          this: any,
          init: boolean
        ): void {
          const series = this,
            chart = series.chart,
            points = series.points || [],
            animation: any = series.options
              .animation as Highcharts.AnimationOptionsObject,
            startAngleRad = series.startAngleRad || 0;

          function fanAnimate(
            point: Highcharts.Point,
            startAngleRad: number
          ): void {
            const graphic = point.graphic,
              args = point.shapeArgs;

            if (graphic && args) {
              graphic
                .attr({
                  start: startAngleRad,
                  end: startAngleRad,
                  opacity: 1,
                })
                .animate(
                  {
                    start: args.start,
                    end: args.end,
                  },
                  {
                    duration: animation.duration / points.length,
                  },
                  function (): void {
                    if (points[point.index + 1]) {
                      fanAnimate(points[point.index + 1], args.end);
                    }

                    if (point.index === points.length - 1) {
                      series.dataLabelsGroup.animate(
                        {
                          opacity: 1,
                        },
                        undefined as any,
                        function (): void {
                          points.forEach((point: any): void => {
                            point.opacity = 1;
                          });
                          series.update(
                            {
                              enableMouseTracking: true,
                            },
                            false
                          );
                          chart.update({
                            plotOptions: {
                              pie: {
                                innerSize: "40%",
                                borderRadius: 8,
                              },
                            },
                          });
                        }
                      );
                    }
                  }
                );
            }
          }

          if (init) {
            points.forEach((point: any): void => {
              point.opacity = 0;
            });
          } else {
            fanAnimate(points[0], startAngleRad);
          }
        };
      })(Highcharts);

      const options: Highcharts.Options = {
        chart: {
          type: "pie",
          renderTo: chartContainer.current,
          backgroundColor: "none"
        },
        title: {
          text: title,
          align: "left",
          style: { color: "#62367d" }
        },
        // subtitle: {
        //   text: 'Custom animation of pie series',
        //   align: 'left',
        // },
        tooltip: {
          // pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
          pointFormat: "{series.name}: <b>{point.percentage}%</b>",
        },
        accessibility: {
          point: {
            valueSuffix: '%',
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            borderWidth: 2,
            // borderColor: "#0f161b",
            cursor: "pointer",
            dataLabels: {
              enabled: true,
              format: "<b>{point.name}</b><br>{point.percentage:.0f}%",
              distance: 20,
            },
          },
        },
        series: [
          {
            type: "pie",
            enableMouseTracking: false,
            animation: {
              duration: 2000,
            },
            // colorByPoint: true,
            data,
          },
        ],
      };
      Highcharts.chart(chartContainer.current, options);
    }
  }, []);

  return (
    <div
      className="highcharts-figure"
      style={{
        // width: "30%",
        width: "100%",
      }}
    >
      <style>
        {`
          .highcharts-figure,
          .highcharts-data-table table {
              min-width: 320px;
              max-width: 800px;
              margin: 1em auto;
          }

          .highcharts-data-table table {
              font-family: Verdana, sans-serif;
              border-collapse: collapse;
              border: 1px solid #ebebeb;
              margin: 10px auto;
              text-align: center;
              width: 100%;
              max-width: 500px;
          }

          .highcharts-data-table caption {
              padding: 1em 0;
              font-size: 1.2em;
              color: #555;
          }

          .highcharts-data-table th {
              font-weight: 600;
              padding: 0.5em;
          }

          .highcharts-data-table td,
          .highcharts-data-table th,
          .highcharts-data-table caption {
              padding: 0.5em;
          }

          .highcharts-data-table thead tr,
          .highcharts-data-table tr:nth-child(even) {
              background: #f8f8f8;
          }

          .highcharts-data-table tr:hover {
              background: #f1f7ff;
          }
        `}
      </style>
      <div ref={chartContainer}></div>
      <Typography className="highcharts-description" color={"text.primary"}>
        Pie charts demo showing how to create a custom entrance animation.
      </Typography>
    </div>
  );
};
export default PieChartComponent;
