import { Stack, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

const FlatList: React.FC<{
  limit: number;
  renderItem: (item: any, index: number) => React.ReactNode;
  data: any[];
  loader?: React.ReactNode;
  isLoading?: boolean;
  onChange: (it: number) => void;
}> = ({
  limit,
  data,
  renderItem,
  isLoading = false,
  loader,
  onChange,
}) => {
    const lastScrollTopRef = useRef<number>(0);
    const [page, setPage] = useState<number>(limit);
    const containerRef = useRef<HTMLDivElement | null>(null);

    const handleScroll = () => {
      const container = containerRef.current;
      if (container) {
        const scrollPosition = container.scrollTop;
        const scrollDirection =
          scrollPosition > lastScrollTopRef.current ? "down" : "up";
        lastScrollTopRef.current = scrollPosition;

        if (
          scrollDirection === "down" &&
          container.scrollHeight - scrollPosition <= container.clientHeight + 5
        ) {
          // if (query_count && data.length < query_count) {
          setPage(page + 10);
          // }
        }
      }
    };

    useEffect(() => {
      onChange(page);
    }, [page]);


    if (data.length === 0 && !isLoading) {
      return (
        <Stack
          width={"100%"} alignItems="center" height="full" justifyContent="center"
        >
          <Typography fontSize={20} fontWeight={"bold"}>No Data Found</Typography>
        </Stack>
      );
    }

    return (
      <Stack
        alignItems="flex-start"
        height="100%"
        width={"100%"}
        sx={{ overflowY: "scroll", overflowX: "none" }}
        justifyContent="flex-start"
        ref={containerRef}
        onScroll={handleScroll} // Directly assign onScroll handler
      >
        {data.map(renderItem)}

        {isLoading && loader && (
          <Stack
            width="100%"
            alignItems="center"
          >
            {[1, 1, 1].map(() => loader)}
          </Stack>
        )}

      </Stack>
    );
  };

export default FlatList;
