import ClearIcon from "@mui/icons-material/Clear";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { CircularProgress, IconButton, Stack } from "@mui/material";
import { useDeleteMediaMutation } from "features/Global/service";
import { memo, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

interface type {
  id: string | undefined | null;
  onCallBack: (res: string) => void;
}

const PDFViewer: React.FC<type> = ({ id, onCallBack }) => {
  const [deleteMedia, { isLoading: isDeleting }] = useDeleteMediaMutation();

  const [data, setData] = useState<any>(null);
  const [dynamicID, setDynamic] = useState<string | undefined | null>(id);
  const [fileName, setFileName] = useState<string>("sample");

  const fetchData = async () => {
    const token = localStorage.getItem("appToken");
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    var requestOptions: RequestInit = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`https://api.pecup.fr/media/${dynamicID}`, requestOptions)
      .then((response) => {
        const headersObj: any = {};
        response.headers.forEach((value, name) => {
          headersObj[name] = value;
        });
        var decodedString = decodeURIComponent(
          headersObj["content-disposition"]
            ?.replace("inline; filename=", "")
            .replace('"', "")
            .replace('"', "")
        );
        setFileName(decodedString);

        return response.blob();
      })
      .then((result) => {
        setData(result);
      })
      .catch((error) => console.log("error", error));
  };

  const downloadPDF = () => {
    const blob = new Blob([data], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  useEffect(() => {
    if (id) {
      setDynamic(id);
      fetchData();
    }
  }, [id]);

  const onDrop = useCallback(async (acceptedFiles: any) => {
    const file = acceptedFiles[0];

    try {
      const token = localStorage.getItem("appToken");
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${token}`);

      var formdata = new FormData();
      formdata.append("filepond", file, file.name);

      var requestOptions: RequestInit = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch("https://api.pecup.fr/media", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          setDynamic(result);
          onCallBack(result);
        })
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
    },
    onDrop,
  });

  if (dynamicID === null || dynamicID === undefined || !data)
    return (
      <Stack color={"text.primary"}>
        <section className="container">
          <div
            {...getRootProps({ className: "dropzone" })}
            style={{
              // borderStyle: "dashed",
              borderStyle: "solid",
              borderWidth: 1,
              borderRadius: 7,
              padding: 40,
            }}
          >
            <input {...getInputProps()} />
            <p>Faites glisser le document ici ou Parcourir</p>
          </div>
        </section>
      </Stack>
    );

  return (
    <Stack position="relative">
      {/* <Typography fontSize={10} position={"absolute"} top={-13} right={2} color={"primary.main"}>Nom de fichier:{` ${fileName}`}</Typography> */}
      <Stack
        width="100%"
        maxHeight={330}
        overflow="hidden"
        borderRadius={3}
        border={2}
        borderColor="#FFFFFF"
        position="relative"
      >
        <Stack
          display={"flex"}
          direction={"row"}
          sx={{ position: "absolute", zIndex: 1000, top: 0 }}
        >
          {isDeleting ? (
            <CircularProgress
              size={20}
              sx={{ margin: 1, color: "secondary.main" }}
            />
          ) : (
            <IconButton
              onClick={() => {
                deleteMedia(dynamicID).then(() => {
                  setData(null);
                });
              }}
            >
              <ClearIcon htmlColor="secondary.main" />
            </IconButton>
          )}
          <IconButton onClick={downloadPDF}>
            <DownloadRoundedIcon htmlColor="secondary.main" />
          </IconButton>
        </Stack>

        <Stack height={400}>
          <embed
            src={URL.createObjectURL(data) + "#toolbar=0"}
            type="application/pdf"
            height={"100%"}
            width={"100%"}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default memo(PDFViewer);
