import { Avatar, Stack, Typography } from '@mui/material';




const TopHeading = ({ title, count }: any) => {


  return (
    <Stack p={2} alignItems={"center"} bgcolor={"background.paper"} borderRadius={2}>
      <Stack direction={"row"} alignContent={"center"} justifyContent={"center"}>
        <Typography color={"text.primary"}>
          {title}
        </Typography>
        <Stack px={1.1} ml={1} borderRadius={3} alignContent={"center"} justifyContent={"center"} sx={{ background: "red" }}>
          <Typography fontSize={10} color={"white"}>
            {count}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
}

export default TopHeading;
