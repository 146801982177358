import { Stack, Typography } from '@mui/material';

const TopHeading = ({ title, count }: { title: string; count: string }) => {
  return (
    <Stack
      p={2}
      alignItems={"center"}
      bgcolor={"#1c2a4d"} // ✅ Bleu fixe pour le fond
      borderRadius={"8px 8px 0 0"} // ✅ Coins arrondis seulement en haut
      data-title={title} // ✅ Ajout pour que DashboardStatsModal puisse récupérer les valeurs
    >
      <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
        <Typography color={"#FFFFFF"}>{title}</Typography>
        <Stack px={1.1} ml={1} borderRadius={3} alignItems={"center"} justifyContent={"center"} sx={{ background: "red" }}>
          <Typography fontSize={14} color={"white"}>
            {count}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default TopHeading;
