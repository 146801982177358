import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import pecuplogo from "assets/images/pecuplogo.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { InputFeild, PrimaryButton } from "components";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "tools/store";
import { ToastView } from "utils";
import * as Yup from "yup";
import { useSignInMutation } from "../service";
import { getRemember, getUser, setRemember } from "../slice";

const SignIn = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate: any = useNavigate();
  const dispatch = useAppDispatch();
  const remember = useAppSelector(getRemember);
  const [signIn, { isLoading }] = useSignInMutation();
  const User = useAppSelector(getUser);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (User) {
      navigate("/dashboard", true);
    }
  }, [User]);

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("Password is required"),
  });

  const { handleBlur, handleChange, values, handleSubmit, errors, touched } =
    useFormik({
      initialValues: {
        username: remember?.check ? remember?.email : "",
        password: remember?.check ? remember?.password : "",
      },
      validationSchema,
      onSubmit: async (values: any) => {
        signIn(values)
          .then((data: any) => {
            if (!data.error) {
              ToastView("connexion réussie", "success");
              if (remember?.check) {
                dispatch(
                  setRemember({
                    ...remember,
                    email: values.username,
                    password: values.password,
                  })
                );
              }
              navigate("/dashboard", true);
            } else ToastView("Utilisateur ou mot de passe incorrect", "error");
          })
          .catch((e) => {
            ToastView(e, "error");
          });
      },
    });

  return (
    <Stack
      width={"100%"}
      height={"100%"}
      bgcolor={"primary.200"}
      justifyContent={"center"}
      padding={isMobile ? 2 : 0}
    >
      <Stack
        width={isMobile ? "90%" : "75%"}
        mx={"auto"}
        flex={1}
        bgcolor={"secondary.600"}
        borderRadius={8}
        color={"white"}
        my={2.5}
        pr={isMobile ? undefined : 7}
        boxShadow={"30px 30px 70px rgba(98, 54, 125, 0.5)"}
      >
        <Stack direction={isMobile ? "column" : "row"} flex={1} m={1}>
          <Stack
            flex={1}
            height={isMobile ? "auto" : "100%"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <img
              src={pecuplogo}
              style={{
                height: isMobile ? "15rem" : "22rem",
                width: isMobile ? "15rem" : "22rem",
                marginTop: isMobile ? 0 : -30,
              }}
              alt="PEC UP Logo"
            />
          </Stack>

          <Stack
            flex={1}
            height={isMobile ? "auto" : "100%"}
            justifyContent={"center"}
            alignItems={"center"}
            padding={isMobile ? 2 : 0}
          >
            <Typography color={"primary.main"} variant="h4" fontWeight={"bold"}>
              PEC UP
            </Typography>
            <Stack my={4}>
              <InputFeild
                type="text"
                name="username"
                placeholder="Identifiant"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.username}
                errors={
                  touched.username && errors.username
                    ? errors.username.toString()
                    : undefined
                }
              />

              <InputFeild
                type={showPassword ? "text" : "password"}
                name="password"
                placeholder="Mot de passe"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                errors={
                  touched.password && errors.password
                    ? errors.password.toString()
                    : undefined
                }
                onKeyDown={(e: any) => {
                  if (e.key === "Enter") {
                    handleSubmit();
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      sx={{ color: "text.primary" }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />

              <Stack
                direction={isMobile ? "column" : "row"}
                mt={1}
                justifyContent={isMobile ? "center" : "space-between"}
                alignItems={isMobile ? "center" : "initial"}
                mx={2}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={remember?.check}
                      onChange={() =>
                        dispatch(
                          setRemember({ ...remember, check: !remember?.check })
                        )
                      }
                      color={"secondary"}
                    />
                  }
                  label={
                    <Typography fontSize={13} color={"text.primary"}>
                      Se rappeler de moi
                    </Typography>
                  }
                />

                <Button
                  sx={{
                    color: "transparent",
                    background: "transparent",
                    textTransform: "none",
                  }}
                  onClick={() => navigate("/forgotpass")}
                >
                  <Typography fontSize={12}>Mot de passe oublié</Typography>
                </Button>
              </Stack>
            </Stack>

            <PrimaryButton
              title="Se connecter"
              loading={isLoading}
              disabled={isLoading}
              onClick={() => {
                handleSubmit();
              }}
              type="submit"
            />

            <Button
              sx={{
                marginTop: 10,
                alignSelf: "center",
                color: "transparent",
                background: "transparent",
                height: 5,
                textTransform: "none",
              }}
              onClick={() => navigate("/privacy-policy")}
            >
              <Typography fontSize={15}>Données privées</Typography>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SignIn;
