import {
  Stack
} from "@mui/material";
import { PrimaryButton } from "components";
import { useFormik } from "formik";
import { ToastView } from "utils";
import * as Yup from "yup";
import {
  useCreateInsurancesMutation,
  useDeleteInsurancesMutation,
  useUpdateInsurancesMutation
} from "../../service";
import CustomStandardInput from "../CustomStandardInput";


const validationSchema = Yup.object().shape({
  // name: Yup.string().required("Name is required"),
  // url: Yup.string().url('Invalid URL').required('URL is required'),
  name: Yup.string().required().label('Nom'),
  external_id: Yup
    .number()
    .positive()
    .label('ID Desmos'),
  url: Yup.string().url().label('URL'),
});

const MutuellsForm: React.FC<{
  name?: string;
  external_id?: number;
  url?: string;
  id?: number;
  onClosed: () => void;
}> = ({
  name,
  external_id,
  url,
  id,
  onClosed
}) => {

    const [onDelete, { isLoading: isDeleting }] = useDeleteInsurancesMutation();
    const [onUpdate, { isLoading: isUpdating }] = useUpdateInsurancesMutation();
    const [onCreate, { isLoading: isCreatting }] = useCreateInsurancesMutation();


    const onSubmit = (values: any) => {
      if (id) {
        onUpdate({ id, body: values })
          .then((res: any) => {
            if (!res?.error) {
              ToastView("Mutuelle mise à jour !", "success");
              onClosed()
            }
          })
          .catch((e) => {
            console.log("=-=-=-=-=-:::::", e);
          });
      } else {
        onCreate(values)
          .then((res: any) => {
            if (!res?.error) {
              ToastView("Mutuelle ajoutée !", "success");
              onClosed()
            }
          })
          .catch((e) => {
            console.log("=-=-=-=-=-:::::", e);
          });
      }
    };

    const {
      handleBlur,
      handleChange,
      values,
      handleSubmit,
      errors,
      touched,
    } = useFormik({
      initialValues: {
        name: name || undefined,
        external_id: external_id || undefined,
        url: url || undefined,
      },
      validationSchema,
      onSubmit,
    });
    // console.log("=-=-=-==-=-=:::::tr:::", traformPaymentList)
    // console.log("=-=-=-==-=-=:::::", values.payments)
    return (
      <Stack
        flex={1}
        height={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Stack my={4} width={"100%"}>
          <CustomStandardInput
            label="Nom"
            value={values.name}
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.name && errors.name ? true : false}
          />
          <CustomStandardInput
            label="ID DESMOS"
            value={values.external_id}
            name="external_id"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.external_id && errors.external_id ? true : false}
          />
          <CustomStandardInput
            label="URL"
            value={values.url}
            name="url"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.url && errors.url ? true : false}
          />
        </Stack>

        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          width={"97%"}
          py={2}
        >
          {id && (
            <PrimaryButton
              title="Supprimer"
              onClick={() => onDelete(id).then((res: any) => {
                if (!res?.error) {
                  ToastView("Supprimé avec succès", "success");
                  onClosed()
                }
              })}
              style={{ width: 150, paddingBlock: 15, borderRadius: 6 }}
              boxShadow=""
              loading={isDeleting}
            />
          )}
          <PrimaryButton
            title="Enregister"
            onClick={() => handleSubmit()}
            style={{ width: 150, paddingBlock: 15, borderRadius: 6 }}
            boxShadow=""
            loading={isUpdating || isCreatting}
          />
        </Stack>
      </Stack>
    );
  };

export default MutuellsForm;
