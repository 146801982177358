import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SearchIcon from "@mui/icons-material/Search";
import UnarchiveOutlinedIcon from "@mui/icons-material/UnarchiveOutlined";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { DownloadTemplate, Filters, PrimaryButton } from "components";
import useFilter from "hooks/useFilter";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import {
  Jsons,
  ToastView,
  capitalization,
  colorJson,
  diff,
  modeIconJson,
  replacer,
} from "utils";
import EnhancedTableHead, {
  CustomInput,
} from "../components/EnhancedTableHead";
import {
  useLazyGetReqUnArchivedQuery,
  useUpdateToArchiveMutation,
} from "../service";
import { Data, Order } from "../types";
import { InputSearchComponent } from "components/StatusBar";

export default function Toutes() {
  const [order] = React.useState<Order>("asc");
  const [orderBy] = React.useState<keyof Data>("calories");
  const [selected, setSelected] = React.useState<number[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSeacrh] = React.useState("");

  const [getReqToutes, { data, isFetching: loadingReqToutes }] =
    useLazyGetReqUnArchivedQuery();
  const { data: myFilterData } = useFilter({});
  const [updateArchive, { isLoading: isUpdating = false }] =
    useUpdateToArchiveMutation();

  React.useEffect(() => {
    const value = {
      page,
      limit: rowsPerPage,
      any_names: search,
      params: myFilterData,
    };
    getReqToutes(value);
  }, [
    page,
    rowsPerPage,
    search,
    myFilterData.patient_billing_statuses.length,
    myFilterData.patient_statuses.length,
    myFilterData.statuses.length,
    myFilterData.center_ids.length,
  ]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const navigator = useNavigate();

  return (
    <Box width={"100%"} pt={1} overflow={"auto"}>
      <Collapse in={selected.length > 0}>
        <Box
          sx={{
            width: "98%",
            margin: "auto",
            height: 48,
            alignItems: "center",
            position: "relative",
            display: {
              xs: "column",
              sm: "column",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
          }}
        >
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            width={"100%"}
          >
            <Typography>{selected.length} éléments sélectionnés</Typography>
            {isUpdating ? (
              <CircularProgress
                size={15}
                sx={{ color: "primary.main", mr: 5 }}
              />
            ) : (
              <Stack direction={"row"}>
                <Button
                  style={{ textTransform: "none", color: "primary.main" }}
                  onClick={() => {
                    if (selected.length > 0)
                      updateArchive({ ids: selected, is_archived: true }).then(
                        (res) => {
                          ToastView("Archiver réussie", "success");
                          setSelected([]);
                        }
                      );
                  }}
                >
                  Archiver
                  <UnarchiveOutlinedIcon
                    htmlColor={"primary.main"}
                    fontSize={"small"}
                    style={{ marginLeft: 10 }}
                  />
                </Button>
                <Divider
                  orientation="vertical"
                  style={{ height: "90%", marginInline: 10 }}
                />
                <Button
                  style={{ textTransform: "none", color: "primary.main" }}
                  onClick={() => setSelected([])}
                >
                  Annuler
                </Button>
              </Stack>
            )}
          </Stack>
        </Box>
      </Collapse>

      <Collapse in={selected.length === 0}>
        <Box
          sx={{
            width: "98%",
            margin: "auto",
            height: 48,
            alignItems: "center",
            position: "relative",
            display: {
              xs: "column",
              sm: "column",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
          }}
        >
          <InputSearchComponent
            w="100%"
            icon={false}
            onChange={(val: any) => {
              setSeacrh(val.target.value);
              setPage(0);
            }}
          />

          <Stack height={48} direction={"row"}>
            <Filters />

            <Stack direction={"row"} spacing={1} alignItems={"center"} pb={1}>
              <DownloadTemplate
                page={page}
                limit={rowsPerPage}
                archive={false}
                search={search}
              />
              {/* 
<PrimaryButton
  title={"Voir Stats"}
  onClick={() => navigator("/stats", { state: { demandes: data || [], filters: myFilterData } })}
  style={{ width: 120, paddingBlock: 15, borderRadius: 6 }}
  boxShadow=""
/>
*/}

{/* 
<PrimaryButton
  title={"Ajouter"}
  style={{ width: 80, paddingBlock: 15, borderRadius: 6 }}
  boxShadow=""
/>
*/}
            </Stack>
          </Stack>
        </Box>
      </Collapse>

      {loadingReqToutes ? (
        <Stack
          pt={"calc(100% - 80%)"}
          alignItems={"center"}
          justifyContent={"center"}
          overflow={"hidden"}
        >
          <CircularProgress size={60} />
        </Stack>
      ) : (
        <Box
          sx={{
            width: "98%",
            margin: "auto",
            mt: { xs: 13, sm: 13, md: 7, lg: 0, xl: 0 },
          }}
        >
          <TableContainer
            sx={{
              borderRadius: 2,
              overflow: "hidden",
              // boxShadow: "5px 5px 10px #62367d",
              boxShadow: "5px 5px 10px #cfbfd9",
            }}
          >
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"small"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={() => {
                  if (selected.length === rowsPerPage) {
                    setSelected([]);
                  } else
                    setSelected(data?.map((row: any, index: number) => row.id));
                }}
                onRequestSort={() => { }}
                rowCount={rowsPerPage}
              />

              <TableBody>
                {data?.map((row: any, index: number) => {
                  const isItemSelected = selected.includes(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  const myJson: any = { ...Jsons };
                  const status = myJson.pec.pec_status[row?.status];
                  const patient_status =
                    myJson.pec.patient_status[row?.patient_status];
                  const status_update = diff(row?.status_update);

                  return (
                    <TableRow
                      hover
                      onClick={() => {
                        navigator(`/toutes/pec/${row.id}`);
                      }}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                      sx={{
                        cursor: "pointer",
                        bgcolor: "secondary.200",
                        // background: index % 2 === 0 ? "white" : "#eee",
                        height: 50,
                      }}
                    >
                      <TableCell
                        padding="checkbox"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <Checkbox
                          sx={{ color: "text.primary" }}
                          checkedIcon={<CheckCircleIcon />}
                          color="primary"
                          checked={isItemSelected}
                          onChange={() => {
                            if (isItemSelected) {
                              const temp = [...selected];
                              temp.splice(
                                temp.findIndex((it) => it === row.id),
                                1
                              );
                              setSelected(temp);
                            } else setSelected([...selected, row.id]);
                          }}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        align="left"
                      >
                        {row.patient_name}
                      </TableCell>
                      <TableCell align="left">
                        {row?.patient_lastname}
                      </TableCell>
                      <TableCell align="left">
                        {"Dr " + row?.user?.lastname}
                      </TableCell>
                      <TableCell align="left">
                        {modeIconJson[row?.mode].icon}
                      </TableCell>
                      <TableCell align="left">{row?.insurance?.name}</TableCell>
                      <TableCell align="left">
                        <Chip
                          size={"small"}
                          // icon={colorJson[patient_status].icon}
                          icon={React.cloneElement(colorJson[patient_status].icon, { style: { color: "#000" } })}
                          label={
                            capitalization(patient_status) +
                            " : " +
                            replacer(status_update)
                          }
                          color="success"
                          sx={{ bgcolor: colorJson[patient_status].color, color: "#000" }}
                        />
                      </TableCell>
                      <TableCell align="left">
                        <Chip
                          size={"small"}
                          icon={React.cloneElement(colorJson[status].icon, { style: { color: "#000" } })}
                          label={"PEC " + capitalization(status)}
                          sx={{ bgcolor: colorJson[status].color, color: "#000" }}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 15, 20, 30]}
            component="div"
            count={-1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      )}
    </Box>
  );
}
