import { MenuItem, Stack, Typography } from "@mui/material";
import { CustomDropDown, InputFeild, PDFViewer } from "components";
import { FormikProps } from "formik";
import { copyTextToClipboard, formatDate } from "utils";
import CustomInPut from "./CustomInPut";

interface PatientProps extends FormikProps<any> {}

const Patient: React.FC<PatientProps> = ({
  values,
  errors,
  touched,
  handleChange,
  setFieldValue,
  handleBlur,
}) => {
  return (
    <Stack width={"50%"} display={"flex"} flex={1} alignSelf={"start"}>
      {/* Patient */}
      <CustomInPut
        name="patient_lastname"
        value={values.patient_lastname}
        // onBlur={handleBlur}
        onChange={handleChange}
        placeholder="Nom du patient"
        label="Nom"
        onClipBoard={copyTextToClipboard}
      />
      <CustomInPut
        name="patient_name"
        value={values.patient_name}
        // onBlur={handleBlur}
        onChange={handleChange}
        placeholder="Prénom du patient"
        label="Prénom"
        onClipBoard={copyTextToClipboard}
      />
      <Stack direction={"row"} flex={1} width={"100%"} spacing={1}>
        <CustomInPut
          type="date"
          name="patient_birthdate"
          value={formatDate(values.patient_birthdate)}
          onBlur={handleBlur}
          onChange={handleChange}
          label="Date de naissance"
          onClipBoard={copyTextToClipboard}
        />
        <CustomInPut
          name="social_security_number"
          value={values.social_security_number}
          // onBlur={handleBlur}
          onChange={handleChange}
          placeholder="Numéro de sécurité sociale du patient"
          label="Numéro de sécurité sociale"
          onClipBoard={copyTextToClipboard}
        />
      </Stack>
      <CustomInPut
        name="phone_number"
        value={values.phone_number}
        // onBlur={handleBlur}
        onChange={handleChange}
        placeholder="Numéro de téléphone du patient"
        label="Numéro de téléphone"
        onClipBoard={copyTextToClipboard}
      />
      <CustomDropDown
        name="patient_status"
        value={values.patient_status}
        onChange={handleChange}
        label="Statut patient"
        isShadow
      >
        <MenuItem value="pending_insurance_approval">Attente mutuelle</MenuItem>
        <MenuItem value="to_call">À appeler</MenuItem>
        <MenuItem value="granted">Accord</MenuItem>
        <MenuItem value="cancelled">Annulation</MenuItem>
        <MenuItem value="pending_patient">En attente du patient</MenuItem>
        <MenuItem value="voicemail">Messagerie</MenuItem>
        <MenuItem value="refused">Refus</MenuItem>
      </CustomDropDown>
      <Stack direction={"row"} flex={1} width={"100%"} spacing={1}>
        <CustomInPut
          type="date"
          name="call_date"
          value={formatDate(values.call_date)}
          onChange={handleChange}
          label="Date d'appel"
          isShadow
        />
        <CustomInPut
          type="date"
          name="appointment_date"
          value={formatDate(values.appointment_date)}
          onChange={handleChange}
          label="Date de rendez-vous"
          isShadow
        />
      </Stack>
      <InputFeild
        name="comments"
        boxShadow=""
        value={values.comments}
        // onBlur={handleBlur}
        onChange={handleChange}
        placeholder="Remarks"
        bgColor="primary.200"
        style={{ minHeight: "100px" }}
        width={"100%"}
        label="Remarques"
        multiline
        rows={3}
      />

      <Typography mt={1} ml={0.4} fontSize={13} color={"text.primary"}>
        Devis
      </Typography>
      <PDFViewer
        id={values?.estimate_id}
        onCallBack={(id) => setFieldValue("estimate_id", Number(id))}
      />
      <Typography mt={2} ml={0.4} fontSize={13} color={"text.primary"}>
        Carte mutuelle
      </Typography>
      <PDFViewer
        id={values?.insurance_media_id}
        onCallBack={(id) => setFieldValue("insurance_media_id", Number(id))}
      />
    </Stack>
  );
};

export default Patient;
