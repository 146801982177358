import { Middleware } from '@reduxjs/toolkit';

const restrictionsMiddleware: Middleware = () => (next) => (action) => {
  // Restrict RTK Query from executing if a person do not have permission
  // const tmp = localStorage.getItem('permissions');
  // const permissions: any = JSON.parse(tmp === null ? '' : tmp);
  // if (action.type === 'mainAPI/internalSubscriptions/subscriptionsUpdated') {
  //   if (
  //     permissions.warehouse.create === false ||
  //     permissions.warehouse.update === false ||
  //     permissions.warehouse.remove === false
  //   ) {
  //     return next({ type: 'commonSlice/showPermissionModal' });
  //   }
  // } else if (action.type === 'mainAPI/executeQuery/fulfilled') {
  //   if (
  //     permissions.zones.create === false ||
  //     permissions.zones.update === false ||
  //     permissions.zones.remove === false
  //   ) {
  //     return next({ type: 'commonSlice/showPermissionModal' });
  //   }
  // }

  // if (action.type === "slice/action") {
  //   if (session.permissions[PermissionTypes.{Name}].[view/create/edit/delete] === false) {
  //     Show Error message
  //     return next({
  //       payload: "entity name",
  //       type: "commonSlice/toggleNotificationModal",
  //     });
  //   }
  // } else if () ...

  return next(action);
};

export { restrictionsMiddleware };
