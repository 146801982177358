import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import api, { MAIN_API_REDUCER_KEY } from './api';
import AuthenticationReducer, { AuthenticationReducerName } from '../features/auth/slice';
import GlobalReducer, { GlobalReducerName } from '../features/Global/slice';
import { rtkQueryErrorLogger, successMessageLogger, restrictionsMiddleware } from './middlewares';
import filterSlice from 'features/toutes/slice';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['authentication', 'global']
};

const reducers = combineReducers({
  // authentication: AuthenticationReducer,
  [AuthenticationReducerName]: AuthenticationReducer,

  [GlobalReducerName]: GlobalReducer,
  // [ConfigurationReducerName]: ConfigurationReducer,
  // [AuthenticationService.reducerPath]: AuthenticationService.reducer,

  [MAIN_API_REDUCER_KEY]: api.reducer,
  'filters': filterSlice,

  

});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }).concat([api.middleware, rtkQueryErrorLogger, successMessageLogger, restrictionsMiddleware]);
  },
  devTools: process.env.NODE_ENV !== 'production'
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;