import { createSlice } from "@reduxjs/toolkit";

const initialState: any = { per_centre: [], facturation: [], patient: [], pec: [] };

const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    mangeFilter: (state, action) => {
      const { item, type } = action.payload;

      if (type == 'per_centre') {
        let list = updateList(state.per_centre, item)
        state.per_centre = list
      } else if (type == 'facturation') {
        let list = updateList(state.facturation, item)
        state.facturation = list
      } else if (type == 'patient') {
        let list = updateList(state.patient, item)
        state.patient = list
      } else if (type == 'pec') {
        let list = updateList(state.pec, item)
        state.pec = list
      }

      return state
    },
  },
});

export default filterSlice.reducer;

export const { mangeFilter } = filterSlice.actions;

const updateList = (selectedList: any[], item: any) => {
  if(item.length===0){
    return [];
  }
  const selectedItem = selectedList.find((x: any) => x.name === item.name);

  if (selectedItem) {
    return selectedList.filter((it: any) => it.name != item.name);
  } else {
    return [...selectedList, item];
  }
}
