import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { PrimaryButton } from "components";
import { useFormik } from "formik";
import { ToastView } from "utils";
import * as yup from "yup";
import {
  useCreateCenterMutation,
  useDeleteCenterMutation,
  useGetAllPaymentsQuery,
  useUpdateCenterMutation
} from "../../service";
import CustomStandardInput from "../CustomStandardInput";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const validationSchema = yup.object().shape({
  name: yup.string().required().label('Nom'),
  external_id: yup
    .number()
    .positive()
    .label('ID Desmos'),
  payments: yup.array().label('Modes de paiement'),
});

const CenterForm: React.FC<{
  name?: string;
  external_id?: number;
  payments?: any;
  id?: number;
  onClosed: () => void;
}> = ({
  name,
  external_id,
  payments,
  id,
  onClosed
}) => {
    const { data: paymentList = [], isLoading: paymentLoading } =
      useGetAllPaymentsQuery(undefined);

    const traformPaymentList = paymentList?.map((item: any) => {
      const newItem = { ...item }
      delete newItem.centers;
      return newItem
    })

    const [onDelete, { isLoading: isDeleting }] = useDeleteCenterMutation();
    const [onUpdate, { isLoading: isUpdating }] = useUpdateCenterMutation();
    const [onCreate, { isLoading: isCreatting }] = useCreateCenterMutation();

    const onSubmit = (vals: any) => {
      const { payments, external_id, ...rest } = vals
      const values = {
        ...rest,
        payment_ids: payments.map((it: any) => it.id),
        external_id: Number(external_id)
      }
      if (id) {
        onUpdate({ id, body: values })
          .then((res: any) => {
            if (!res?.error) {
              ToastView("Centre mis à jour !", "success");
              onClosed()
            }
          })
          .catch((e) => {
            console.log("=-=-=-=-=-:::::", e);
          });
      } else {
        onCreate(values)
          .then((res: any) => {
            if (!res?.error) {
              ToastView("Nouveau centre ajouté !", "success");
              onClosed()
            }
          })
          .catch((e) => {
            console.log("=-=-=-=-=-:::::", e);
          });
      }
    };

    const {
      handleBlur,
      handleChange,
      values,
      handleSubmit,
      errors,
      touched,
      setFieldValue,
    } = useFormik({
      initialValues: {
        name: name || undefined,
        external_id: external_id || undefined,
        payments: payments || undefined,
      },
      validationSchema,
      onSubmit,
    });
    return (
      <Stack
        flex={1}
        height={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Stack my={4} width={"100%"}>
          <CustomStandardInput
            label="Nom"
            value={values.name}
            name="name"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.name && errors.name ? true : false}
          />
          <CustomStandardInput
            label="ID DESMOS"
            value={values.external_id}
            name="external_id"
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.external_id && errors.external_id ? true : false}
          />

          <FormControl
            variant="standard"
            sx={{
              height: 60,
              "& .MuiInputLabel-root": {
                paddingLeft: "10px",
                fontSize: 14,
              },
              "& .MuiInputBase-root": {
                paddingLeft: "10px",
                fontSize: 14,
              },
            }}
          >
            <InputLabel id="demo-simple-select-standard-label" sx={{ color: "gray" }}>Modes de paiement</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              label="Payments"
              multiple
              value={(values?.payments || []).map((it: any) => it.name)}
              renderValue={(selected) => selected?.join(", ")}
              name="payment_ids"
              MenuProps={MenuProps}
            >
              {traformPaymentList.map((it: any) => {
                const tempList = values?.payments
                  ? [...values?.payments]
                  : [];
                const findedIndex = tempList?.findIndex(
                  (item: any) => item?.id === it?.id
                );
                const check = findedIndex > -1 ? true : false
                return <MenuItem value={it.id.toString()} onClick={() => {
                  if (check) {
                    tempList.splice(findedIndex, 1);
                  } else {
                    tempList.push(it);
                  }
                  setFieldValue("payments", tempList)
                }}>
                  <Checkbox
                    checked={check}
                  />
                  <ListItemText primary={it.name} />
                </MenuItem>
              })}
            </Select>
          </FormControl>
        </Stack>

        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          width={"97%"}
          py={2}
        >
          {id ? (
            <PrimaryButton
              title="Supprimer"
              onClick={() => onDelete(id).then((res: any) => {
                if (!res?.error) {
                  ToastView("Supprimé avec succès", "success");
                  onClosed()
                }
              })}
              style={{ width: 150, paddingBlock: 15, borderRadius: 6 }}
              boxShadow=""
              loading={isDeleting}
            />
          ) : <Stack />}
          <PrimaryButton
            title="Enregister"
            onClick={() => handleSubmit()}
            style={{ width: 150, paddingBlock: 15, borderRadius: 6 }}
            boxShadow=""
            loading={isUpdating || isCreatting}
          />
        </Stack>
      </Stack>
    );
  };

export default CenterForm;
