import { ButtonBase, ButtonBaseProps, CircularProgress } from "@mui/material";

interface type extends ButtonBaseProps {
  title: string;
  boxShadow?: string;
  loading?: boolean;
}

const PrimaryButton = ({
  title,
  boxShadow = "4px 4px 10px rgba(7, 25, 130, 0.3)",
  loading,
  style = {},
  ...props
}: type) => {
  return (
    <ButtonBase
      {...props}
      style={{
        paddingBlock: 15,
        color: "white",
        fontSize: 14,
        width: "100%", // Assure que le bouton ne dépasse pas
        maxWidth: "350px", // Empêche de devenir trop large sur desktop
        borderRadius: 10,
        boxShadow: boxShadow,
        backgroundColor: "#1c2a4d", // Assure la bonne couleur
        ...style,
      }}
    >
      {loading ? <CircularProgress size={15} style={{ color: "white" }} /> : title}
    </ButtonBase>
  );
};

export default PrimaryButton;
