import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Stack, Typography } from '@mui/material';
import { memo, useEffect, useRef, useState } from "react";
import Card from './Card';
import TopHeading from './TopHeading';

const ToDo = ({ title, url, name, sort, archived, min }: any) => {
    const [data, setData] = useState<any[]>([]);
    const [page, setPage] = useState<number>(0);
    // const [isLoading, setLoading] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const token = localStorage.getItem('appToken');

    const [header, setHeader] = useState("")

    const fetchData = async () => {
        // setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token}`); // Replace with your actual access token

        var requestOptions: RequestInit = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        const tempUrl = new URL(url);
        tempUrl.searchParams.set("page", page.toString())

        const finalUrl = tempUrl.toString();

        await fetch(finalUrl, requestOptions)
            .then(response => {

                const headersObj: any = {};
                response.headers.forEach((value, name) => {
                    headersObj[name] = value;
                });

                setHeader(headersObj["pagination-count"].toString())

                return response.json()
            })
            .then((result: any) => {
                if (Array.isArray(result)) {
                    if (data.length === 0 || page === 0) {
                        setData(result)
                    } else {
                        const updatedData = result.filter(item => !data.some(existingItem => existingItem.id === item.id));
                        setData(prevData => [...prevData, ...updatedData]);
                    }
                    // setLoading(false)
                }
            })
            .catch(error => console.log('error', error));
    };

    const handleScroll = () => {
        const container = containerRef.current;
        if (container) {
            const scrollPosition = container.scrollTop;
            const containerHeight = container.clientHeight;
            const contentHeight = container.scrollHeight;

            if (scrollPosition + containerHeight >= contentHeight - 10) {
                setPage(page + 1);
            }
        }
    };



    useEffect(() => {
        setData([])
        setPage(0)
        fetchData()
    }, [name, sort, archived, min])


    useEffect(() => {
        fetchData();
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, [page]); // Include 'page' as a dependency for the effect

    return (
        <Stack bgcolor={"primary.200"} border={1} borderRadius={2}
            height={"calc(100vh - 163px)"}
            borderColor={"gray"} width={"100%"} pb={.2}
            boxShadow={"3px 1px 5px #855191"}
        >
            <TopHeading title={title} count={header} />

            <Stack ref={containerRef} paddingX={2} sx={{ overflowY: data.length > 0 ? 'auto' : 'none', overflowX: "hidden" }}>
                {
                    data.length > 0 ?

                        data.map((item, index) => (
                            <Card key={index} title={title} item={item} />
                            // <>
                            //     {(isLoading && data.length === index + 1)
                            //         ? <CircularProgress />
                            //         : <Card key={index} title={title} item={item} />
                            //     }
                            // </>
                        ))
                        :
                        <Stack mt={20} width={"20rem"} alignItems={"center"}>
                            <ErrorOutlineIcon sx={{ fontSize: 36, color: "gray" }} />
                            <Typography textAlign={"center"} color={"gray"}>
                                Aucune donnée
                            </Typography>
                        </Stack>
                }
            </Stack>

        </Stack>
    );
}

export default memo(ToDo);