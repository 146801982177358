import AddSharpIcon from "@mui/icons-material/AddSharp";
import { IconButton, Stack } from "@mui/material";
import { useState } from "react";
import { StatusBar, ToDo } from "../../../components";
import DashboardStatsModal from "../components/DashboardStatsModal";
import ReminderPopup from "../components/ReminderPopup";



const DashBoard = () => {

  const [name, setName] = useState("");
  const [sort, setSort] = useState("status_update");
  const [min, setMin] = useState(true);
  const [archived, setArchived] = useState(true);
  const [searchType, setSearchType] = useState("all"); // "all" par défaut
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>, searchType: string) => {
  const value = event.target.value; // ✅ Garde uniquement la valeur tapée

  setName(value); // ✅ Met uniquement la valeur tapée dans l'état
};



  const token = localStorage.getItem("appToken");
  

  return (
    <Stack width={"100%"} height={"100%"} bgcolor={"#FFFFFF"}> 
<StatusBar
  name={name}
  onChange={handleSearch}
  onSort={setSort}
  onArchive={setArchived}
  onMin={setMin}
  searchType={searchType} // ✅ Passe searchType ici
  setSearchType={setSearchType} // ✅ Passe aussi la fonction pour le modifier
/>



      <Stack sx={{ overflowX: "auto" }} height={"calc(100% - 52px)"}>
        <Stack direction="row" spacing={1} my={1}>
          {/* &types=&patient_billing_statuses=&payment_ids=&patient_statuses=&user_ids=&insurance_ids=&center_ids= */}

          <ToDo
            token={token}
            title={"À faire"}
            name={name}
            sort={sort}
            archived={archived}
            min={min}
           url={`https://api.pecup.fr/requests?limit=10&is_archived=${!archived}&statuses=to_do&mine=${!min}&order=desc&sort=${sort}${
  name
    ? searchType === "patient"
      ? `&patient_name=${name}&patient_lastname=${name}`
      : searchType === "doctor"
      ? `&doctor_name=${name}&doctor_lastname=${name}`
      : searchType === "center"
      ? `&center_name=${name}`
      : searchType === "devis"
      ? `&devis_number=${name}`
      : `&any_names=${name}`
    : ""
}`}


          />

          <ToDo
            token={token}
            title={"En cours"}
            name={name}
            sort={sort}
            archived={archived}
            min={min}
            url={`https://api.pecup.fr/requests?limit=10&is_archived=${!archived}&statuses=in_progress&mine=${!min}&order=desc&sort=${sort}${
  name
    ? searchType === "patient"
      ? `&patient_name=${name}&patient_lastname=${name}`
      : searchType === "doctor"
      ? `&doctor_name=${name}&doctor_lastname=${name}`
      : searchType === "center"
      ? `&center_name=${name}`
      : searchType === "devis"
      ? `&devis_number=${name}`
      : `&any_names=${name}`
    : ""
}`}

          />

          <ToDo
            token={token}
            title={"Envoi patient"}
            name={name}
            sort={sort}
            archived={archived}
            min={min}
url={`https://api.pecup.fr/requests?limit=10&is_archived=${!archived}&statuses=sent_patient&mine=${!min}&order=desc&sort=${sort}${
  name
    ? searchType === "patient"
      ? `&patient_name=${name}&patient_lastname=${name}`
      : searchType === "doctor"
      ? `&doctor_name=${name}&doctor_lastname=${name}`
      : searchType === "center"
      ? `&center_name=${name}`
      : searchType === "devis"
      ? `&devis_number=${name}`
      : `&any_names=${name}`
    : ""
}`}

          />

          <ToDo
            token={token}
            title={"Relance Mutuelle"}
            name={name}
            sort={sort}
            archived={archived}
            min={min}
            url={`https://api.pecup.fr/requests?limit=10&is_archived=${!archived}&statuses=retry_1&mine=${!min}&order=desc&sort=${sort}${
  name
    ? searchType === "patient"
      ? `&patient_name=${name}&patient_lastname=${name}`
      : searchType === "doctor"
      ? `&doctor_name=${name}&doctor_lastname=${name}`
      : searchType === "center"
      ? `&center_name=${name}`
      : searchType === "devis"
      ? `&devis_number=${name}`
      : `&any_names=${name}`
    : ""
}`}

          />

          <ToDo
            token={token}
            title={"Accordée"}
            name={name}
            sort={sort}
            archived={archived}
            min={min}
            url={`https://api.pecup.fr/requests?limit=10&is_archived=${!archived}&statuses=granted&mine=${!min}&order=desc&sort=${sort}${
  name
    ? searchType === "patient"
      ? `&patient_name=${name}&patient_lastname=${name}`
      : searchType === "doctor"
      ? `&doctor_name=${name}&doctor_lastname=${name}`
      : searchType === "center"
      ? `&center_name=${name}`
      : searchType === "devis"
      ? `&devis_number=${name}`
      : `&any_names=${name}`
    : ""
}`}

          />

          <ToDo
            token={token}
            title={"Refusée"}
            name={name}
            sort={sort}
            archived={archived}
            min={min}
            url={`https://api.pecup.fr/requests?limit=10&is_archived=${!archived}&statuses=refused&mine=${!min}&order=desc&sort=${sort}${
  name
    ? searchType === "patient"
      ? `&patient_name=${name}&patient_lastname=${name}`
      : searchType === "doctor"
      ? `&doctor_name=${name}&doctor_lastname=${name}`
      : searchType === "center"
      ? `&center_name=${name}`
      : searchType === "devis"
      ? `&devis_number=${name}`
      : `&any_names=${name}`
    : ""
}`}

          />

          <ToDo
            token={token}
            title={"Devis erroné ou Désistement Patient"}
            name={name}
            sort={sort}
            archived={archived}
            min={min}
            url={`https://api.pecup.fr/requests?limit=10&is_archived=${!archived}&statuses=retry_2&mine=${!min}&order=desc&sort=${sort}${
  name
    ? searchType === "patient"
      ? `&patient_name=${name}&patient_lastname=${name}`
      : searchType === "doctor"
      ? `&doctor_name=${name}&doctor_lastname=${name}`
      : searchType === "center"
      ? `&center_name=${name}`
      : searchType === "devis"
      ? `&devis_number=${name}`
      : `&any_names=${name}`
    : ""
}`}

          />

{/*
<ToDo
  token={token}
  title={"Erreur Devis"}
  name={name}
  sort={sort}
  archived={archived}
  min={min}
  url={`https://api.pecup.fr/requests?limit=10&is_archived=${!archived}&statuses=error&mine=${!min}&order=desc&sort=${sort}${
    name
      ? searchType === "patient"
        ? `&patient_name=${name}&patient_lastname=${name}`
        : searchType === "doctor"
        ? `&doctor_name=${name}&doctor_lastname=${name}`
        : searchType === "center"
        ? `&center_name=${name}`
        : searchType === "devis"
        ? `&devis_number=${name}`
        : `&any_names=${name}`
      : ""
  }`}
/>
*/}


          <Stack p={0.5} />
        </Stack>
      </Stack>
{/* ✅ Modale de statistiques */}
<DashboardStatsModal />

{/* ✅ Nouvelle pop-up de rappel */}
<ReminderPopup />

    </Stack>
  );
};

export default DashBoard;
