import api from "tools/api";

export const DashboardService = api.injectEndpoints({
  endpoints: (build) => ({
    getRequests: build.query<any, boolean>({
      query: (bool) => `requests?is_archived=${bool}&priority=true`,
      //   query: (bool) => "requests?limit=100&page=55&priority=true",
    }),
    getSingleRequests: build.query({
      query: (id) => `requests/${id}`,
    }),
    getMedia: build.query<Blob, string>({
      query: (id) => `media/${id}`,
      transformResponse: (response: any) => {
        if (response.error) {
          throw new Error("Request failed");
        }
        return response.blob();
      },
    }),
    getCenters: build.query<any, undefined | void>({
      query: () => "centers",
      providesTags: ["centers"],
    }),
    getSingleCenters: build.query({
      query: (id) => `centers/${id}`,
    }),
    getPayments: build.query<any, undefined | void>({
      query: () => "payments",
    }),
    getInsurances: build.query<any, string | undefined | void>({
      query: (search?: string) => {
        var url = "insurances";
        if (search && search !== "") {
          url = `insurances?name=${search}`;
        }
        return url;
      },
    }),
    getUsers: build.query<any, string | undefined | void>({
      query: (search?: string) => {
        var url = "users";
        if (search && search !== "") {
          url = `users?any_names=${search}`;
        }
        return url;
      },
    }),
    getSingleUser: build.query({
      query: (id) => `users/${id}`,
    }),
    getHistory: build.query({
      query: (id) => `requests/${id}/history`,
    }),
    updateRequests: build.mutation({
      query: (body) => ({
        url: `requests/${body.id}`,
        method: "PATCH",
        body: body,
      }),
    }),
    createRequests: build.mutation({
      query: (body) => ({
        url: "requests",
        method: "POST",
        body: body,
      }),
    }),
    getSync: build.query({
      query: (id) => `requests/sync/${id}`,
    }),
    getSingleInsurances: build.query<any, string>({
      query: (id?: string) => `insurances/${id}`,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetRequestsQuery,
  useLazyGetRequestsQuery,
  useGetSingleRequestsQuery,
  useLazyGetSingleRequestsQuery,
  useLazyGetMediaQuery,
  useGetMediaQuery,
  useGetCentersQuery,
  useGetPaymentsQuery,
  useLazyGetPaymentsQuery,
  useGetInsurancesQuery,
  useLazyGetInsurancesQuery,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useGetHistoryQuery,
  useLazyGetHistoryQuery,
  useUpdateRequestsMutation,
  useLazyGetSyncQuery,
  useCreateRequestsMutation,
  useLazyGetSingleCentersQuery,
  useGetSingleInsurancesQuery,
  useLazyGetSingleInsurancesQuery,
  useLazyGetSingleUserQuery,
} = DashboardService;
